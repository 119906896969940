import React from "react";
import {showNotification, setTwoFactorSuccess} from "../../redux/actions";
import {connect} from "react-redux";
import {Formik, Form} from "formik";
import {TextField} from '../Common/CustomInputs'
import {apiSendData} from "../../libs/Api";
import authRouteFinder from "../../libs/AuthRouteFinder";
import CSRFTokenForm from "../Common/CSRFTokenForm";

const TwoFactorForm = (props) => {
    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    return(
        <Formik
            initialValues={{code: ''}}
            onSubmit={(values, {setSubmitting}) => {
                apiSendData(
                '/api/users/login/two-factor/',
                    values,
                    'post',
                    true,
                ).then(response => {
                    if (mountedRef.current) {
                        //setSubmitting(false);
                        if (response && !response.error) {
                            props.setTwoFactorSuccess();
                            authRouteFinder();
                        }
                    }
                });
            }}
        >
            <Form className="pure-form pure-form-stacked" id="form-two-factor">
                <CSRFTokenForm />
                <div className="pure-u-1 row-space">
                    <TextField
                        label="Two Factor Code"
                        id="code"
                        name="code"
                        type="text"
                        required="required"
                        autoFocus={true}
                        placeholder="Two Factor Code"
                    />
                </div>

                <div className="pure-u-1-2">
                    <button type="submit" className="pure-button button-std button-row-space">Submit Code</button>
                </div>
            </Form>
        </Formik>
    );
}

function TwoFactorAuth(props) {
    const handleResendTwoFactorCode = (event) => {
        event.preventDefault();
        //Do that sending thing!
    }

    return(
        <>
            <h3>Two Factor Authentication</h3>
            <p>A 6 digit code has been sent to your registered email address.</p>
            <a href="" onClick={handleResendTwoFactorCode}>Resend Code</a>

            <TwoFactorForm {...props} />
        </>
    );
}

const mapStateToProps = state => {
    return {
        authState: state.authState,
    };
};

const mapDispatchToProps =  {
    setTwoFactorSuccess,
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth);