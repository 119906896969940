import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from "prop-types";


function AdminOverviewBookingsGraph(props) {
    return(
        <>
            <div style={{height:"300px"}} className="w-100 big-space">
                <h4 style={{textAlign:"center"}}>Bookings in the last 90 days</h4>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={props.data}
                        margin={{
                            top: 5,
                            right: 0,
                            left: 0,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="bookings" stroke="#8884d8" activeDot={{ r: 6 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

AdminOverviewBookingsGraph.propTypes = {
    data: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOverviewBookingsGraph);