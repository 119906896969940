import {DatePickerField, SelectField} from "../../Common/CustomInputs";
import React from "react";
import PropTypes from "prop-types";
import ModifyButton from "../../Common/ModifyButtons";
import Modal from "../../Common/Modal";
import {Form, Formik, Field, FieldArray, useFormikContext} from "formik";
import * as Yup from "yup";
import CSRFTokenForm from "../../Common/CSRFTokenForm";
import dayjs from "dayjs";


function ClinicSetupReservedAppointmentBlackoutModal(props) {

    // Mounted Ref
    // const mountedRef = React.useRef(true);
    // React.useEffect(() => {
    //     mountedRef.current = true;
    //     return () => {
    //         mountedRef.current = false;
    //     }
    // }, []);

    const [isEditing, setIsEditing] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    // React.useEffect(() => {
    //     console.log(props.appointment);
    //     if (props.appointment && props.appointment.blackout_dates && props.appointment.blackout_dates.some(entry => entry.id < 1)) {
    //         setIsEditing(true);
    //     } else {
    //         setIsEditing(false);
    //     }
    // }, [props.appointment]);

    return(
        <Modal
            modalOpen={props.blackoutAppointmentModalOpen}
            setModalOpen={props.setBlackoutAppointmentModalOpen}
            header={"Edit Blackout Dates"}
            //onModalClose={props.onModalClose}
            minHeight={isEditing ? '500px' : 'auto'}
        >
            <div className={`pure-g ${loading ? 'loading-overlay-element': ""}`} style={{ height:'100%'}}>
                <div className='pure-u-1'>
                    <p>You can add or edit blackout dates for this appointment. All dates listed here will be unavailable to book for this appointment.</p>
                </div>
                <div className='pure-u-1 row-space'>
                    {
                        props.appointment && props.appointment.blackout_dates ?
                                <ClinicSetupReservedAppointmentBlackoutModalRow
                                    blackout_dates={props.appointment.blackout_dates}
                                    appointment_id={props.appointment.id}
                                    setIsEditing={setIsEditing}
                                    setBlackoutAppointmentModalOpen={props.setBlackoutAppointmentModalOpen}
                                    updateBlackoutDates={props.updateBlackoutDates}
                                />
                            :
                            ""
                    }
                </div>
            </div>
        </Modal>
    );
}

ClinicSetupReservedAppointmentBlackoutModal.propTypes = {
    blackoutAppointmentModalOpen: PropTypes.bool,
    setBlackoutAppointmentModalOpen: PropTypes.func,
    appointment: PropTypes.object,
    setAppointment: PropTypes.func,
    updateBlackoutDates: PropTypes.func,
}

function ClinicSetupReservedAppointmentBlackoutModalRow(props) {

    const [deleteList, setDeleteList] = React.useState([]);

    return(
        <div>
            <Formik
                initialValues={{'blackout_dates' : props.blackout_dates ? [...props.blackout_dates] : []}}
                enableReinitialize={true}
                onSubmit={(values, {resetForm, setSubmitting, setErrors}) => {
                    props.updateBlackoutDates(props.appointment_id, values, deleteList)
                    props.setBlackoutAppointmentModalOpen(false);
                    resetForm();
                    setDeleteList([]);
                    }
                }
                validationSchema={Yup.object().shape({
                    blackout_dates: Yup.array()
                        .of(
                            Yup.object().shape({
                                start_date: Yup.date().nullable()
                                    .required("Blackout start date is required."),
                                end_date: Yup.date().nullable()
                                    .required("Blackout end date is required.")
                                    .test({
                                        name: 'end-after-start',
                                        exclusive: false,
                                        params: {},
                                        message: 'Blackout end date must be after the start date.',
                                        test: function(value) {
                                            return !(dayjs(value) < dayjs(this.parent.start_date));
                                        }
                                    }),
                            })
                        )
                })}
            >
                {
                    ({values}) => (
                        <ClinicSetupReservedAppointmentBlackoutModalRowForm
                            values={values}
                            setIsEditing={props.setIsEditing}
                            setBlackoutAppointmentModalOpen={props.setBlackoutAppointmentModalOpen}
                            deleteList={deleteList}
                            addToDeleteList={(id) => {
                                setDeleteList([...deleteList, id]);
                            }}
                        />
                    )
                }
            </Formik>
        </div>
    )
}

ClinicSetupReservedAppointmentBlackoutModalRow.propTypes = {
    blackout_dates: PropTypes.array,
    appointment_id: PropTypes.number,
    setIsEditing: PropTypes.func,
    setBlackoutAppointmentModalOpen: PropTypes.func,
    updateBlackoutDates: PropTypes.func,
}

function ClinicSetupReservedAppointmentBlackoutModalRowForm(props) {

    const {values, resetForm} = useFormikContext();

    React.useEffect(() => {
        if (props.values.blackout_dates && props.values.blackout_dates.length > 0) {
            props.setIsEditing(true);
        } else {
            props.setIsEditing(false);
        }
    },[values]);

    return (
            <Form className="pure-form pure-form-stacked" id="form-setup-resv-appt" autoComplete="off">
                <FieldArray
                    name="blackout_dates"
                    render={arrayHelpers => (
                        <div>
                            <div className="pure-u-1 row-space">
                                <button type="button" className="pure-button button-std" onClick={() => arrayHelpers.push({ start_date: '', end_date: '', id: '' })}>Add New Blackout Date</button>
                            </div>
                            {
                                props.values.blackout_dates && props.values.blackout_dates.length > 0 ?
                                    <div className='pure-u-1 row-space'>
                                        <div className="pure-table-div-thead">
                                            <div className="pure-u-9-24">Start Date</div>
                                            <div className="pure-u-9-24">End Date</div>
                                            <div className="pure-u-3-24">Duration</div>
                                            <div className="pure-u-3-24">&nbsp;</div>
                                        </div>
                                    </div>
                                    :""
                            }

                            {props.values.blackout_dates ?
                                props.values.blackout_dates.map((entry, index) => (
                                    <div key={index}>
                                        <div className="pure-u-9-24 row-space same-row-input-spacing">
                                            <DatePickerField
                                                id={`blackout_dates.${index}.start_date`}
                                                name={`blackout_dates.${index}.start_date`}
                                                placeholder="Start Date"
                                                required="required"
                                            />
                                        </div>
                                        <div className="pure-u-9-24 row-space same-row-input-spacing">
                                            <DatePickerField
                                                id={`blackout_dates.${index}.end_date`}
                                                name={`blackout_dates.${index}.end_date`}
                                                minDate={entry.start_date}
                                                required="required"
                                            />
                                        </div>
                                        <div className="pure-u-3-24 row-space" style={{marginTop: '25px'}}>
                                            <strong>
                                                {
                                                    dayjs(props.values.blackout_dates[index].end_date).diff(props.values.blackout_dates[index].start_date, 'day', false) ?
                                                        dayjs(props.values.blackout_dates[index].end_date).diff(props.values.blackout_dates[index].start_date, 'day', false)
                                                        : "-"
                                                } days
                                            </strong>
                                        </div>
                                        <div className="pure-u-3-24 text-right" style={{marginTop: '25px'}}>
                                            {/*<ModifyButton type='save' additionalClass="icon-space-right" onClickHandler={() => {formRef.current.handleSubmit()}} />*/}
                                            {/*<ModifyButton type='cancel' onClickHandler={onClickCancel} />*/}
                                            <ModifyButton type='delete' onClickHandler={() => {
                                                arrayHelpers.remove(index);
                                                if (props.values.blackout_dates[index].id !== '') {
                                                    props.addToDeleteList(props.values.blackout_dates[index].id);
                                                }
                                            }}/>
                                        </div>
                                    </div>
                                ))
                                : ""
                            }
                            {
                                props.values.blackout_dates && (props.values.blackout_dates.length > 0 || props.deleteList.length > 0) ?
                                    <>
                                        <div className="row-space" >
                                            <button type="submit" className="pure-button button-std">Save Changes</button>
                                            <button type="button" className="pure-button button-cancel left-space"
                                                    onClick={() => {
                                                            props.setBlackoutAppointmentModalOpen(false);
                                                            resetForm();
                                                        }
                                                    }
                                            >Cancel</button>
                                        </div>
                                    </>
                                    : ""
                            }
                        </div>
                    )}
                />
            </Form>
    );
}

ClinicSetupReservedAppointmentBlackoutModalRowForm.propTypes = {
    setIsEditing: PropTypes.func,
    setBlackoutAppointmentModalOpen: PropTypes.func,
}

export default ClinicSetupReservedAppointmentBlackoutModal;