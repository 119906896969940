import React from 'react';
import {connect} from "react-redux";
import ModifyButton from "../../Common/ModifyButtons";
import history from "../../Common/history";
import * as Routes from "../../../libs/PCAOSSRoutes";
import PropTypes from "prop-types";


function Row(props) {

    return(
        <tr>
            <td>{props.booking.id}</td>
            <td><div className={`status ${props.booking.cancelled ? "Cancelled": "Pending"}`}>{props.booking.cancelled ? "Cancelled" : "Booked"}</div></td>
            <td>{props.booking.patient_name}</td>
            <td>{props.booking.clinic}</td>
            <td>{props.booking.booking_start_date}</td>
            <td>{props.booking.booking_start_time}</td>
            <td>{props.booking.booking_user}</td>
            <td style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                <span className="icon-space-right"><ModifyButton type='view' onClickHandler={() => {history.push(`${Routes.HOSPITAL_RECENT_BOOKINGS}/${props.booking.id}`)}} /></span>
                {props.booking.cancelled ? "" : <ModifyButton type='edit' onClickHandler={() => {history.push(`${Routes.HOSPITAL_RECENT_BOOKINGS}/${props.booking.id}/edit`)}} />}
            </td>
        </tr>
    );
}

Row.propTypes = {
    booking: PropTypes.object.isRequired,
}

function HospitalRecentBookingsTable(props) {

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <table className="pure-table pure-table-horizontal pure-table-striped table-hover row-space" style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th style={{whiteSpace: 'nowrap'}}>ID</th>
                        <th style={{whiteSpace: 'nowrap'}}>Status</th>
                        <th style={{whiteSpace: 'nowrap'}}>Patient Name</th>
                        <th style={{whiteSpace: 'nowrap'}}>Clinic</th>
                        <th style={{whiteSpace: 'nowrap'}}>Date</th>
                        <th style={{whiteSpace: 'nowrap'}}>Time</th>
                        <th style={{whiteSpace: 'nowrap'}}>Booked By</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.bookings.length > 0 ?
                            props.bookings.map((booking, index) => (
                                <Row
                                    key={index}
                                    booking={booking}
                                />
                            ))
                            : <tr><td colSpan={8}>No Recent Bookings</td></tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

HospitalRecentBookingsTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    bookings: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalRecentBookingsTable);