import React from 'react';
import {connect} from "react-redux";
import {removeNotification, resetNotification} from "../../redux/actions";
import {useLocation} from "react-router-dom";


function Notification(props) {
    const location = useLocation();
    const alertDetails = {
        error: {
            class: "alert-danger",
            title: "Error!",
        },
        success: {
            class: "alert-success",
            title: "Success!",
        },
        warning: {
            class: "alert-warning",
            title: "Warning!",
        },
        info: {
            class: "alert-primary",
            title: "Info!",
        },
    };

    const [currentNotifications, setCurrentNotifications] = React.useState([])
    const [pendingNotifications, setPendingNotifications] = React.useState([])

    //Reset notifications on page change
    React.useEffect(() => {
       setCurrentNotifications(pendingNotifications);
       setPendingNotifications([]);
       // eslint-disable-next-line
    }, [location]);

    React.useEffect(() => {
        if (props.notifications.length > 0) {
            setCurrentNotifications(props.notifications.filter(messages => messages.next === false));
            if (props.notifications.filter(messages => messages.next === true).length > 0) {
                setPendingNotifications([...pendingNotifications, ...props.notifications.filter(messages => messages.next === true)]);
            }
            props.resetNotification();

        }
        // eslint-disable-next-line
    }, [props.notifications]);

    return(
        currentNotifications.map((message, index) => {
            return(
                <div key={index} className={`alert ${alertDetails[message.variant].class}`}>
                    <div className="alert-content">
                        <h4 className="alert-heading" style={{marginTop: "1em"}}>{alertDetails[message.variant].title}</h4>
                        {message.message}
                    </div>
                </div>
            );
        })

    );
}

const mapStateToProps = state => {
    return {
        notifications: state.notificationState.notifications
    };
};

const mapDispatchToProps =  {
    resetNotification
};



export default connect(mapStateToProps, mapDispatchToProps)(Notification);