import React from 'react';
import {connect} from "react-redux";

function AdminEmailTemplates(props) {
    return(
        <>
            <h3>Users</h3>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmailTemplates);