import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Modal from "./Modal";


function ConfirmOptionModal(props) {

    // Handle dismissing modal if not overridden
    const onClickCancel = () => {
        if (!props.onClickCancel) {
            props.setConfirmationModalOpen(false);
        } else {
            props.onClickCancel();
        }

    }

    return(
        <Modal
            modalOpen={props.confirmationModalOpen}
            setModalOpen={props.setConfirmationModalOpen}
            header={props.headerText || "Confirm Action"}
        >
                <div className="pure-form pure-form-stacked">
                    <div className="pure-u-1 row-space">
                        {<p>{props.bodyText}</p> ||
                            <>
                                <p>Click Confirm to complete this action.</p>
                            </>
                        }
                    </div>
                    <div className="pure-u-1 button-row-space" style={{display:'flex'}}>
                        <button type="button" className="pure-button button-cancel" onClick={onClickCancel}>Cancel</button>
                        <button type="button" className="pure-button button-error left-space" onClick={() => {props.onClickConfirm(true)}}>Confirm</button>
                    </div>
                </div>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

ConfirmOptionModal.propTypes = {
    confirmationModalOpen: PropTypes.bool.isRequired,
    setConfirmationModalOpen: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func,
    onClickConfirm: PropTypes.func.isRequired,
    headerText: PropTypes.string,
    bodyText: PropTypes.string,

}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOptionModal);