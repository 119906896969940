import React from 'react';
import {connect} from "react-redux";
import HospitalSearchClinicsTable from "./HospitalSearchClinicsTable";
import HospitalSearchClinicsSearch from "./HospitalSearchClinicsSearch";
import {apiSendData} from "../../libs/Api";
import {findBestMatch, sortRatings, trimLowRatings} from '../../libs/StringCompare';

function HospitalSearchClinics(props) {
    const [tableLoading, setTableLoading] = React.useState(false);
    const [clinicData, setClinicData] = React.useState([]);
    const [filteredClinicData, setFilteredClinicData] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchSelectValue, setSearchSelectValue] = React.useState(1);

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    // Fetch calendar effect
    React.useEffect(() => {
        //Set loading overlay
        setTableLoading(true);

        apiSendData(
        '/api/hospital/clinic/',
            {},
            'get',
        ).then(response => {
            if (mountedRef.current) {
                setTableLoading(false);
                if (response && !response.error) {
                    setClinicData(response.data.clinics);
                    setFilteredClinicData((response.data.clinics));
                }
            }
        });
    }, []);

    React.useEffect(() => {
        // Skip search if length is less than 2
        if (searchValue.length < 2) {
            setFilteredClinicData(clinicData);
            return;
        }
        const matchResults = findBestMatch(searchValue, clinicData.map((clinic) => (searchSelectValue === 1 ? clinic.physician_name : clinic.clinic_name)));

        // Sort clinic data
        const sorted_results = sortRatings(matchResults.ratings);

        // Trim those with a zero rating
        const zeroTrimmedResults = trimLowRatings(sorted_results, (searchValue.length*0.01));
        console.log(sorted_results);
        console.log(zeroTrimmedResults);

        // Construct array of clinic data
        const newFilteredClinicData = [];
        zeroTrimmedResults.forEach(result => {
            newFilteredClinicData.push(clinicData[result.index]);
        });

        setFilteredClinicData(newFilteredClinicData);
    }, [searchValue, searchSelectValue]);

    return(
        <div className="pure-g">
            <div className="pure-u-1">
                <h3>Clinic Search by Physician</h3>
                <p>To find an appointment for your patient, search for their family physician's name or office name and click on the schedule link.</p>
                <HospitalSearchClinicsSearch
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setSearchSelectValue={setSearchSelectValue}
                    searchSelectValue={searchSelectValue}
                />
                <HospitalSearchClinicsTable
                    loading={tableLoading}
                    clinics={filteredClinicData}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalSearchClinics);