import React from 'react';
import {connect} from "react-redux";
import dayjs from "dayjs";
import {Form, Formik} from "formik";
import {apiSendData} from "../../../libs/Api";
import {showNotification} from "../../../redux/actions";
import * as Yup from "yup";
import {TextField, SearchSelectField} from "../CustomInputs";
import PropTypes from 'prop-types';
import PasswordRequirements from "../UserAccount/PasswordRequirements";
import history from "../history";
import * as Routes from "../../../libs/PCAOSSRoutes";
import CSRFTokenForm from "../CSRFTokenForm";


function UserCreateAccountForm(props) {
    const [healthcareCentreOptions, setHealthcareCentreOptions] = React.useState([]);

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    // Fetch healthcare centre options
    React.useEffect(() => {
        props.setLoading(true);
        apiSendData(
        '/api/users/healthcare-centre/options/',
            {},
            'get',
        ).then(response => {
            if (mountedRef.current) {
                props.setLoading(false);
                if (response && !response.error) {
                    setHealthcareCentreOptions(response.data.options)
                }
            }
        });
    }, []);

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <Formik
                initialValues={{
                    'healthcare_centre': '',
                    'username': '',
                    'first_name': '',
                    'last_name': '',
                    'email': '',
                    'password': '',
                    'password_confirm': '',
                }}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    props.setLoading(true);
                    apiSendData(
                    '/api/users/account-create/',
                        {...values},
                        'post',
                    ).then(response => {
                        if (mountedRef.current) {
                            props.setLoading(false);
                            if (response && response.error && response.code === "input_error" && response.input_errors) {
                                setErrors(response.input_errors);
                            }
                            if (response && !response.error) {
                                props.showNotification("You account has been created. All new accounts are reviewed by a system administrator. Once your account has been approved you will be notified via email.", "success", true);
                                history.push(Routes.LOGIN)
                            }
                        }
                    });
                }}
                validationSchema={Yup.object().shape({
                    healthcare_centre: Yup.string()
                        .required("Healthcare centre is required."),
                    username: Yup.string()
                        .min(5, "Username is too short.")
                        .max(150, "Username is too long.")
                        .required("Username is required."),
                    first_name: Yup.string()
                        .min(2, "First name is too short.")
                        .max(30, "First name is too long.")
                        .required("First name is required."),
                    last_name: Yup.string()
                        .min(2, "Last name is too short.")
                        .max(30, "Last name is too long.")
                        .required("Last name is required."),
                    email: Yup.string().email()
                        .required("Email is required."),
                    password: Yup.string()
                        .min(2, "Password is too short.")
                        .required("Password is required."),
                    password_confirm: Yup.string()
                        .min(2, "Password is too short.")
                        .required("Confirm Password is required.")
                })}
            >
                <Form autoComplete="off" className="pure-form pure-form-stacked" id="form-user-create-acct">
                    <CSRFTokenForm />
                    <div className="pure-u-1 pure-u-md-1-2">
                            <SearchSelectField
                                label="Healthcare Centre"
                                id="healthcare_centre"
                                name="healthcare_centre"
                                required="required"
                                options={healthcareCentreOptions}
                                helpText="Select a healthcare centre."
                            />

                    </div>
                    <div className="pure-u-1 row-space">
                        <TextField
                            label="Username"
                            id="username"
                            name="username"
                            required="required"
                            placeholder="Enter Username"
                            helpText="Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only."
                        />
                    </div>
                    <div className="pure-u-1 row-space">
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                            <div className="grid-space">
                                <TextField
                                    label="First Name"
                                    id="first_name"
                                    name="first_name"
                                    required="required"
                                    placeholder="First Name"
                                />
                            </div>
                        </div>
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                                <TextField
                                    label="Last Name"
                                    id="last_name"
                                    name="last_name"
                                    required="required"
                                    placeholder="Last Name"
                                />
                            </div>
                    </div>
                    <div className="pure-u-1 row-space">
                        <TextField
                            label="Email Address"
                            id="email"
                            name="email"
                            required="required"
                            placeholder="Email Address"
                            helpText="Please do not use your personal email."
                        />
                    </div>
                    <div className="pure-u-1">
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                            <div className="grid-space">
                                <TextField
                                    label="Password"
                                    id="password"
                                    name="password"
                                    required="required"
                                    type="password"
                                    placeholder="Password"
                                />
                            </div>
                            <PasswordRequirements />
                        </div>
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                            <TextField
                                label="Confirm Password"
                                id="password_confirm"
                                name="password_confirm"
                                required="required"
                                type="password"
                                placeholder="Confirm Password"
                                helpText="Enter the same password as before, for verification."
                            />
                        </div>
                    </div>
                    <div className="pure-u-1">
                        <button type="submit" className="pure-button button-std button-row-space">Create Account</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCreateAccountForm);

UserCreateAccountForm.propTypes = {
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    accountDetails: PropTypes.object,
}