import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {showNotification} from "../../../redux/actions";
import dayjs from "dayjs";


const Row = props => {
    return(
        <tr>
            <td>{dayjs(props.history.date).format("YYYY-MM-DD")}</td>
            <td>{dayjs(props.history.date).format("HH:mm")}</td>
            <td>{props.history.ip}</td>
            <td>{props.history.region !== null ? props.history.region : "-"}</td>
            <td>{props.history.city !== null ? props.history.city : "-"}</td>
            <td>{props.history.country !== null ? props.history.country: "-"}</td>
        </tr>
    )
}
Row.propTypes = {
    history: PropTypes.object,
}

function UserAccountLoginHistory(props) {

    return(
        <>
            <div className={props.loading ? 'loading-overlay-element': undefined}>
                <table className="pure-table pure-table-horizontal pure-table-striped table-hover row-space" style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th style={{whiteSpace: 'nowrap'}}>Login Date</th>
                            <th style={{whiteSpace: 'nowrap'}}>Login Time</th>
                            <th style={{whiteSpace: 'nowrap'}}>IP Address</th>
                            <th style={{whiteSpace: 'nowrap'}}>Region</th>
                            <th style={{whiteSpace: 'nowrap'}}>City</th>
                            <th style={{whiteSpace: 'nowrap'}}>Country</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.loginHistory.length > 0 ?
                                props.loginHistory.map(history => (
                                    <Row key={history.id} history={history} />
                                ))
                            : <tr><td colSpan={6}>No Login History</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}
UserAccountLoginHistory.propTypes = {
    loading: PropTypes.bool,
    loginHistory: PropTypes.array,
    setLoginHistory: PropTypes.func,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountLoginHistory);