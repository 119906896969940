import store from "../../redux/ConfigureStore";
import {Navigate, useLocation} from "react-router-dom";

const RequireNotAuth = ({children}) => {
    const authState = store.getState().authState;
    let location = useLocation();

    if (!authState.isLoggedIn) {
        return children
    }
    else {
        return <Navigate to="/login" state={{ from: location }} replace />
    }
}

export default RequireNotAuth;