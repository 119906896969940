import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Modal from "../../../Common/Modal";


function UrgentGIMNoticeModal(props) {

    return(
        <Modal
            modalOpen={props.modalOpen}
            setModalOpen={props.setModalOpen}
            disableClickOffClose={true}
            disableClose={true}
            header="Clinic Restricted Appointment Types"
        >
                <div className="pure-form pure-form-stacked">
                    <div className="pure-u-1 row-space">
                        <p><b>Patient referrals we will see in our Urgent Care Clinic:</b></p>
                        <ol>
                          <li>Cardiac - Chest pain follow-up (troponin negative or negative delta troponin), pericarditis, heart failure not requiring admission, syncope NYD, pre-syncope, atrial fibrillation or flutter, PVCs, cardiac risk factors (HTN, DLP, DMII).</li>
                          <li>Respiratory - Follow up for COPDe/asthma/URTI/pneumonia, cough NYD, dyspnea NYD (not hypoxic, d-dimer negative/PE ruled out)</li>
                          <li>GI - liver enzyme elevation NYD (abdo U/S should be done in ED), new Hepatitis C (HIV ruled out), latent TB (no active respiratory symptoms), post-travel GI symptoms and or fever</li>
                          <li>Hematologic - blood work abnormalities (e.g. thrombocytosis NYD, iron deficiency, anemia NYD, leukopenia NYD, elevated ferritin NYD)</li>
                          <li>Renal - AKI (obstruction ruled out in ED)</li>
                          <li>Urgent pre-op GIM/cardiac assessments</li>
                          <li>ID - post cellulitis/soft tissue infection, post-sepsis, post-urinary tract infections/cystitis</li>
                        </ol>
                        <p><b>Patient referrals we will NOT see:</b></p>
                        <ol>
                          <li>Age under 17 yrs</li>
                          <li>Trauma/MSK/Fractures</li>
                          <li>Patients with acute ACS</li>
                          <li>Acute pulmonary embolism, or elevated D-dimers without DVT/PE ruled out</li>
                          <li>Patients requiring oxygen (new)</li>
                          <li>Large pericardial effusions or large pleural effusions</li>
                          <li>New hematologic or solid organ cancer work-up</li>
                          <li>Abdo pain NYD/GERD/dysphagia - no scope capability at our center</li>
                          <li>Back pain</li>
                        </ol>
                    </div>
                    <div className="pure-u-1 button-row-space" style={{display:'flex',justifyContent:'space-between'}}>
                        <button type="button" className="pure-button button-cancel" onClick={props.onClickCancel}>Go Back</button>
                        <button type="button" className="pure-button button-success" onClick={props.onClickConfirm}>Proceed</button>
                    </div>
                </div>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

UrgentGIMNoticeModal.propTypes = {
    modalOpen: PropTypes.bool,
    setModalOpen: PropTypes.func,
    onClickCancel: PropTypes.func,
    onClickConfirm: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(UrgentGIMNoticeModal);