import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./redux/ConfigureStore";
import {BrowserRouter, unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';
import history from "./components/Common/history";
import App from './App';
import './css/pure.css';
import './css/pure-grids.css';
import './css/main_style.scss';
import "react-datepicker/dist/react-datepicker.css";


ReactDOM.render(
    <Provider store={store}>
        <HistoryRouter history={history}>
            <App />
        </HistoryRouter>
    </Provider>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
