import React from 'react';
import {connect} from "react-redux";
import dayjs from "dayjs";
import {Form, Formik} from "formik";
import {apiSendData} from "../../../libs/Api";
import {showNotification} from "../../../redux/actions";
import * as Yup from "yup";
import {TextField} from "../CustomInputs";
import PropTypes from 'prop-types';
import PasswordRequirements from "./PasswordRequirements";
import CSRFTokenForm from "../CSRFTokenForm";

function UserAccountPasswordForm(props) {
    const initialValues = {
        'old_password': '',
        'new_password1': '',
        'new_password2': '',
    }

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <Formik
                initialValues={initialValues}
                enableReinitialize={false}
                onSubmit={(values, {resetForm, setSubmitting, setErrors}) => {
                    props.setLoading(true);
                    apiSendData(
                    '/api/users/update-password/',
                    {...values},
                    'patch',
                    ).then(response => {
                        if (mountedRef.current) {
                            props.setLoading(false);
                            if (response && response.error && response.code === "input_error" && response.input_errors) {
                                setErrors(response.input_errors);
                            }
                            if (response && !response.error) {
                                props.showNotification("Your password was updated successfully.", "success");
                                resetForm();
                            }
                        }
                    });
                }}
                validationSchema={Yup.object().shape({
                    old_password: Yup.string()
                        .required("Your current password is required."),
                    new_password1: Yup.string()
                        .required("Password is required."),
                    new_password2: Yup.string()
                        .required("Confirm Password is required."),
                })}
            >
                <Form autoComplete="off" className="pure-form pure-form-stacked" id="form-acct-password">
                    <CSRFTokenForm />
                    <div className="pure-u-1 row-space">
                        <TextField
                            label="Current Password"
                            id="old_password"
                            name="old_password"
                            required="required"
                            type="password"
                            placeholder="Enter Current Password"
                        />
                    </div>
                    <div className="pure-u-1">
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                            <div className="grid-space">
                                <TextField
                                    label="New Password"
                                    id="new_password1"
                                    name="new_password1"
                                    autoComplete="off"
                                    required="required"
                                    type="password"
                                    placeholder="Enter New Password"
                                />
                            </div>
                            <PasswordRequirements />
                        </div>
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                            <TextField
                                label="Confirm New Password"
                                id="new_password2"
                                name="new_password2"
                                autoComplete="off"
                                required="required"
                                type="password"
                                placeholder="Enter Confirm New Password"
                                helpText="Enter the same password as before, for verification."
                            />
                        </div>
                    </div>
                    <div className="pure-u-1">
                        <button type="submit" className="pure-button button-std button-row-space">Update Password</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountPasswordForm);

UserAccountPasswordForm.propTypes = {
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
}