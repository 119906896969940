import React from 'react';
import {connect} from "react-redux";
import HospitalClinicBookingForm from "../Booking/HospitalClinicBookingForm";
import {formatDate} from "../../../libs/CommonFunctions";
import {apiSendData} from "../../../libs/Api";
import {useParams} from "react-router-dom";

function HospitalRecentBookingsEdit(props) {
    const {booking_id} = useParams();
    const [loading, setLoading] = React.useState(false);
    const [bookingData, setBookingData] = React.useState({});

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    // Fetching booking info
    React.useEffect(() => {
        setLoading(true);
        apiSendData(
        "/api/hospital/clinic/booking/",
            {'booking_id': booking_id},
            'get',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    setBookingData(response.data);
                }
            }
        });
    }, [])

    return(
        <>
            <div className={`pure-g ${props.loading ? 'loading-overlay-element': ''}`}>
            <div className="pure-u-1">
                <h3>Editing Appointment Booking</h3>
                {/*<p>Appointment for <strong>{formatDate(bookingData.date)}, {clinicInfo.appointment_time ? clinicInfo.appointment_time : ""} at {clinicInfo.clinic_name ? clinicInfo.clinic_name : "" }</strong> - Please fill out all sections, then click submit</p>*/}
                <HospitalClinicBookingForm
                    loading={loading}
                    setLoading={setLoading}
                    bookingData={bookingData}
                    showNotification={props.showNotification}
                    is_external={bookingData.is_external}
                />
            </div>
        </div>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalRecentBookingsEdit);