import React from 'react';
import PropTypes from 'prop-types';

function Modal(props) {

    const onClickClose = () => {
        if (!props.disableClose) {
            if (props.onModalClose) {
                props.onModalClose();
            }
            props.setModalOpen(false);
        }
    }

    return(
        <div className="modal-underlay" style={{display: props.modalOpen ? 'block': 'none'}} onClick={props.disableClickOffClose ? null : onClickClose}>
            <div className="modal" style={{display: props.modalOpen ? 'block': 'none'}}>
                <div className="modal-content" style={{maxWidth: props.maxWidth ? props.maxWidth : '950px', minHeight: props.minHeight ? props.minHeight : 'auto'}} onClick={(event) => {event.stopPropagation()}}>
                    {
                        props.header ?
                            <div className="modal-header">
                                <span className="close" onClick={onClickClose}>&times;</span>
                                <h3 style={{color: 'white'}}>{props.header}</h3>
                            </div>
                            : ''
                    }
                    <div className="modal-body">
                        {props.children}
                    </div>
                    {
                        props.footer ?
                            <div className="modal-footer">
                                <h3 style={{color: 'white'}}>{props.footer}</h3>
                            </div>
                        : ''
                    }
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    setModalOpen: PropTypes.func.isRequired,
    maxWidth: PropTypes.string,
    minHeight: PropTypes.string,
    header: PropTypes.string,
    footer: PropTypes.string,
    disableClickOffClose: PropTypes.bool,
    disableClose: PropTypes.bool,
}

export default Modal;