import React from 'react';
import PropTypes from 'prop-types';
import {FaFilter, FaSearch} from "react-icons/fa";

const SearchAndFilterBar = (props) => {

    const onClickFilter = (event, value) => {
        //Remove filter if it already exists
        if (props.filter.options.includes(value)) {
            props.setFilter({...props.filter, options: props.filter.options.filter((option) => option !== value)});
        } else {
            props.setFilter({...props.filter, options: [...props.filter.options, value]});
        }
        event.target.blur();
    };

    const onChangeSearch = (event) => {
        props.setFilter({...props.filter, searchTerm: event.target.value});
    };

    return (
        <div className="pure-g" style={{marginBottom:'2em', marginTop:'2em'}}>
            <div className="pure-u-1 pure-u-sm-1-2">
                <div className="pure-form icon-input-container">
                    <div className="input-icon field"><FaSearch /></div>
                    <input
                        className="icon-input-input"
                        type="text"
                        placeholder={props.searchPlaceholder}
                        value={props.filter.searchTerm}
                        onChange={onChangeSearch}
                    />
                </div>
            </div>
            <div className="pure-u-1 pure-u-sm-1-2" style={{display: 'flex', justifyContent: 'flex-end'}}>
                <div className="pure-form icon-input-container">
                    <div className="input-icon group"><FaFilter /></div>
                    <div className="pure-button-group" role="group">
                        {
                            props.filterOptions.map(
                                (option) => (
                                    <button
                                        key={option}
                                        className={`pure-button button-group-empty ${props.filter.options.includes(option) ? 'selected' : ''}`}
                                        onClick={(event) => onClickFilter(event, option)}
                                    >
                                        {option}
                                    </button>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

SearchAndFilterBar.propTypes = {
    filterOptions: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    searchPlaceholder: PropTypes.string,
}

export default SearchAndFilterBar;