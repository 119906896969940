import React from 'react';
import {FaRegCopy} from "react-icons/fa";
import ReactTooltip from 'react-tooltip';

const AppointmentRow = (props) => {
    const [showDetails, setShowDetails] = React.useState((new Date() < Date.parse(props.appointment.booking_start_date)));
    const detailsRef = React.createRef();

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    //Handle show/hide details
    React.useEffect(() => {
        if (showDetails) {
            detailsRef.current.style.maxHeight = detailsRef.current.scrollHeight + "px";
        } else {
            detailsRef.current.style.maxHeight = 0;
        }
    }, [showDetails]);

    const handleCopyReason = (text) => {
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }

    //Cancelled Class
    const cancelledClass = (props.appointment.cancelled) ? "cancelled-table-item" : ""

    return(
        <>
        <tr>
            <td>
                <div className={`status ${props.appointment.status}`}>{props.appointment.status}</div>
            </td>
            <td className={cancelledClass}>
                {props.appointment.booking_start_date}
                <br />
                {props.appointment.booking_start_time}
            </td>
            <td className={cancelledClass}>
                {props.appointment.physician_name}
            </td>
            <td className={cancelledClass}>
                {props.appointment.patient_name}
            </td>
            <td className={cancelledClass}>
                {props.appointment.patient_phone}
            </td>
            <td className={cancelledClass}>
                {props.appointment.booking_healthcare_centre_name}
            </td>
            <td>
                <button className="pure-button button-in-table button-std" onClick={toggleDetails}>{showDetails ? "Hide Details" : "View Details"}</button>
            </td>
        </tr>
        <tr style={{border: !showDetails ? '1px solid white' : '0'}}>
            <td colSpan={7} className={cancelledClass}>
            <div className="accordion-panel" ref={detailsRef}>
                {/* <div className='pure-g'>
                    <div className='pure-u-1 pure-u-md-1-3'>
                        <p><strong>Patient Name:</strong> {props.appointment.patient_name}</p>
                    </div>
                    <div className='pure-u-1 pure-u-md-1-3'>
                        <p><strong>Patient Age:</strong> {props.appointment.patient_age}</p>
                    </div>
                    <div className='pure-u-1 pure-u-md-1-3'>
                        <p><strong>Patient Phone:</strong> {props.appointment.patient_phone}</p>
                    </div>
                    <div className='pure-u-1 pure-u-md-1-3'>
                        <p><strong>Discharge Date:</strong> {props.appointment.discharge_date}</p>
                    </div>
                    <div className='pure-u-1 pure-u-md-1-3'>
                        <p><strong>Health Card #:</strong> {props.appointment.health_card_number}</p>
                    </div>
                    <div className='pure-u-1 pure-u-md-1-3'>&nbsp;</div>
                    <div className='pure-u-1 pure-u-md-1-3'>
                        <p><strong>Patient Has COVID Symptoms:</strong> {props.appointment.patient_covid_symptoms === null ? "Unknown" : props.appointment.patient_covid_symptoms ? "Yes" : "No"}</p>
                    </div>
                    <div className='pure-u-1 pure-u-md-1-3'>
                        <p><strong>COVID Infection Likely:</strong> {props.appointment.patient_covid_likely === null ? "Unknown" : props.appointment.patient_covid_likely ? "Yes" : "No"}</p>
                    </div>
                    <div className='pure-u-1'>
                        <p><strong>Is the appointment appropriate for virtual, if the FP wishes to make it virtual?:</strong> {props.appointment.virtual_appt_ok ? "Yes": "No"}</p>
                    </div>
                    <div className='pure-u-1'>
                        <p>
                            <strong>Reason for the Appointment </strong>
                            <FaRegCopy
                                style={{cursor: 'pointer'}}
                                onClick={() => handleCopyReason(props.appointment.reason_for_appt)}
                                data-tip
                                data-for="copyTooltip"
                            />
                        </p>
                        <p style={{whiteSpace: 'pre-line'}}>{props.appointment.reason_for_appt}</p>
                        <ReactTooltip id="copyTooltip" place="right" effect="solid">Copy</ReactTooltip>
                    </div>
                </div> */}
                <p><strong>Patient Name:</strong> {props.appointment.patient_name}</p>
                <p><strong>Patient Age:</strong> {props.appointment.patient_age}</p>
                <p><strong>Patient Phone:</strong> {props.appointment.patient_phone}</p>
                <p><strong>Discharge Date:</strong> {props.appointment.discharge_date}</p>
                <p><strong>Health Card #:</strong> {props.appointment.health_card_number}</p>
                <p><strong>Patient Has COVID Symptoms:</strong> {props.appointment.patient_covid_symptoms === null ? "Unknown" : props.appointment.patient_covid_symptoms ? "Yes" : "No"}</p>
                <p><strong>COVID Infection Likely:</strong> {props.appointment.patient_covid_likely === null ? "Unknown" : props.appointment.patient_covid_likely ? "Yes" : "No"}</p>
                <p><strong>Is the appointment appropriate for virtual, if the FP wishes to make it virtual?:</strong> {props.appointment.virtual_appt_ok ? "Yes": "No"}</p>
                <strong>Reason for the Appointment </strong>
                <FaRegCopy
                    style={{cursor: 'pointer'}}
                    onClick={() => handleCopyReason(props.appointment.reason_for_appt)}
                    data-tip
                    data-for="copyTooltip"
                />
                <br/>
                <p style={{whiteSpace: 'pre-line'}}>{props.appointment.reason_for_appt}</p>
                <ReactTooltip id="copyTooltip" place="right" effect="solid">Copy</ReactTooltip>
            </div>
                {/* <div className="accordion-panel" ref={detailsRef}>
                    <p><strong>Patient Name:</strong> {props.appointment.patient_name}</p>
                    <p><strong>Patient Age:</strong> {props.appointment.patient_age}</p>
                    <p><strong>Patient Phone:</strong> {props.appointment.patient_phone}</p>
                    <p><strong>Health Card #:</strong> {props.appointment.health_card_number}</p>
                    <p><strong>Discharge Date:</strong> {props.appointment.discharge_date}</p>
                    <p><strong>Is the appointment appropriate for virtual, if the FP wishes to make it virtual?:</strong> {props.appointment.virtual_appt_ok ? "Yes": "No"}</p>
                    <p><strong>Patient Has COVID Symptoms:</strong> {props.appointment.patient_covid_symptoms === null ? "Unknown" : props.appointment.patient_covid_symptoms ? "Yes" : "No"}</p>
                    <p><strong>COVID Infection Likely:</strong> {props.appointment.patient_covid_likely === null ? "Unknown" : props.appointment.patient_covid_likely ? "Yes" : "No"}</p>
                    <strong>Reason for the Appointment </strong>
                    <FaRegCopy
                        style={{cursor: 'pointer'}}
                        onClick={() => handleCopyReason(props.appointment.reason_for_appt)}
                        data-tip
                        data-for="copyTooltip"
                    />
                    <br/>
                    <p style={{whiteSpace: 'pre-line'}}>{props.appointment.reason_for_appt}</p>
                    <ReactTooltip id="copyTooltip" place="right" effect="solid">Copy</ReactTooltip>
                </div> */}
            </td>
        </tr>
        </>
    );
};

function ClinicAppointmentsTable(props) {

    return(
        <div className="pure-g">
            <div className="pure-u-1">
                <table className="pure-table pure-table-horizontal pure-table-striped table-hover-2n" style={{width: '100%'}}>
                    <thead>
                    <tr>
                        <th>Status</th>
                        <th>Appointment Date</th>
                        <th>Clinic Physician</th>
                        <th>Patient Name</th>
                        <th>Patient Phone</th>
                        <th>Booking Hospital</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            props.loading ?
                                <tr>
                                    <td><div className="small-loader-spinner"></div></td>
                                </tr>
                                :
                                props.appointments.length === 0 ?
                                    <tr><td colSpan={8}>No Records</td></tr>
                                    :
                                    props.appointments.map((appointment, index) => (
                                        <AppointmentRow key={index} appointment={appointment} />
                                    ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ClinicAppointmentsTable;