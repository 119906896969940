import {
    GET_LOGIN,
    SET_LOGIN,
    SET_LOGOUT,
    SET_TWO_FACTOR_SUCCESS,
    SET_ACCEPT_AGREEMENT,
} from "../actions";

const initState = {
    isLoggedIn: false,
    twoFactorRequired: false,
    signedAgreement: false,
    roles: '',
    userName: "",
    healthcareCentre: "",
    lastLoginDate: '',
    lastLoginLocation: '',
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case GET_LOGIN:
            return {...initState}
        case SET_LOGIN:
            return {
                ...state,
                isLoggedIn: action.payload.login_state,
                twoFactorRequired: action.payload.two_factor_required,
                signedAgreement: action.payload.signed_agreement,
                roles: [...action.payload.roles.map(e => e.id)],
                userName: action.payload.user_name,
                healthcareCentre: action.payload.healthcare_center_name,
                lastLoginDate: action.payload.last_login_date,
                lastLoginLocation: action.payload.last_login_location

            };
        case SET_LOGOUT:
            return {...initState};
        case SET_TWO_FACTOR_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                twoFactorRequired: false,
            }
        case SET_ACCEPT_AGREEMENT:
            return {
                ...state,
                signedAgreement: true,
            }
        default:
            return state;
    }
};

export default authReducer;