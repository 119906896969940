import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Formik, Form} from "formik";
import {TextField} from "../../Common/CustomInputs";
import Modal from "../../Common/Modal";
import CSRFTokenForm from "../../Common/CSRFTokenForm";


function ClinicSetupPhysiciansModal(props) {

    const initialValues = props.initialValues ? {physician_name: props.initialValues.physician_name} : {physician_name: ''};

    return(
        <Modal
            modalOpen={props.physiciansModalOpen}
            setModalOpen={props.setPhysiciansModalOpen}
            onModalClose={props.onPhysicianModalClose}
            header={props.initialValues ? "Update Physician" : "Add New Physician"}
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, setErrors, resetForm}) => {
                    // Check if updating existing physician
                    if (props.initialValues) {
                        props.updatePhysician({...values, edit_id: props.initialValues.id});
                    } else {
                        props.addPhysician(values);
                    }
                    props.setPhysiciansModalOpen(false);
                    resetForm();
                }}
            >
                <Form autoComplete="off" className="pure-form pure-form-stacked" id="form-clinic-setup">
                    <CSRFTokenForm />
                    <div className="pure-u-1 row-space">
                        <TextField
                            label="Physician Name"
                            id="physician_name"
                            name="physician_name"
                            placeholder="Surname, Firstname"
                            required="required"
                            helpText="Enter the physicians name in the format Surname, Firstname (i.e. Green, Mark or Green, Mark L)"
                        />
                    </div>
                    <div className="pure-u-1">
                        {
                            props.initialValues ?
                                <button type="submit" className="pure-button button-std button-row-space">Update Physician</button>
                                :
                                <button type="submit" className="pure-button button-std button-row-space">Add Physician</button>
                        }
                    </div>
                </Form>
            </Formik>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

ClinicSetupPhysiciansModal.propTypes = {
    physiciansModalOpen: PropTypes.bool,
    setPhysiciansModalOpen: PropTypes.func,
    addPhysician: PropTypes.func,
    updatePhysician: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSetupPhysiciansModal);