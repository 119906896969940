import dayjs from "dayjs";

const formatDate = (stringDate) => {
    const DOTW = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return `${DOTW[dayjs(stringDate).day()]} ${dayjs(stringDate).format("MMMM D, YYYY")}`;
}

const formatPhoneNumber = (number) => {
        number = number.replace(/\D/g,'');
        if (number.length === 10) {
            return `(${number.substring(0,3)}) ${number.substring(3,6)}-${number.substring(6,11)}`;
        }
        return number;
    }

export {
    formatDate,
    formatPhoneNumber,
}