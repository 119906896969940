import React from 'react';
import {connect} from "react-redux";
import history from "./history";
import * as Routes from '../../libs/PCAOSSRoutes';

function Lander(props) {
    // Redirect based on auth state
    console.log("#@#@$#@$!@$@$!@!$(*&!@^*&!T@&^R%^!R@^%R!@");
    React.useEffect(() => {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        console.log(`Agreement State: ${props.authState.signedAgreement}`);
        console.log(`Signed in  State: ${props.authState.isLoggedIn}`);
        if (!props.authState.isLoggedIn) {
            history.replace(Routes.LOGIN);
        } else if (!props.authState.signedAgreement) {
            history.replace(Routes.ACCEPT_AGREEMENT);
        }
        // else if (props.authState.roles === "Clinic") {
        //     console.log("In Role");
        //     history.replace(Routes.LOGIN);
        // }
        // eslint-disable-next-line
    }, [props.authState]);

    return(
        <><h4>In lander</h4></>
    );
}

const mapStateToProps = state => {
    return {
        authState: state.authState,
    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(Lander);