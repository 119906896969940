import React from 'react';
import * as Routes from '../../../libs/PCAOSSRoutes';
import DOMPurify from 'dompurify';
import {connect} from "react-redux";
import PropTypes from "prop-types";

const Row = (props) => {
    return (
        <tr>
            <td>
                {(props.issue.level === "Critical") && <span className="overview-issue critical">&#9888; </span>}
                {(props.issue.level === "Warning") && <span className="overview-issue warning">&#9888; </span>}
                {(props.issue.level === "Info") && <span className="overview-issue info">&#9432; </span>}
                <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.issue.message)}}></span>
            </td>
        </tr>
    )
}

function AdminOverviewIssuesTable(props) {
    return(
        <>
            <h4 className="big-space">Issues</h4>
            {/*<div>*/}
            {/*    <div className="pure-u-1-3">*/}
            {/*        <span className="overview-issue critical">&#9888; </span>*/}
            {/*        {props.data.issues ? props.data.issues.issues.filter(issue => (issue.level === "Critical")).length : 0} Critical Issues*/}
            {/*    </div>*/}
            {/*    <div className="pure-u-1-3">*/}
            {/*        <span className="overview-issue warning">&#9888; </span>*/}
            {/*        {props.data.issues ? props.data.issues.issues.filter(issue => (issue.level === "Warning")).length : 0} Warning Issues*/}
            {/*    </div>*/}
            {/*    <div className="pure-u-1-3">*/}
            {/*        <span className="overview-issue info">&#9432; </span>*/}
            {/*        {props.data.issues ? props.data.issues.issues.filter(issue => (issue.level === "Info")).length : 0} Info Issues*/}
            {/*    </div>*/}
            {/*</div>*/}

            <table id="issues-list" className="pure-table pure-table-horizontal pure-table-striped w-100">
                <tbody>
                    {
                        props.data.issues ?
                            props.data.issues.issues.map((issue, index) => (<Row key={index} issue={issue} />))
                            : <tr><td>No issues detected</td></tr>
                    }
                </tbody>
            </table>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

AdminOverviewIssuesTable.propTypes = {
    data: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOverviewIssuesTable);

// <h3 style="margin-top:2em;" className="header_color_override">Issues</h3>
// <table id="issues-list" className="pure-table pure-table-horizontal pure-table-striped" style="width:100%;">
//     <tbody>
//     {% if issue_list %}
//     {% for issue in issue_list %}
//     <tr>
//         <td>{% if issue.level == "Critical" %}<span style="color:red;">&#9888;</span>{% elif issue.level == "Warning" %}<span
//             style="color:rgb(223, 117,  20);">&#9888;</span>{% else %}<span
//             style="color:#2d3e50;">&#x2139;</span>{% endif %}</td>
//         <td>{{issue.message | safe}}</td>
//
//     </tr>
//     {% endfor %}
//     {% else %}
//     <tr>
//         <td>No Issues</td>
//     </tr>
//     {% endif %}
//     </tbody>
// </table>