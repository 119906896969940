import React from 'react';
import {connect} from "react-redux";
import {useParams} from 'react-router-dom';
import AdminUserEditForm from "./AdminUserEditForm";
import {apiSendData} from "../../../libs/Api";

function AdminUserEdit(props) {
    let { user_id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const buildUserFormState = (state) => {
        return {
            'is_active': state && state.is_active !== null ? state.is_active : true,
            'requires_activation': state && state.requires_activation !== null ? state.requires_activation : true,
            'healthcare_centre': state && state.healthcare_centre !== null ? state.healthcare_centre : '',
            'username': state && state.username ? state.username : '',
            'first_name': state && state.first_name ? state.first_name : '',
            'last_name': state && state.last_name ? state.last_name : '',
            'email': state && state.email ? state.email : '',
            'password': '',
            'password_confirm': '',
        }
    }

    const [initialValues, setInitialValues] = React.useState(buildUserFormState());

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    // Fetch user data
    React.useEffect(() => {
        if (user_id !== "new") {
            setLoading(true);
            apiSendData(
                `/api/admin/users/${user_id}/`,
                {},
                'get',
            ).then(response => {
                if (mountedRef.current) {
                    setLoading(false);
                    if (response && !response.error) {
                        setInitialValues(buildUserFormState(response.data.initial_values))
                    }
                }
            });
        }
    }, []);

    return(
        <>
            <h3>Edit User {initialValues.username}</h3>
            <AdminUserEditForm
                loading={loading}
                setLoading={setLoading}
                user_id={user_id}
                initialValues={initialValues}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserEdit);