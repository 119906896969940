import React from 'react';
import {setLogout, setAcceptAgreement, showNotification} from "../../redux/actions";
import {connect} from "react-redux";
import {apiSendData} from "../../libs/Api";
import history from "../Common/history";
import * as Routes from "../../libs/PCAOSSRoutes";
import authRouteFinder from "../../libs/AuthRouteFinder";
import pdfAddendum from '../../images/AWSBusinessAssociateAddendum.pdf'


function AcceptAgreement(props) {
    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const onClickAgree = (event) => {
        event.preventDefault();
        apiSendData('/api/users/agreement/', '', 'post', true)
            .then(response => {
                if (mountedRef.current) {
                    if (response && !response.error) {
                        props.setAcceptAgreement();
                        authRouteFinder();
                    }
                }
            })
    }

    const onClickDisagree = (event) => {
        event.preventDefault();
        apiSendData('/api/users/logout/', '', 'post', true)
            .then(response => {
                if (mountedRef.current) {
                    if (response && !response.error) {
                        props.showNotification("You have successfully signed out.", 'success', true);
                        props.setLogout();
                        history.push(Routes.LOGIN);
                    }
                }
            });
    }

    return(
        <>
            <h3>Primary Care Provider User Agreement</h3>
            <p>All users are required to read and accept the following license agreement before using their account.</p>
            <div className="license-agreement">
            <ol>
                <li>
                    <h6>Acceptance</h6>
                    <p>It is important that you read this Terms of Use Agreement ("Agreement") carefully. This Agreement is a legal agreement between you or the company/organization on whose behalf you are accepting this Agreement (“you”) , Dr. Clare Atzema (the “Principal Investigator”) and Sunnybrook Research Institute (the “Institution” and together with the Principal Investigator, "Sunnybrook") as the coordinating institution for the research study titled “Primary Care Online Appointment Scheduling Platform for Emergency Departments” (the “Study”). If you are entering into this Agreement on behalf of a company or other organization, you represent that you have the authority to bind your company/organization to this Agreement. For the purposes of this Agreement, you and/or your company/organization and Sunnybrook may each be referred to individual as a “Party” and together as the “Parties”. 
                    <br/><br/>One goal of the Study is to implement an online scheduling platform of urgent primary care appointments for vulnerable patients (the "Platform"), in a pilot project at three (3) Ontario emergency departments (“EDs”) and their surrounding primary care offices. This Agreement states the terms and conditions under which you may access and use the Platform and all information and other materials displayed or made available through the Platform. By using the Platform, you accept to be bound by the terms and conditions of the Agreement. If you do not accept these terms and conditions, you must not use the Platform. Sunnybrook may revise this Agreement at any time by posting the revised terms and conditions on the Platform. Using the Platform after Sunnybrook posts changes to the terms and conditions means that you accept and agree to the revised terms and conditions.
                    </p>
                </li>
                <li>
                    <h6>Platform/Study Description</h6>
                    <p>The internet-based Platform was designed by Sunnybrook to allow EDs at participating hospitals,  and their surrounding primary care provider (“PCP”) offices to schedule venue-appropriate (in-person or virtual) follow-up appointments to vulnerable patients, and measure the associated outcomes. The goals of the Study (the “Purposes”) are to generate the following:</p>
                    <ol type='a'>
                        <li>A secure, tested and optimized appointment scheduling platform that connects EDs  and PCP offices in order to provide rapid outpatient primary care follow- up, either in-person or virtually, for vulnerable patients seen in the ED;</li>
                        <li>Data demonstrating the impact of the Platform on timely outpatient follow-up care and associated outcomes, including hospital admissions;</li>
                        <li>Implementation processes identified and optimized for sustainable, effective scale and spread to different types of EDs and PCP offices.</li>
                    </ol>
                    <p>Data (as defined below) stored and transferred via the Platform, including without limitation Study participant Personal Health Information (as defined in the Personal Health Information Protection Act) and any information you enter into the Platform, or access via the Platform, will be hosted by Amazon Web Services (“AWS”). </p>
                </li>
                <li>
                    <h6>Account Terms and Conditions</h6>
                    <p>
                    You acknowledge that your use of the Platform will be governed by the AWS Customer Agreement available at <a href="http://aws.amazon.com/agreement">http://aws.amazon.com/agreement</a> (as updated from time to time) and the AWS Business Associate Addendum attached hereto (together, the “AWS Agreement”) and you agree to comply with all applicable terms of the AWS Agreement.
                    <br/><br/>Your account on the Platform (“Account”) will be password protected and only you will have access to the Platform using your Account. Your Account consists of an online user name ("User Name") and a password ("Password"). In order to receive an Account, you agree (i) to provide true, accurate, current and complete information about yourself as prompted by a registration form; and (ii) to maintain and promptly update the information about yourself to ensure it remains true, accurate, current and complete. You agree that you will not: (a) use or attempt to use the Platform with an Account, User Name and/or Password that is not yours, (b) access or attempt to gain access to information other than the information you require for the purpose of scheduling appointments at your PCP office, (c) share your User Name and Password with any other person, or (d) leave your Account signed on for the use or potential use of others. Inappropriate access to the Platform may be referred to legal authorities.
                    <br/><br/>You are responsible for maintaining the confidentiality of the User Name and Password you use in association with your Account and are responsible for all activities that occur under your User Name and Password. You agree to notify Sunnybrook immediately if you suspect or know of any unauthorized use of your User Name and/or Password or any other breach of security, and to ensure that you exit from your account at the end of each session. Sunnybrook and the participating EDs will not be liable for any loss or damage arising from your failure to protect your User Name or Password from loss or inappropriate use. 
                    <br/><br/>If Sunnybrook has reasonable grounds to suspect that you have failed to comply with the terms and conditions set out in this Section 3, Sunnybrook has the right to suspend or terminate your Account and your access to all or part of the Platform.
                    <br/><br/>Features and functions of the Platform and applicable policies and procedures for information handling may change without notice and it is your responsibility to ensure that you understand how to use the Platform in accordance with the current posted policies and procedures. 
                    </p>
                </li>
                <li>
                    <h6>Data and Privacy Terms</h6>
                    <p>
                    “Data” means all personal information (including without limitation medical data and information, and other personal health information) that has been collected by you, Sunnybrook, and/or the participating EDs for the purpose of carrying out the Study. You acknowledge and agree that as part of the Study, you may receive Data from the participating EDs and transfer Data to Sunnybrook and/or the participating EDs, subject to the terms of this Agreement.
                    <br/><br/>In transferring and/or receiving the Data, the Parties shall comply, and in the case of Sunnybrook, require participating EDs to comply, with all applicable privacy laws, regulations, guidelines and policies, including, without limitation the Personal Health Information Protection Act, 2004, S.O. 2004, c. 3 (“PHIPA”), (collectively “Privacy Laws”). The disclosing Party will prepare and furnish the Data in accordance with applicable Privacy Laws including without limitation obtaining all applicable consents as required by the reviewing Research Ethics Board(s) (“REB(s”). No Data will be transferred until the applicable REB(s) has approved the Study protocol. 
                    <br/><br/>The disclosing party, including without limitation each participating ED, retains the right but not the obligation to conduct audits of the receiving Party’s compliance with this Agreement upon reasonable advance written notice to the receiving Party, and at mutual acceptable times.
                    <br/><br/>If there is a breach of this Agreement by the receiving Party, the relevant disclosing party may require that all Data be returned promptly, or destroyed in a secure manner at the disclosing party’s option. Each party disclosing Data retains the right, acting on reasonable grounds, to refuse the transfer of the Data requested hereunder.
                    <br/><br/>The receiving Party (you and/or Sunnybrook) will limit its/their access to the Data only to its/their internal personnel and/or agents who need access for the purposes herein and who are bound by the same confidentiality obligations herein (“Study Staff”). Without limiting the obligation to comply with Privacy Laws, the receiving Party agrees that it/they shall, and shall require its/their Study Staff, to: 
                    </p>
                    <ol type='a'>
                        <li>maintain Data in confidence and not disclose Data except as permitted by this Agreement and, if applicable, the relevant ICF(s);</li>
                        <li>use Data solely for the purposes of the Study or other expressly consented purposes, in compliance with the Privacy Laws, ICF, and any written conditions imposed by the reviewing REB(s) (as may be communicated by Sunnybrook in writing);</li>
                        <li>not transfer the Data to any third parties without the prior written consent of the disclosing party and without obligating such third parties to comply with the terms and conditions of this Agreement. Notwithstanding the foregoing, the receiving Party may transfer the data:</li>
                        <ol type='a'>
                            <li>to regulatory authorities, provided that the receiving Party gives prior written notice of such intended disclosure to the disclosing party;</li>
                            <li>as otherwise permitted by the ICF or Waiver and applicable Privacy Laws;</li>
                            <li>in order to comply with an applicable law or judicial process, or with a court of regulatory order, provided that the Party subject to such  requirement gives prior written notice to the disclosing party and takes all lawful actions that are reasonable in the circumstances to minimize the extent of such disclosure and obtain confidential treatment for such disclosure.</li>
                        </ol>
                    </ol>
                    <p>Each Party will use appropriate safeguards (including without limitation with respect to encrypting identifying numbers, linking files, storing and retrieving files from secured locations) to prevent any unauthorized use or disclosure of the Data and shall promptly report to the other Party any unauthorized use or disclosure of which it/they become aware.
                    <br/><br/>Sunnybrook will not make contact or attempt to make contact with an individual study participant unless the participating ED first obtains the individuals’ consent to be contacted, except to the extent that Sunnybrook is otherwise the individual’s health information custodian, or as permitted by the ICF or applicable law.
                    <br/><br/>All Data and results arising from the Study shall be owned by Sunnybrook, with the exception of identifiable patient medical records which shall remain the property of the health information custodian who created such records. No right, title or interest in and to the Study Data or results is granted to you, or implied hereunder. 
                    </p>
                </li>
                <li>
                    <h6>Limitations of the Platform</h6>
                    <p>
                    You acknowledge that the Platform is provided by Sunnybrook solely for the purpose of scheduling appointments as part of the Study and is not intended to replace any record-keeping systems (electronic or otherwise) which you may use. You agree to maintain the medical records of any study participants for whom you are a Health Information Custodian (as defined in PHIPA) in accordance with all applicable laws, and shall not rely upon Sunnybrook or the Platform to maintain such records., and agree to maintain and its Contributing Providers in their discretion, as a convenience for patients - it is not core to the provision of patient care by Sunnybrook or any one of its Contributing Providers. The information in the Platform is not a substitute for advice, diagnosis or treatment from your health care provider(s).
                    <br/><br/>You acknowledge that Sunnybrook is providing the Platform as part of a research study and Sunnybrook makes no representations, warranties, or covenants, express or implied regarding the Platform, including without limitation that the Platform will be usable, fit for a particular purpose, uninterrupted, virus-free, or error-free.
                    </p>
                </li>
                <li>
                    <h6>Intellectual Property</h6>
                    <p>As between the Parties, all right, title and interest in the Platform is held solely by the Institution and, except for the right to access the Platform for the purpose of the Study, no right, title and interest in the Platform is granted to you hereunder. Any and all inventions or discoveries (whether patentable or not), innovations, improvements, new uses, processes or compounds made, conceived reduced to practice during, or directly arising from the conduct of the Study shall be solely owned by the Institution.</p>
                </li>
                <li>
                    <h6>Limitation of liability</h6>
                    <p>In no event shall either Party, and your/its respective physicians, other health care providers, officers, directors, employees, agents, licensors, and their respective successors and assigns, be responsible to for any direct, special, indirect, punitive, incidental or consequential damages arising from this Agreement</p>
                </li>
                <li>
                    <h6>Indemnity</h6>
                    <p>
                    You agree to indemnify, defend and hold harmless Sunnybrook, and the participating EDs, and their respective physicians, other health care providers, officers, directors, employees, agents, licensors, and their respective successors and assigns, from and against any and all claims, demands, liabilities, costs, or expenses whatsoever, including, without limitation, legal fees and disbursements (“Claims”), resulting directly or indirectly from (i) your use or misuse of the Platform which is contrary to any of the terms and conditions of this Agreement, or (ii) your breach of the Privacy Laws.
                    <br/><br/>The Institution agrees to indemnify, defend, and hold you (including your employees, agents, successors and assigns) harmless from and against any and all Claims arising from (i) the Institution’s use of the Data; (ii) breach of this Agreement by the Institution; and (iii) the negligence or willful misconduct of the Institution or its directors, officers, employees and agents.
                    </p>
                </li>
                <li>
                    <h6>Termination</h6>
                    <p>
                    This Agreement shall commence on the date that you accept the terms and conditions, as set out in Section 1 above (the “Effective Date”) and terminate on the completion of the Study unless terminated earlier in accordance with this Section 9. You may terminate this agreement for any reason by providing us notice and closing your Account. Sunnybrook may terminate this Agreement for any reason by providing you at least fifteen (15) days’ advance notice.
                    <br/><br/>Any Party may terminate this Agreement for cause, if another Party is in material breach of this Agreement and such breach remains uncured for a period of 30 days from receipt of notice by the other Party or Parties. Institution or Principal Investigator may terminate this Agreement immediately if (i) the terminating Party, in its/their sole reasonable opinion, deems that the safety of Study participants will be compromised by any delay in terminating; (ii) regulatory or REB approval for the Study is withdrawn; (iii) the Institution’s agreement with AWS is terminated; or (iv) Sunnybrook is entitled to terminate access to the Platform under Section 3. 
                    </p>
                </li>
                <li>
                    <h6>General Legal Terms and Conditions</h6>
                    <ul>
                        <li><b>Governing law and jurisdiction.</b> You agree that, as between you and Sunnybrook, all matters relating to this Agreement, or your access or use of the Platform and its Content shall be governed by the laws of the Province of Ontario and the laws of Canada applicable therein, without regard to conflict of laws principles. You agree and hereby submit to the exclusive jurisdiction of the courts of the Province of Ontario with respect to all matters between you and Sunnybrook relating to your access and use of the Platform and the Content as well as any dispute that may arise therefrom. </li>
                        <li><b>Waiver.</b> Any consent by Sunnybrook or a Contributing Provider, or waiver of, a breach of this Agreement which you have committed, whether express or implied, shall not constitute a consent to, or waiver of any other, different or subsequent breach.</li>
                        <li><b>Severability.</b> The invalidity or unenforceability of any provision of this Agreement or any covenant contained herein shall not affect the validity or enforceability of any other provision or covenant contained herein and any such invalid provision or covenant shall be deemed severable from the rest of the Agreement.</li>
                        <li><b>Entire Agreement.</b> This is the entire Agreement between you and Sunnybrook or any one of its Contributing Providers relating to your access and use of the Platform.</li>
                    </ul>
                </li>
            </ol>
            <a href= {pdfAddendum} target='_blank'>View AWS Business Associate Addendum</a>
            </div>
            <div className="pure-u-1">
                <button
                    type="button"
                    className="pure-button button-std button-row-space"
                    onClick={onClickAgree}
                >
                    Agree
                </button>
                <button
                    type="button"
                    className="pure-button button-cancel button-row-space button-in-table-space"
                    onClick={onClickDisagree}
                >
                    Disagree
                </button>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    setLogout,
    setAcceptAgreement,
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptAgreement);