import React from 'react';
import {connect} from "react-redux";
import AdminBookingsTable from "./AdminBookingsTable";
import {apiSendData} from "../../../libs/Api";
import SearchAndFilterBar from "../../Common/SearchAndFilterBar";

function AdminBookings(props) {
    const [loading, setLoading] = React.useState(false);
    const [bookings, setBookings] = React.useState([]);
    const [filter, setFilter] = React.useState({searchTerm: '', options: []});
    const statusOptions = ["Booked", "Cancelled"];

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    // Fetch bookings
    React.useEffect(() => {
        setLoading(true);
        apiSendData(
        '/api/admin/bookings/',
            {},
            'get',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    setBookings(response.data)
                }
            }
        });
    }, []);

    const filterBookings = (booking) => {
        return (
                !filter.options.length
                || (booking.cancelled && filter.options.includes("Cancelled")) || (!booking.cancelled && filter.options.includes("Booked"))
            )
            && (filter.searchTerm === '' || booking.patient_name.toLowerCase().includes(filter.searchTerm.toLowerCase()));
    };

    return(
        <>
            <h3>Bookings</h3>
            <p></p>
            <SearchAndFilterBar
                filterOptions={statusOptions}
                filter={filter}
                setFilter={setFilter}
                data={bookings}
                searchPlaceholder="Search Patient Name"
            />
            <AdminBookingsTable
                loading={loading}
                bookings={bookings.filter(filterBookings)}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBookings);