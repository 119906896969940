import {
    SHOW_NOTIFICATION,
    REMOVE_NOTIFICATION,
    RESET_NOTIFICATION,
    SHOW_BUSY,
    HIDE_BUSY
} from "../actions";

const notification = (message = "",
                      variant= "success",
                      next = false) => {
    return {
        message: message,
        variant: variant,
        next: next,
    }
};

const initState = {
    notifications: [],
    showBusy: false,
    showBusyText: ""
}

const notificationReducer = (state = initState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return {...state, notifications: [...state.notifications, notification(action.message, action.variant, action.next)]};
        case REMOVE_NOTIFICATION:
            let newNotificationState = [...state.notifications];
            newNotificationState = newNotificationState.slice(1);
            return {...state, notifications: newNotificationState};
        case RESET_NOTIFICATION:
            return {...state, notifications: []};
        case SHOW_BUSY:
            return {...state, showBusy: true, showBusyText: action.text}
        case HIDE_BUSY:
            return {...state, showBusy: false, showBusyText: ""}
        default:
            return state;
    }
};

export default notificationReducer;