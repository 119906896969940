import React from 'react';
import {connect} from "react-redux";
import UserAccountForm from "./UserAccountForm";
import UserAccountPasswordForm from "./UserAccountPasswordForm";
import UserAccountLoginHistory from "./UserAccountLoginHistory";
import {apiSendData} from "../../../libs/Api";

function UserAccount(props) {
    const [historyLoading, setHistoryLoading] = React.useState(false);
    const [accountDetailsLoading, setAccountDetailsLoading] = React.useState(false);
    const [passwordLoading, setPasswordLoading] = React.useState(false);
    const [loginHistory, setLoginHistory] = React.useState([]);
    const [accountDetails, setAccountDetails] = React.useState({});

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setHistoryLoading(true);
        apiSendData(
        '/api/users/login/history/',
        {},
        'get',
        ).then(response => {
            if (mountedRef.current) {
                setHistoryLoading(false);
                if (response && !response.error) {
                    setLoginHistory(response.data.login_history);
                }
            }
        });

        setAccountDetailsLoading(true);
        apiSendData(
        '/api/users/account/',
        {},
        'get',
        ).then(response => {
            if (mountedRef.current) {
                setAccountDetailsLoading(false);
                if (response && !response.error) {
                    setAccountDetails(response.data.user_data);
                }
            }
        });
    }, []);

    return(
        <>
            <div className="pure-u-1">
                <h3>Login History</h3>
            </div>
            <UserAccountLoginHistory
                loading={historyLoading}
                loginHistory={loginHistory}
                setLoginHistory={setLoginHistory}
            />
            <div className="pure-u-1 row-space-large">
                <h3>Update Account Details</h3>
            </div>
            <UserAccountForm
                loading={accountDetailsLoading}
                setLoading={setAccountDetailsLoading}
                accountDetails={accountDetails}
            />
            <div className="pure-u-1 row-space-large">
                <h3>Update Account Password</h3>
            </div>
            <UserAccountPasswordForm
                loading={passwordLoading}
                setLoading={setPasswordLoading}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);