import React from 'react';
import {connect} from "react-redux";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import CalendarHelper from "../../../libs/CalendarHelper";
import {showNotification} from "../../../redux/actions";
import {FaChevronLeft, FaChevronRight, FaPhone, FaWalking} from "react-icons/fa";

const EventList = (props) => {
    return(
        <ul style={{width:'100%', padding: 0}}>
            {
                props.events.filter(event => props.filterPhysicianId === undefined || event.physician_id === props.filterPhysicianId).map((event, index) => (
                    <li
                        key={`cal-event-${event.id}-event-${index}-${event.start_time}`}
                        style={props.onClickEvent ? {cursor: 'pointer'}: {}}
                        className={
                            `event ${event.past_event ? "event-past" 
                                : event.type === "booking" ? "event-booked"
                                    : event.type === "blackout" ? "event-blackout" 
                                        : event.appt_type == 1 ? "event-available" : "event-available-phone"}`
                        }
                        onClick={props.onClickEvent ? () => props.onClickEvent(event) : undefined}
                    >
                        {event.appt_type == 1 ? <FaWalking /> : <FaPhone />} {event.start_time}{event.type === "booking" ? " - Booked": event.type === "blackout" ? " - Unavailable" : ""}
                    </li>
                ))
            }
        </ul>
    );
}

EventList.propTypes = {
    events: PropTypes.array,
    onClickEvent: PropTypes.func,
    filterPhysicianId: PropTypes.number,
}

const Day = (props) => {
    return(
        <div className={`day ${props.day.is_today ? "today" : ""}`}>
            <p
                className="date"
                // className={`date ${props.day.currentMonth ? "" : "other-month"}`}
            >
                {props.day.day}
            </p>
            {
                (props.day.events && props.day.events.length > 0) ?
                    <EventList
                        events={props.day.events}
                        onClickEvent={props.onClickEvent}
                        filterPhysicianId={props.filterPhysicianId}
                    />
                    :
                    ""
            }
        </div>
    );
}

Day.propTypes = {
    day: PropTypes.object,
    onClickEvent: PropTypes.func,
    filterPhysicianId: PropTypes.number,
}

const Week = (props) => {
    return(
        <div className="week">
            {
                props.week.map((day, index) => (
                    <Day
                        key={`cal-day-${index}`}
                        day={day}
                        onClickEvent={props.onClickEvent}
                        filterPhysicianId={props.filterPhysicianId}
                    />
                ))
            }
        </div>
    );
}

Week.propTypes = {
    week: PropTypes.array,
    onClickEvent: PropTypes.func,
    filterPhysicianId: PropTypes.number,
}

const AppointmentCalendarMonthNav = (props) => {
    const onClickPreviousMonth = () => {
        props.setCalendarDate(props.calendarDate.subtract(1, 'month'));
    };

    const onClickNextMonth = () => {
        props.setCalendarDate(props.calendarDate.add(1, 'month'));
    };

    const onClickThisMonth = () => {
        props.setCalendarDate(dayjs());
    };

    return(
        <div style={{position: 'absolute', display: 'flex', marginLeft: '10px'}}>
            <FaChevronLeft className="calendar-button" style={{borderRightWidth: '1px'}} onClick={onClickPreviousMonth} />
            <FaChevronRight className="calendar-button" style={{borderLeftWidth: '1px'}} onClick={onClickNextMonth} />
            <p className="calendar-button" style={{marginLeft: '10px', marginTop: '0', paddingBottom: '3px'}} onClick={onClickThisMonth}>Today</p>
        </div>
    );
}

AppointmentCalendarMonthNav.propTypes = {
    calendarDate: PropTypes.object,
    setCalendarDate: PropTypes.func,
}


const AppointmentCalendar = (props) => {

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <div className="calendar-wrap calendar">
                <div className="calendar-header">
                    <AppointmentCalendarMonthNav calendarDate={props.calendarDate} setCalendarDate={props.setCalendarDate} />
                    <h4 style={{color: 'white', margin: '0'}}>{`${props.calendarDate.format('MMMM')} ${props.calendarDate.year()}`}</h4>
                </div>
                <div className="week">
                    <div className="weekday">Sunday</div>
                    <div className="weekday">Monday</div>
                    <div className="weekday">Tuesday</div>
                    <div className="weekday">Wednesday</div>
                    <div className="weekday">Thursday</div>
                    <div className="weekday">Friday</div>
                    <div className="weekday">Saturday</div>
                </div>
                {
                    props.calendar.length > 0 ?
                        props.calendar.map((week, index) => (
                            <Week
                                key={`cal-week-${index}`}
                                week={week}
                                onClickEvent={props.onClickEvent}
                                filterPhysicianId={props.filterPhysicianId}
                            />
                        ))
                        :""
                }
            </div>
        </div>
    );
}

AppointmentCalendar.propTypes = {
    loading: PropTypes.bool,
    calendar: PropTypes.array,
    calendarDate: PropTypes.object,
    setCalendarDate: PropTypes.func,
    onClickEvent: PropTypes.func,
    filterPhysicianId: PropTypes.number,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentCalendar);