import React from "react";
import Header from "./Header"
import Notification from "./Notification";

import {connect} from "react-redux";

function BodyWrapper(props) {

    return(
        <div className="page-container">
            <Header />
            <Notification />
            <div className="content-wrapper">
                {props.children}
                <div style={{height: "5em", clear: "both"}}>&nbsp;</div>
            </div>
            <footer className="footer">
                <p>&nbsp;</p>
            </footer>
        </div>

    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};



export default connect(mapStateToProps, mapDispatchToProps)(BodyWrapper);