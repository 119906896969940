import store from "../redux/ConfigureStore";
import history from "../components/Common/history";
import * as Routes from './PCAOSSRoutes';

const authRouteFinder = (from = undefined) => {
    const authState = store.getState().authState;

    if (!authState.isLoggedIn) {
        history.push(Routes.LOGIN);
    } else if (!authState.signedAgreement) {
        history.push(Routes.ACCEPT_AGREEMENT);
    } else if (from !== undefined) {
        history.push(from);
    } else if (authState.roles.includes(3)) {
        history.push(Routes.ADMIN_OVERVIEW);
    } else if (authState.roles.includes(1)) {
        history.push(Routes.CLINIC_SETUP_APPOINTMENTS);
    } else if (authState.roles.includes(2)) {
        history.push(Routes.HOSPITAL_CLINIC_SEARCH);
    } else {
        console.log("AuthRouteFinder: Requested route unknown");
    }
}

export default authRouteFinder;