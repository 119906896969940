import React from 'react';
import {connect} from "react-redux";
import ClinicAppointmentsTable from "./ClinicAppointmentsTable";
import {apiSendData} from "../../libs/Api";
import {FaFilter, FaSearch} from "react-icons/fa";


const ClinicAppointmentsSummary = (props) => {

    const onClickFilter = (event, value) => {
        //Remove filter if it already exists
        if (props.filter.options.includes(value)) {
            props.setFilter({...props.filter, options: props.filter.options.filter((option) => option !== value)});
        } else {
            props.setFilter({...props.filter, options: [...props.filter.options, value]});
        }
        event.target.blur();
    };

    const onChangeSearchPatientName = (event) => {
        props.setFilter({...props.filter, patientName: event.target.value});
    };

    return (
        <div className="pure-g" style={{marginBottom:'2em', marginTop:'2em'}}>
            <div className="pure-u-1 pure-u-sm-1-2">
                <div className="pure-form icon-input-container">
                    <div className="input-icon field"><FaSearch /></div>
                    <input
                        className="icon-input-input"
                        type="text"
                        placeholder="Search Patient Name"
                        value={props.filter.patientName}
                        onChange={onChangeSearchPatientName}
                    />
                </div>
            </div>
            <div className="pure-u-1 pure-u-sm-1-2" style={{display: 'flex', justifyContent: 'flex-end'}}>
                <div className="pure-form icon-input-container">
                    <div className="input-icon group"><FaFilter /></div>
                    <div className="pure-button-group" role="group">
                        <button
                            className={`pure-button button-group-empty ${props.filter.options.includes('Upcoming') ? 'selected' : ''}`}
                            onClick={(event) => onClickFilter(event, 'Upcoming')}
                        >
                            Upcoming
                        </button>
                        <button
                            className={`pure-button button-group-empty ${props.filter.options.includes('Complete') ? 'selected' : ''}`}
                            onClick={(event) => onClickFilter(event, 'Complete')}
                        >
                            Complete
                        </button>
                        <button
                            className={`pure-button button-group-empty ${props.filter.options.includes('Cancelled') ? 'selected' : ''}`}
                            onClick={(event) => onClickFilter(event, 'Cancelled')}
                        >
                            Cancelled
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ClinicAppointments(props) {
    const [appointments, setAppointments] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filter, setFilter] = React.useState({patientName: '', options: []});

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    //Fetch appointments
    React.useEffect(() => {
        apiSendData('/api/clinic/bookings/', '', 'get')
            .then(response => {
                if (mountedRef.current) {
                    setLoading(false);
                    if (response && !response.error) {
                        setAppointments(response.data.bookings);
                    }
                }
            })
    }, []);

    const filterAppointments = (appointment) => {
        return (!filter.options.length || filter.options.includes(appointment.status))
            && (filter.patientName === '' || appointment.patient_name.toLowerCase().includes(filter.patientName.toLowerCase()));
    };

    return(
        <div className="pure-g">
            <div className="pure-u-1">
                <h3>Already Booked (previous or pending) Appointments</h3>
                <p>Appointments that have been booked at your clinic by emergency departments will appear here.
                You will be notified via email when a new appointment has been booked at your clinic.
                </p>
                <ClinicAppointmentsSummary appointments={appointments} filter={filter} setFilter={setFilter} />
                <ClinicAppointmentsTable appointments={appointments.filter(filterAppointments)} loading={loading} />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicAppointments);