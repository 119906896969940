import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Modal from "../../Common/Modal";


function ClinicSetupPhysiciansDeleteModal(props) {

    return(
        <Modal
            modalOpen={props.physiciansDeleteModalOpen}
            setModalOpen={props.setPhysiciansDeleteModalOpen}
            header="Confirm Delete Physician?"
        >
                <div className="pure-form pure-form-stacked">
                    <div className="pure-u-1 row-space">
                        <p>Are you sure you want to delete this physician?</p>
                        <p>You can optionally delete this physicians associated appointments as well. If you choose to only delete the physician their appointment times will become general clinic appointments.</p>
                    </div>
                    <div className="pure-u-1 button-row-space" style={{display:'flex',justifyContent:'space-around'}}>
                        <button type="button" className="pure-button button-cancel" onClick={props.onClickCancel}>Cancel</button>
                        <button type="button" className="pure-button button-warning" onClick={() => {props.onClickConfirmRemovePhysician(true)}}>Only Delete Physician</button>
                        <button type="button" className="pure-button button-error" onClick={() => {props.onClickConfirmRemovePhysician(false)}}>Also Delete Physician Appointments</button>
                    </div>
                </div>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

ClinicSetupPhysiciansDeleteModal.propTypes = {
    physiciansDeleteModalOpen: PropTypes.bool,
    setPhysiciansDeleteModalOpen: PropTypes.func,
    onClickCancel: PropTypes.func,
    onClickConfirmRemovePhysician: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSetupPhysiciansDeleteModal);