import React, {PureComponent} from 'react';
import * as Routes from '../../../libs/PCAOSSRoutes';
import {connect} from "react-redux";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";


function AdminOverviewBlocks(props) {
    return(
        <>
            <div className="pure-g text-center">
                <div className="pure-u-1 pure-u-md-1-4">
                    <p>
                        <span className="overview-blocks-text">{props.data.issues ? props.data.issues.issue_count : "0"}</span>
                        <br />
                        <span className="overview-subblocks-text">Potential Issues</span>
                    </p>
                    <a href="#issues-list">View Issues</a>
                </div>
                <div className="pure-u-1 pure-u-md-1-4">
                    <p>
                        <span className="overview-blocks-text">{props.data.stats ? props.data.stats.active_users : "0"}</span>
                        <br />
                        <span className="overview-subblocks-text">Registered Users</span>
                    </p>
                    <Link to={Routes.ADMIN_USER}>View Users</Link>
                </div>
                <div className="pure-u-1 pure-u-md-1-4">
                    <p>
                        <span className="overview-blocks-text">{props.data.stats ? props.data.stats.total_clinics : "0"}</span>
                        <br />
                        <span className="overview-subblocks-text">Clinics Enrolled</span>
                    </p>
                    <Link to={Routes.ADMIN_HEALTHCARE_CENTRE}>View Clinics</Link>
                </div>
                <div className="pure-u-1 pure-u-md-1-4">
                    <p>
                        <span className="overview-blocks-text">{props.data.stats ? props.data.stats.total_bookings : "0"}</span>
                        <br />
                        <span className="overview-subblocks-text">Appointments Booked</span></p>
                    <Link to={Routes.ADMIN_BOOKING}>View Bookings</Link>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

AdminOverviewBlocks.propTypes = {
    data: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOverviewBlocks);