import React from 'react';
import {connect} from "react-redux";
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import ClinicSetupPhysiciansModal from "./ClinicSetupPhysiciansModal";
import {showNotification} from "../../../redux/actions";
import {apiSendData} from "../../../libs/Api";
import ModifyButton from "../../Common/ModifyButtons";
import ClinicSetupPhysiciansDeleteModal from "./ClinicSetupPhysiciansDeleteModal";

function ClinicSetupPhysicians(props) {
    let { centre_id } = useParams();

    const [physiciansModalOpen, setPhysiciansModalOpen] = React.useState(false);
    const [physiciansDeleteModalOpen, setPhysiciansDeleteModalOpen] = React.useState(false);
    const [physiciansDeleteID, setPhysiciansDeleteID] = React.useState(-1);
    const [initialValues, setInitialValues] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const onPhysicianModalClose = () => {
        setInitialValues(undefined);
    }

    const addPhysician = (values) => {
        setLoading(true);
        apiSendData(
        '/api/clinic/physicians/',
            {...values, centre_id: centre_id},
            'post',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    props.showNotification(`${values['physician_name']} was successfully added.`, 'success');
                    props.setPhysicians(response.data.physicians);
                }
            }
        });
    }

    const onClickEditPhysician = (values) => {
        // Set initial values
        setInitialValues(values);
        // Show modal
        setPhysiciansModalOpen(true);
    }

    const onClickSaveEditPhysician = (values) => {
        setLoading(true);
        apiSendData(
        '/api/clinic/physicians/',
            {...values, centre_id: centre_id},
            'patch',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    props.showNotification(`${values['physician_name']} was successfully updated.`, 'success');
                    props.setPhysicians(response.data.physicians);
                    props.setAppointments(response.data.appointments)
                }
            }
        });
    }

    const onClickRemovePhysician = (physician_id) => {
        setPhysiciansDeleteModalOpen(true);
        setPhysiciansDeleteID(physician_id);
    }

    //Confirm Removal Options
    const onClickRemoveCancel = () => {
        setPhysiciansDeleteModalOpen(false);
        setPhysiciansDeleteID(null);
    };

    const onClickConfirmRemovePhysician = (onlyPhysician) => {
        setPhysiciansDeleteModalOpen(false);
        setLoading(true);
        apiSendData(
        '/api/clinic/physicians/',
            {centre_id: centre_id, physician_id: physiciansDeleteID, only_physician: onlyPhysician},
            'delete',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    props.showNotification(`Physician was successfully removed.`, 'success');
                    props.setPhysicians(response.data.physicians);
                    props.setAppointments(response.data.appointments)
                }
            }
        });
    }

    return(
        <>
            <h3 className="header-spacing">Clinic Physicians</h3>
            <p>Please ensure every physician at your clinic is listed.</p>
            <div className={`pure-u-1 pure-u-md-1-3 ${loading && 'loading-overlay-element'}`} style={{width: '300px'}}>
                <table className="pure-table pure-table-horizontal pure-table-striped table-hover row-space" style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th>Physician Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.physicians.length ?
                                props.physicians.map(physician => (
                                    <tr key={physician.id}>
                                        <td style={{whiteSpace: 'nowrap'}}>{physician.physician_name}</td>
                                        <td style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                                            <ModifyButton type='edit' onClickHandler={() => {onClickEditPhysician(physician)}} />
                                            <ModifyButton type='delete' onClickHandler={() => {onClickRemovePhysician(physician.id)}} />
                                        </td>
                                    </tr>
                                ))
                            : <tr><td colSpan={2}>No Clinic Physicians</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="pure-u-1">
                <button
                    className="pure-button button-std button-row-space"
                    onClick={() => {setPhysiciansModalOpen(true)}}
                >
                    Add New Clinic Physician
                </button>
            </div>
            <ClinicSetupPhysiciansModal
                physiciansModalOpen={physiciansModalOpen}
                setPhysiciansModalOpen={setPhysiciansModalOpen}
                addPhysician={addPhysician}
                onPhysicianModalClose={onPhysicianModalClose}
                initialValues={initialValues}
                updatePhysician={onClickSaveEditPhysician}
            />
            <ClinicSetupPhysiciansDeleteModal
                physiciansDeleteModalOpen={physiciansDeleteModalOpen}
                setPhysiciansDeleteModalOpen={setPhysiciansDeleteModalOpen}
                onClickCancel={onClickRemoveCancel}
                onClickConfirmRemovePhysician={onClickConfirmRemovePhysician}
            />
        </>
    );
}

ClinicSetupPhysicians.propTypes = {
    physicians: PropTypes.array,
    setPhysicians: PropTypes.func,
    setAppointments: PropTypes.func,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSetupPhysicians);