import React from 'react';
import {connect} from "react-redux";
import HospitalClinicBookingConfirmed from "../Booking/HospitalClinicBookingConfirmed";

function HospitalRecentBookingsView(props) {
    return(
        <>
            <HospitalClinicBookingConfirmed />
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalRecentBookingsView);