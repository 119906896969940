export const GET_LOGIN = "GET_LOGIN";
export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_TWO_FACTOR_SUCCESS = "TWO_FACTOR_SUCCESS";
export const SET_ACCEPT_AGREEMENT = "SET_ACCEPT_AGREEMENT";

export const getLogin = () => {
    return {
        type: GET_LOGIN,
    }
};

export const setLogin = (payload) => {
    return {
        type: SET_LOGIN,
        payload
    }
};

export const setLogout = () => {
    return {
        type: SET_LOGOUT
    }
};

export const setTwoFactorSuccess = () => {
    return {
        type: SET_TWO_FACTOR_SUCCESS
    }
}

export const setAcceptAgreement = () => {
    return {
        type: SET_ACCEPT_AGREEMENT
    }
}