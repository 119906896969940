import notificationReducer from "./notificationReducer";
import authReducer from "./authReducer";


import {combineReducers} from "redux";

const rootReducer = combineReducers({
    notificationState: notificationReducer,
    authState: authReducer,
});

export default rootReducer;
