import {showNotification, setLogout, setLogin} from "../redux/actions";
import store from "../redux/ConfigureStore";
import history from "../components/Common/history";
import * as Routes from './PCAOSSRoutes';
import authRouteFinder from "./AuthRouteFinder";

//const baseURL = 'http://localhost:8000'; // Development only
const baseURL = '/'; // Production
const axios = require('axios').default;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.timeout = 10000; //10seconds
axios.defaults.withCredentials = true;

const handleError = (error, applicationError = false) => {
    let message = "";
    //Handle not authorization error
    if (error.response.status === 401) {
        store.dispatch(showNotification("Your session has expired. You will need login again to continue.", "warning", true));
        store.dispatch(setLogout());
        history.push(Routes.LOGIN);
        return;
    } else if (error.response.status === 403) {
        store.dispatch(showNotification("You do no have permission to view this page.", "error", true));
        authRouteFinder();
    } else if (error.response.status === 500) {
        store.dispatch(showNotification("An internal server error occurred while processing your request.", "error", false));
        error.response.data = {'error': true};
    } else if (error.response) { //Response with status code (out of 2xx range)
        //Reformat
        message = `${error.response.data.detail ? error.response.data.detail: "unknown"}`;
        //Reformat errors if necessary
        if (error.response.data.input_errors) {
            Object.keys(error.response.data.input_errors).map(key => {
                error.response.data.input_errors[key] = error.response.data.input_errors[key][0];
            })
        }
    } else if (error.request) {
        //Request was made but no response was received
        message = `Error: the request was made but no response was received by the server.`;
    } else {
        //Something else happened
        message = `Error: an unknown error occurred.`;
    }
    store.dispatch(showNotification(message, "error"));
};

const handleSuccess = (detail) => {
    store.dispatch(showNotification(detail, "success"));
};

const handleWarning = (detail) => {
    store.dispatch(showNotification(detail, "warning"));
};

export function apiSendData(url, data, method = 'post') {
    axios.defaults.baseURL = baseURL;

    //Get current state
    return axios({
        method: method,
        url: url,
        data: data,
        params: (method === 'get') ? data : null,
    }).then(response => {
        if (response.data.error) {
            handleWarning(response.data.detail);
        } else {
            if (response.data.error === false && response.data.detail !== "") {
                handleSuccess(response.data.detail);
            }
        }
        return response.data;
    }).catch(error => {
        // Catch timeout
        if (!error.response) {
            store.dispatch(showNotification("Unable to connect to the server. Connection timed out.", "error"));
        } else {
            handleError(error);
            if (!(error.response.status === 403 || error.response.status === 401)) {
                return error.response.data;
            }
        }
    });
}


axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        const originalRequest = error.config;
        const authState = store.getState().authState;

        // Check if previous api login attempt failed
        if ((error.response.status === 403 || error.response.status === 401) && originalRequest.url.includes('login/refresh/')) {
            return Promise.reject(error);
        }

        // Attempt to refresh login information
        if ((error.response.status === 403 && error.response.data.detail=="Authentication credentials were not provided.") || error.response.status === 401) {
            return axios
                .get('/api/users/login/refresh/')
                .then((response) => {
                    // Handle login state
                    store.dispatch(setLogin(response.data));
                    return axios(originalRequest);
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        }

        // Check if user is logged in but doesn't have permission to view the requested page
        if ((error.response.status === 403 || error.response.status === 401) && (authState && authState.isLoggedIn)) {
            //history.push('/');
            error.detail = "You do not have permission to view this page!"
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
)