import React from "react";
import {showNotification, setLogin} from "../../redux/actions";
import {connect} from "react-redux";
import {Formik, Form} from "formik";
import {TextField} from '../Common/CustomInputs'
import {Link} from "react-router-dom";
import * as Routes from '../../libs/PCAOSSRoutes';
import {apiSendData} from "../../libs/Api";
import CSRFTokenForm from "../Common/CSRFTokenForm";


const EmailSentConfirmation = (props) => (
    <div className="pure-g">
        <div className="pure-u-1">
            <h3>Password Reset Email Sent</h3>
            <p>An email has been sent with instructions on how to reset your password.</p>
            <p>If you don't receive an email in the next 5 minutes make sure to check your spam folder and confirm
                that you've entered the correct email address.</p>
            <Link to={Routes.LOGIN}>Click here to return to the login screen</Link>
        </div>
    </div>
)

const ForgotPasswordForm = (props) => {
    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    return (
        <Formik
            initialValues={{email: ''}}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                apiSendData(
                    '/api/users/login/forgot-password/',
                    values,
                    'post',
                    true,
                ).then(response => {
                    if (mountedRef.current) {
                        //setSubmitting(false);
                        if (response && !response.error) {
                            props.setEmailSent(true);
                        } else {
                            setErrors(response.input_errors);
                        }
                    }
                });
            }}
        >
            <Form className="pure-form pure-form-stacked" id="form-forgot-password">
                <CSRFTokenForm/>
                <div className="pure-u-1 row-space">
                    <TextField
                        label="Account Email"
                        id="email"
                        name="email"
                        type="email"
                        required="required"
                        autoFocus={true}
                        placeholder="Account Email"
                    />
                </div>
                <div className="pure-u-1">
                    <button type="submit" className="pure-button button-std button-row-space">Send Password Reset
                        Instructions
                    </button>
                </div>
            </Form>
        </Formik>
    );
}

function ForgotPassword(props) {
    const [emailSent, setEmailSent] = React.useState(false);

    if (emailSent) {
        return (
            <EmailSentConfirmation />
        );
    } else {
        return (
            <div className="pure-g">
                <div className="pure-u-1">
                    <div className="pure-u-1">
                        <h3>Password Reset</h3>
                    </div>
                    <div className="pure-u-1">
                        <p>To reset your password please provide the email address associated with your account.</p>
                    </div>
                    <ForgotPasswordForm {...props} setEmailSent={setEmailSent}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);