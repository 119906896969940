import React from "react";
import PropTypes from "prop-types";

const PasswordRequirements = (props) => {

    return(
        <ul className="pure-form-message" style={{margin:0}}>
            <li>Your password must contain at least 8 characters.</li>
            <li>Your password can't be a commonly used password.</li>
            <li>Your password must include three of the following: <br/>Uppercase, Lowercase, Numbers, Symbols</li>
        </ul>
    )
}

PasswordRequirements.propTypes = {
    password: PropTypes.string,
}

export default PasswordRequirements;