import React from 'react';
import {connect} from "react-redux";
import dayjs from "dayjs";
import {apiSendData} from "../../../libs/Api";
import {useParams} from "react-router-dom";
import {formatPhoneNumber} from "../../../libs/CommonFunctions";

function HospitalClinicBookingConfirmed(props) {
    const {booking_id} = useParams();

    const [bookingDetails, setBookingDetails] = React.useState({
        'virtual_appt_ok': false,
        'patient_name': '',
        'booking_date': '',
        'booking_time': '',
        'healthcare_centre': '',
        'phone_number': '',
        'address_line_1': '',
        'address_line_2': '',
        'city': '',
        'postal_code': '',
    });

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    // Fetch booking info
    React.useEffect(() => {
        apiSendData(
        '/api/hospital/clinic/booking-confirmation/',
            {
                booking_id: booking_id,
            },
            'get',
        ).then(response => {
            if (mountedRef.current) {
                if (response && !response.error) {
                    setBookingDetails(response.data.booking_details);
                }
            }
        });
        // eslint-disable-next-line
    }, [])


    const onClickPrintButton = () => {
        window.print();
    }

    return(
        <>
        <div className="pure-g" id="print-confirmation-block">
            <div className="pure-u-1">
                <h2
                    className="header_color_override"
                    style={{textAlign: 'center'}}>Primary Care Appointment
                    <br/>
                    Online Scheduling System for EDs
                </h2>
            </div>
            <div className="pure-u-1">
                <h4 className="header_color_override" style={{textAlign:'center'}}>A pilot study funded by CIHR</h4>
            </div>


            <div className="pure-u-1">
                <h2 style={{color: 'red', marginTop:'0.5rem', marginBottom:0, textAlign: 'center'}}>Appointment Confirmation</h2>
            </div>
            <div className="pure-u-1" style={{backgroundColor:'#f6f6f6', marginTop:'2em', textAlign: 'center'}}>
                <h3 className="header-spacing" style={{color: '#000000'}}>{bookingDetails.booking_date}<br/>{bookingDetails.booking_time}</h3>
                <p style={{fontSize:'1.8em', margin:0, color: '#000000'}}>{bookingDetails.patient_name}</p>
                <p style={{fontSize:'1.8em', marginTop:0, color: '#000000'}}>{bookingDetails.healthcare_centre}</p>
                {
                    bookingDetails.virtual_appt_ok ?
                        <>
                            <h3>VIRTUAL APPOINTMENT</h3>
                            <p style={{fontSize:'1.3em'}}>
                                Please do not go to your clinic. You can follow up by calling your clinics number shown below.
                            </p>
                        </>
                        : undefined
                }
            </div>
            <div className="pure-u-1">
                <p style={{fontSize:'1.4em', textAlign: 'center'}}>To cancel or reschedule your appointment please contact your family clinic.</p>
            </div>
            <div className="pure-u-1" style={{fontSize:'1.4em', textAlign: 'center'}}>
                {formatPhoneNumber(bookingDetails.phone_number)}<br/>
                {bookingDetails.address_line_1},
                {bookingDetails.address_line_2 ? bookingDetails.address_line_2 : ""}
                {bookingDetails.city},
                {bookingDetails.postal_code}
            </div>
        </div>
        <div className="pure-u-1 button-row-space" style={{textAlign:'center'}}>
            <button className="pure-button button-std" onClick={onClickPrintButton}>Print</button>
        </div>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalClinicBookingConfirmed);