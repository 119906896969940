import React from 'react';
import {connect} from "react-redux";
import AdminOverviewBookingsGraph from "./AdminOverviewBookingsGraph";
import {apiSendData} from "../../../libs/Api";
import AdminOverviewBlocks from "./AdminOverviewBlocks";
import PropTypes from "prop-types";
import AdminOverviewArrivalBlock from "./AdminOverviewArrivalBlock";
import AdminOverviewIssuesTable from "./AdminOverviewIssuesTable";

function AdminOverview(props) {
    const [loading, setLoading] = React.useState(false);
    const [overviewData, setOverviewData] = React.useState({});

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setLoading(true);
        apiSendData(
        '/api/admin/overview/',
            {},
            'get',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    console.log(response);
                    setOverviewData(response.data);
                }
            }
        });
    }, []);

    return(
        <>
            <div className={loading ? 'loading-overlay-element': undefined}>
                <AdminOverviewBlocks
                    data={overviewData}
                />
                <AdminOverviewBookingsGraph data={overviewData.booking_graph_data} />
                <AdminOverviewArrivalBlock data={overviewData} />
                <AdminOverviewIssuesTable data={overviewData} />
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminOverview);