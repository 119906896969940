import React from "react";
import {NavLink, Link} from 'react-router-dom'
import {connect} from "react-redux";
import {setLogout, showNotification} from "../../redux/actions";
import logo from "../../images/UofTLogo.png";
import history from "./history";
import * as Routes from '../../libs/PCAOSSRoutes';
import {apiSendData} from "../../libs/Api";


const TopHeader = (props) => {
    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const handleSignOut = (event) => {
        event.preventDefault();
        apiSendData('/api/users/logout/', '', 'post', true)
            .then(response => {
                if (mountedRef.current) {
                    if (response && !response.error) {
                        props.showNotification("You have successfully signed out.", 'success', true);
                        props.setLogout();
                        history.push(Routes.LOGIN);
                    }
                }
            })
    };

    return(
        <div className="pure-g header-wrapper">
            <div className="pure-u-md-1-4 pure-u-1-2">
                <Link to="/">
                    <img src={logo} alt="logo" className="logo" />
                </Link>
            </div>
            <div className="pure-u-md-3-4 pure-u-1-2" style={{textAlign: "right"}}>
            {
                props.authState.isLoggedIn ?
                    <div>
                        <p style={{marginBottom: 0, display:"inline-block"}}>{props.authState.userName}, {props.authState.healthcareCentre}</p>
                        <a href="#" onClick={handleSignOut} style={{marginLeft:"1em"}}>Sign Out</a>
                        {
                            props.authState.lastLoginDate ?
                                <p style={{fontSize: "0.8em"}}>
                                    <strong>Last Login:</strong> {props.authState.lastLoginDate} from {props.authState.lastLoginLocation}
                                </p>
                                : ""
                        }
                    </div>
                :
                    <Link to={Routes.LOGIN}>Sign In</Link>
            }
            </div>
        </div>
    );
}

const HeaderBar = (props) => {
    return(
        <div className="home-menu pure-menu pure-menu-horizontal">
            <Link to={Routes.LOGIN} className="pure-menu-heading" style={{marginLeft:0, paddingLeft:0}}>PCAOSS</Link>

            <ul className="pure-menu-list floaty">
                {
                    props.authState.isLoggedIn && !props.authState.twoFactorRequired && props.authState.signedAgreement ?
                        <>
                            {
                                props.authState.roles.includes(1) ?
                                    <>
                                        <li className="pure-menu-item">
                                            <NavLink
                                                to={Routes.CLINIC_APPOINTMENTS}
                                                className={
                                                    ({isActive}) =>
                                                        isActive ? "pure-menu-link pure-menu-selected" : "pure-menu-link"
                                                }
                                            >
                                                Already Booked Appointments
                                            </NavLink>
                                        </li>
                                        <li className="pure-menu-item">
                                            <NavLink
                                                to={Routes.CLINIC_SETUP_APPOINTMENTS}
                                                className={
                                                    ({isActive}) =>
                                                        isActive ? "pure-menu-link pure-menu-selected" : "pure-menu-link"
                                                }
                                            >
                                                Setup Appointments
                                            </NavLink>
                                        </li>
                                        <li className="pure-menu-item">
                                            <NavLink
                                                to={Routes.CLINIC_NOTIFICATIONS}
                                                className={
                                                    ({isActive}) =>
                                                        isActive ? "pure-menu-link pure-menu-selected" : "pure-menu-link"
                                                }
                                            >
                                                Manage Notifications
                                            </NavLink>
                                        </li>
                                    </>
                                    : ""
                            }
                            {
                                props.authState.roles.includes(2) ?
                                    <>
                                        <li className="pure-menu-item">
                                            <NavLink
                                                to={Routes.HOSPITAL_CLINIC_SEARCH}
                                                className={
                                                    ({isActive}) =>
                                                        isActive ? "pure-menu-link pure-menu-selected" : "pure-menu-link"
                                                }
                                            >
                                                Clinic Search by Physician
                                            </NavLink>
                                        </li>
                                        <li className="pure-menu-item">
                                            <NavLink
                                                to={Routes.HOSPITAL_RECENT_BOOKINGS}
                                                className={
                                                    ({isActive}) =>
                                                        isActive ? "pure-menu-link pure-menu-selected" : "pure-menu-link"
                                                }
                                            >
                                                Recent Bookings
                                            </NavLink>
                                        </li>
                                    </>
                                    : ""
                            }
                            <li className="pure-menu-item">
                                <NavLink
                                    to={Routes.ACCOUNT}
                                    className={
                                        ({isActive}) =>
                                            isActive ? "pure-menu-link pure-menu-selected" : "pure-menu-link"
                                    }
                                >
                                    Account
                                </NavLink>
                            </li>
                        </>
                        : ""
                }
            </ul>
        </div>
    );
}

const HeaderSubBar = (props) => {
    console.log(props.authState);
    return(
        <div className="home-menu pure-menu pure-menu-horizontal secondary-bar">
            {
                props.authState.roles.includes(3) ?
                    <ul className="pure-menu-list submenu">
                        <li className="pure-menu-item">
                            <NavLink
                                to={Routes.ADMIN_OVERVIEW}
                                className={
                                    ({isActive}) =>
                                        isActive ? "pure-menu-link secondary-link pure-menu-selected" : "pure-menu-link secondary-link"
                                }
                                style={{paddingLeft:0}}
                            >
                                Overview
                            </NavLink>
                        </li>
                        <li className="pure-menu-item">
                            <NavLink
                                to={Routes.ADMIN_BOOKING}
                                className={
                                    ({isActive}) =>
                                        isActive ? "pure-menu-link secondary-link pure-menu-selected" : "pure-menu-link secondary-link"
                                }
                            >
                                Bookings
                            </NavLink>
                        </li>
                        <li className="pure-menu-item">
                            <NavLink
                                to={Routes.ADMIN_HEALTHCARE_CENTRE}
                                className={
                                    ({isActive}) =>
                                        isActive ? "pure-menu-link secondary-link pure-menu-selected" : "pure-menu-link secondary-link"
                                }
                            >
                                Healthcare Centres
                            </NavLink>
                        </li>
                        <li className="pure-menu-item">
                            <NavLink
                                to={Routes.ADMIN_USER}
                                className={
                                    ({isActive}) =>
                                        isActive ? "pure-menu-link secondary-link pure-menu-selected" : "pure-menu-link secondary-link"
                                }
                            >
                                User Accounts
                            </NavLink>
                        </li>
                        {/*<li className="pure-menu-item">
                            <NavLink
                                to={Routes.ADMIN_EMAIL_TEMPLATES}
                                className={
                                    ({isActive}) =>
                                        isActive ? "pure-menu-link secondary-link pure-menu-selected" : "pure-menu-link secondary-link"
                                }
                            >
                                Email Templates
                            </NavLink>
                        </li>*/}
                        {/*<li className="pure-menu-item">
                            <NavLink
                                to={Routes.ADMIN_LOGS}
                                className={
                                    ({isActive}) =>
                                        isActive ? "pure-menu-link secondary-link pure-menu-selected" : "pure-menu-link secondary-link"
                                }
                            >
                                System Logs
                            </NavLink>
                        </li>*/}
                    </ul>
                    : <p style={{margin: 0, padding: 0, marginLeft: "1.4%", color: "#6e6e6e", height: "1px"}}>&nbsp;</p>
            }
        </div>
    );
}

function Header(props) {

    return(
        <>
            <TopHeader {...props} />
            <HeaderBar {...props} />
            <HeaderSubBar {...props} />
        </>
    );
}

const mapStateToProps = state => {
    return {
        authState: state.authState,
    };
};

const mapDispatchToProps =  {
    setLogout,
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
