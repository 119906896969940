import React from 'react';
import {connect} from "react-redux";
import {FaSearch} from "react-icons/fa";
import PropTypes from "prop-types";

function HospitalSearchClinicsSearch(props) {

    return(
        <div className="pure-g" style={{marginBottom:'2em', marginTop:'2em'}}>
            <div className="pure-u-1">
                <div className="pure-form icon-input-container">
                    <div className="input-icon field"><FaSearch /></div>
                    <input
                        className="icon-input-input"
                        style={{borderTopRightRadius: 0, borderBottomRightRadius:0, minWidth: '300px'}}
                        type="text"
                        placeholder={props.searchSelectValue === 1 ? "Search Physician Name" : "Search Office Name"}
                        value={props.searchValue}
                        onChange={(event) => (props.setSearchValue(event.target.value))}
                    />
                    <select
                        onChange={(event) => (props.setSearchSelectValue(parseInt(event.target.value)))}
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft:0}}
                    >
                        <option value={1}>Search by Physician</option>
                        <option value={2}>Search by Office Name</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

HospitalSearchClinicsSearch.propTypes = {
    searchValue: PropTypes.string,
    setSearchValue: PropTypes.func,
    setSearchSelectValue: PropTypes.func,
    searchSelectValue: PropTypes.number,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalSearchClinicsSearch);