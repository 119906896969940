import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {connect} from "react-redux";
import ClinicSetupReservedAppointmentsModal from "./ClinicSetupReservedAppointmentsModal";
import ModifyButton from "../../Common/ModifyButtons";
import {apiSendData} from "../../../libs/Api";
import {showNotification} from "../../../redux/actions";
import dayjs from "dayjs";
import ClinicSetupReservedAppointmentBlackoutModal from "./ClinicSetupReservedAppointmentBlackoutModal";
import {FaPhone, FaWalking} from 'react-icons/fa';


const ReservedAppointmentRow = (props) => {
    const dayAsString = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const repeatChoices = ["", "Never", "Weekly", "Every 2 Weeks", "Every 3 Weeks", "Every 4 Weeks",];

    //Notes: Determine repeat days of the week
    const startDOTW = dayjs(props.appointment.start_date).day();

    //Extract Repeated Days from Dictionary to Array
    const repeatDays = props.appointment.appointment_repeat_days.map(day => day.id);

    //Check if repeat days includes start dotw. Otherwise add it.
    if (!repeatDays.includes(startDOTW)) {
        repeatDays.push(startDOTW);
    }

    const repeatDaysString = repeatDays
        .sort()
        .map(day => dayAsString[day])
        .join(', ')
    ;

    return(
        <tr>
            <td style={{whiteSpace: 'nowrap'}}>{props.appointment.start_date}</td>
            <td style={{whiteSpace: 'nowrap'}}>{props.appointment.start_time}</td>
            <td>{repeatChoices[props.appointment.repeat]}</td>
            <td>{repeatDaysString}</td>
            <td style={{whiteSpace: 'nowrap'}}>{props.appointment.clinic_physician ? props.appointment.physician_name: '-'}</td>
            <td>{props.appointment.type == 1 ? <FaWalking /> : <FaPhone /> }</td>
            <td>
                {props.appointment.repeat !== 1
                ? `This appointment will be available ${repeatChoices[props.appointment.repeat].toLowerCase()} on ${repeatDaysString} until cancelled`
                : "-"}
            </td>
            <td style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                <ModifyButton type='blackout-date' additionalClass="icon-space-right" onClickHandler={() => {props.editBlackoutDate(props.appointment)}} />
                <ModifyButton type='edit' additionalClass="icon-space-right" onClickHandler={() => {props.editAppointment(props.appointment)}} />
                <ModifyButton type='delete' onClickHandler={() => {props.removeAppointment(props.appointment.id)}} />
            </td>
        </tr>
    );
};

ReservedAppointmentRow.propTypes = {
    appointment: PropTypes.object,
    removeAppointment: PropTypes.func,
    editAppointment: PropTypes.func,
    editBlackoutDate: PropTypes.func,
}

const PhysicianSelect = (props) => {
    const onChangeSelect = event => {
        props.setSelectedPhysician(event.target.value);
        props.setFilterPhysician(event.target.value);
    };

    return(
        <div className="pure-form">
            <select value={props.selectedPhysician} onChange={onChangeSelect}>
                <option value=''>Show All</option>
                <option value='General'>General Clinic Appointments</option>
                {
                    props.physicians.length ?
                        props.physicians.map(physician => (<option key={physician.id} value={physician.id}>{physician.physician_name}</option>))
                        : null
                }
            </select>
        </div>
    );
};

PhysicianSelect.propTypes = {
    physicians: PropTypes.array,
    selectedPhysician: PropTypes.string,
    setSelectedPhysician: PropTypes.func,
    setFilterPhysician: PropTypes.func,
}

function ClinicSetupReservedAppointments(props) {
    let { centre_id } = useParams();
    const [selectedPhysician, setSelectedPhysician] = React.useState('');
    const [appointmentModalOpen, setAppointmentModalOpen] = React.useState(false);
    const [blackoutAppointmentModalOpen, setBlackoutAppointmentModalOpen] = React.useState(false);
    const [blackoutAppointmentDates, setBlackoutAppointmentDates] = React.useState(undefined);
    const [initialValues, setInitialValues] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false);

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const applyFilterSelectPhysician = (appointment) => {
        return (selectedPhysician === '')
            || (selectedPhysician === 'General' && appointment.clinic_physician == null)
            || (selectedPhysician == appointment.clinic_physician);
    };

    const addAppointment = (values) => {
        //Set loading overlay
        setLoading(true);
        const timeConcat = `${values.start_time_hour}:${values.start_time_min} ${values.start_time_am_pm}`;
        apiSendData(
        '/api/clinic/appointments/',
            {
                ...values,
                start_date: dayjs(values.start_date).format("YYYY-MM-DD"),
                start_time: timeConcat,
                centre_id: centre_id,
                end_date: (values.end_date && values.end_date !== null ) ? dayjs(values.end_date).format("YYYY-MM-DD"): '',
            },
            'post',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    props.showNotification(`The appointment was successfully added.`, 'success');
                    //props.setAppointments([...props.appointments, response.data.appointment]);
                    props.setAppointments(response.data.appointments);
                }
            }
        });
    }

    const removeAppointment = (id) => {
        setLoading(true);
        apiSendData('/api/clinic/appointments/',
            {appointment_id: id, centre_id: centre_id},
            'delete')
            .then(response => {
                if (mountedRef.current) {
                    setLoading(false);
                    if (response && !response.error) {
                        props.showNotification(`The appointment was successfully removed.`, 'success');
                        props.setAppointments(response.data.appointments);
                    }
                }
            });
    };

    const updateBlackoutDates = (id, blackoutAppointmentDates, deleteList) => {
        setLoading(true);
        // Format the dates
        const formattedBlackoutDates = blackoutAppointmentDates.blackout_dates.map(entry => ({...entry, start_date: dayjs(entry.start_date).format("YYYY-MM-DD"), end_date: dayjs(entry.end_date).format("YYYY-MM-DD")}));
        apiSendData('/api/clinic/appointments/blackoutdate/',
            {appointment_id: id, blackout_dates: formattedBlackoutDates, deleteList: deleteList, centre_id: centre_id},
            'post')
            .then(response => {
                if (mountedRef.current) {
                    setLoading(false);
                    if (response && !response.error) {
                        props.showNotification(`Blackout dates for this appointment were successfully updated.`, 'success');
                        props.setAppointments(response.data.appointments);
                    }
                }
            });
    }

    const editBlackoutDatesForAppointment = (values) => {
        setBlackoutAppointmentDates(values);
        setBlackoutAppointmentModalOpen(true);
    }

    const onClickEditAppointment = (values) => {
        setInitialValues(values);
        setAppointmentModalOpen(true);
    };

    const onClickCreateNewAppointment = () => {
        setInitialValues(undefined);
        setAppointmentModalOpen(true);
    }

    const updateAppointment = (values) => {
        //Set loading overlay
        setLoading(true);
        const timeConcat = `${values.start_time_hour}:${values.start_time_min} ${values.start_time_am_pm}`;

        apiSendData(
        '/api/clinic/appointments/',
            {
                ...values,
                start_date: dayjs(values.start_date).format("YYYY-MM-DD"),
                start_time: timeConcat,
                centre_id: centre_id,
                end_date: ( values.end_date && values.end_date !== null ) ? dayjs(values.end_date).format("YYYY-MM-DD"): '',
            },
            'patch',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    props.showNotification(`The appointment was successfully updated.`, 'success');
                    //props.setAppointments([...props.appointments, response.data.appointment]);
                    props.setAppointments(response.data.appointments);
                }
            }
        });
    };

    const onModalClose = () => {
        setInitialValues(undefined);
    };

    return(
        <>
            <h3 className="header-spacing">Reserved Appointments</h3>
            <p>Add reserved appointments for your clinic. Hospital staff will use these times to book followup appointments.</p>
            <div className="pure-u-1-2">
                <PhysicianSelect
                    physicians={props.physicians ? props.physicians : []}
                    selectedPhysician={selectedPhysician}
                    setSelectedPhysician={setSelectedPhysician}
                    setFilterPhysician={props.setFilterPhysician}
                />
            </div>
            <div className={loading ? 'loading-overlay-element': undefined}>
                <table className="pure-table pure-table-horizontal pure-table-striped table-hover row-space" style={{width: '100%'}}>
                    <thead>
                        <tr>
                            <th style={{whiteSpace: 'nowrap'}}>Start Date</th>
                            <th style={{whiteSpace: 'nowrap'}}>Start Time</th>
                            <th style={{whiteSpace: 'nowrap'}}>Recurring</th>
                            <th style={{whiteSpace: 'nowrap'}}>Day of the Week</th>
                            <th style={{whiteSpace: 'nowrap'}}>Physician</th>
                            <th style={{whiteSpace: 'nowrap'}}>Type</th>
                            <th style={{whiteSpace: 'nowrap'}}>Notes</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.appointments.filter(applyFilterSelectPhysician).length ?
                                props.appointments.filter(applyFilterSelectPhysician).map(appointment => (
                                    <ReservedAppointmentRow
                                        key={`appt-${appointment.id}`}
                                        appointment={appointment}
                                        removeAppointment={removeAppointment}
                                        editAppointment={onClickEditAppointment}
                                        editBlackoutDate={editBlackoutDatesForAppointment}
                                    />
                                ))
                            : <tr><td colSpan={7}>No Reserved Appointments</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="pure-u-1">
                <button className="pure-button button-std button-row-space" onClick={onClickCreateNewAppointment}>Create New Reserved Appointment</button>
            </div>
            <ClinicSetupReservedAppointmentsModal
                appointmentModalOpen={appointmentModalOpen}
                setAppointmentModalOpen={setAppointmentModalOpen}
                physicians={props.physicians}
                addAppointment={addAppointment}
                updateAppointment={updateAppointment}
                initialValues={initialValues}
                onModalClose={onModalClose}
            />
            <ClinicSetupReservedAppointmentBlackoutModal
                blackoutAppointmentModalOpen={blackoutAppointmentModalOpen}
                setBlackoutAppointmentModalOpen={setBlackoutAppointmentModalOpen}
                appointment={blackoutAppointmentDates}
                setAppointment={setBlackoutAppointmentDates}
                updateBlackoutDates={updateBlackoutDates}
            />
        </>
    );
}
ClinicSetupReservedAppointments.propTypes = {
    physicians: PropTypes.array,
    appointments: PropTypes.array,
    blackoutDates: PropTypes.array,
    setAppointments: PropTypes.func,
    setFilterPhysician: PropTypes.func,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSetupReservedAppointments);