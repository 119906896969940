import React from 'react';
import {connect} from "react-redux";
import {Form, Formik} from "formik";
import {apiSendData} from "../../../libs/Api";
import {showNotification} from "../../../redux/actions";
import * as Yup from "yup";
import {TextField, SelectField, CheckboxField} from "../../Common/CustomInputs";
import PropTypes from 'prop-types';
import history from "../../Common/history";
import * as Routes from "../../../libs/PCAOSSRoutes";
import CSRFTokenForm from "../../Common/CSRFTokenForm";


function AdminHealthcareCentreEditForm(props) {
    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <Formik
                initialValues={props.initialValues}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    props.setLoading(true);
                    apiSendData(
                    `/api/admin/healthcare-centre/${props.centre_id}/`,
                        {...values},
                        props.centre_id === "new" ? 'post': 'patch',
                    ).then(response => {
                        if (mountedRef.current) {
                            props.setLoading(false);
                            if (response && response.error && response.code === "input_error" && response.input_errors) {
                                setErrors(response.input_errors);
                            }
                            if (response && !response.error) {
                                props.showNotification(
                                    props.centre_id === "new" ?
                                        `${values['name']} was successfully created!`
                                        : `${values['name']} was successfully updated!`
                                    , "success", true
                                );
                                history.push(Routes.ADMIN_HEALTHCARE_CENTRE);
                            }
                        }
                    });
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .min(2, "Healthcare centre name is too short.")
                        .max(150, "Healthcare centre name is too long.")
                        .required("Healthcare centre name is required."),
                    // email: Yup.string().email()
                    //     .required("Email is required."),
                    phone_number: Yup.string()
                        .min(10, "Phone number is too short.")
                        .max(25, "Phone number is too long.")
                        .required("Phone number is required."),
                    extension: Yup.string()
                        .max(10, "Extension is too long."),
                    address_line_1: Yup.string()
                        .min(5, "Address line 1 is too short.")
                        .max(200, "Address line 1 is too long.")
                        .required("Address line 1 is required."),
                    address_line_2: Yup.string()
                        .max(200, "Address line 2 is too long."),
                    city: Yup.string()
                        .min(5, "City is too short.")
                        .max(100, "City is too long.")
                        .required("City is  required."),
                    province: Yup.string()
                        .required("Province is  required."),
                    postal_code: Yup.string()
                        .min(6, "Postal code is too short.")
                        .max(7, "Postal code is too long.")
                        .required("Postal code is  required."),
                    booking_url: Yup.string()
                        .max(300, "Booking url is too long."),
                    appointment_expiry: Yup.number()
                        .min(0, "Appointment expiry is reqired.")
                        .required("Appointment expiry is reqired.")
                })}
            >
                <Form className="pure-form pure-form-stacked" id="from-admin-centre-edit">
                    <CSRFTokenForm />
                    <div className="pure-u-1 row-space">
                        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4">
                            <SelectField
                                label="Centre Type"
                                id="centre_type"
                                name="centre_type"
                                required="required"
                                options={[{value:"2", label: "Clinic"}, {value:"1", label: "Hospital"}]}
                                helpText="Select a healthcare centre type."
                            />
                        </div>
                    </div>
                    <div className="pure-u-1 row-space">
                        <div className="pure-u-1 pure-u-md-18-24 pure-u-lg-16-24">
                            <TextField
                                label="Healthcare Centre Name"
                                id="name"
                                name="name"
                                required="required"
                                placeholder="Enter Healthcare Centre Name"
                            />
                        </div>
                    </div>
                    <div className="pure-u-1 pure-u-md-18-24 pure-u-lg-10-24 row-space">
                        <div className="grid-space">
                            <TextField
                                label="Email"
                                id="email"
                                name="email"
                                type="email"
                                // required="required"
                                placeholder="Email"
                            />
                        </div>
                    </div>
                    <div className="pure-u-1 pure-u-md-8-24 pure-u-lg-4-24 row-space">
                        <div className="grid-space">
                            <TextField
                                label="Phone Number"
                                id="phone_number"
                                name="phone_number"
                                type="text"
                                required="required"
                                placeholder="Phone Number"
                            />
                        </div>
                    </div>
                    <div className="pure-u-1 pure-u-md-4-24 pure-u-lg-2-24 row-space">
                        <TextField
                            label="Extension"
                            id="extension"
                            name="extension"
                            type="text"
                            placeholder="Extension"
                        />
                    </div>
                    <div className="pure-u-1 pure-u-lg-2-3 row-space">
                        <TextField
                            label="Address Line 1"
                            id="address_line_1"
                            name="address_line_1"
                            type="text"
                            required="required"
                            placeholder="Address Line 1"
                        />
                    </div>
                    <div className="pure-u-1">
                        <div className="pure-u-1 pure-u-lg-2-3 row-space">
                            <TextField
                                label="Address Line 2 (optional)"
                                id="address_line_2"
                                name="address_line_2"
                                type="text"
                                placeholder="Address Line 2"
                            />
                        </div>
                    </div>
                    <div className="pure-u-1 pure-u-md-8-24 pure-u-lg-6-24 row-space">
                        <div className="grid-space">
                            <TextField
                                label="City"
                                id="city"
                                name="city"
                                type="text"
                                required="required"
                                placeholder="City"
                            />
                        </div>
                    </div>
                    <div className="pure-u-1 pure-u-md-8-24 pure-u-lg-5-24 row-space">
                        <div className="grid-space">
                            <SelectField
                                label="Province"
                                id="province"
                                name="province"
                                required="required"
                                options={[{value:"Ontario", label: "Ontario"}]}
                            />
                        </div>
                    </div>
                    <div className="pure-u-1 pure-u-md-8-24 pure-u-lg-5-24 row-space">
                        <TextField
                            label="Postal Code"
                            id="postal_code"
                            name="postal_code"
                            type="text"
                            required="required"
                            placeholder="Postal Code"
                        />
                    </div>
                    <div className="pure-u-1 row-space">
                        <TextField
                            label="Booking URL (optional)"
                            id="booking_url"
                            name="booking_url"
                            type="text"
                            placeholder="Booking URL"
                            helpText="Enter the url for the clinics booking system (if applicable). This will skip the internal booking system when new appointments are scheduled. Urls must begin with http(s)://"
                        />
                    </div>
                    <div className="pure-u-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-5 row-space">
                        <TextField
                            label="Appointment Expiry"
                            id="appointment_expiry"
                            name="appointment_expiry"
                            type="number"
                            placeholder="24 hrs"
                            helpText="Appointments for this healthcare centre will expire this number of hours before if an appointment is not taken (default 24)"
                        />
                    </div>

                    <div className="pure-u-1">
                        <button type="submit" className="pure-button button-std button-row-space">
                            {
                                props.centre_id === "new" ?
                                    "Create Healthcare Centre"
                                    : props.initialValues.active ?
                                        "Update Healthcare Centre"
                                        : "Enable and Update Healthcare Centre"
                            }
                        </button>
                        {
                            props.centre_id !== "new" ?
                                props.initialValues.active ?
                                    <button type="button" className="pure-button button-error button-row-space" style={{marginLeft:'3rem'}} onClick={props.handleDeleteRequest}>Disable Healthcare Centre</button>
                                    : ""
                                : ""
                        }
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHealthcareCentreEditForm);

AdminHealthcareCentreEditForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
    centre_id: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    setInitialValues: PropTypes.func.isRequired,
}