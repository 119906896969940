import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import monitorReducersEnhancer from './enhancers/monitorReducers';
import loggerMiddleware from './middleware/logger';
import rootReducer from "./reducers";


function ConfigureStore(preloadedState) {
    // const middlewares = [loggerMiddleware, thunk];
    // const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));
    //
    // const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
    // const composedEnhancers = compose(...enhancers);

    // NOT DEV
    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = compose(...enhancers);

    // Check for persistent state
    const persistedState = {};/*localStorage.getItem('authState') ?
        {authState: JSON.parse(localStorage.getItem('authState'))}
        : {};*/

    const store = createStore(rootReducer, persistedState, composedEnhancers);

    return store
}

const store = ConfigureStore();

// Subscribe and save auth state
/*const unsubscribe = store.subscribe(() => {
    localStorage.setItem('authState', JSON.stringify(store.getState().authState));
})*/

export default store;