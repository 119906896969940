import React from 'react';
import { useParams } from 'react-router-dom';
import {connect} from "react-redux";
import ClinicSetupPhysicians from "./ClinicSetupPhysicians";
import ClinicSetupReservedAppointments from "./ClinicSetupReservedAppointements";
import {apiSendData} from "../../../libs/Api";
import AppointmentCalendar from "../../Common/Calendar/AppointmentCalendar";
import dayjs from "dayjs";

function ClinicSetupAppointments(props) {
    let { centre_id } = useParams();

    const [physicians, setPhysicians] = React.useState([]);
    const [appointments, setAppointments] = React.useState([]);
    const [calendar, setCalendar] = React.useState([]);
    const [calendarDate, setCalendarDate] = React.useState(dayjs());
    const [calendarLoading, setCalendarLoading] = React.useState(false);
    const [filterPhysician, setFilterPhysician] = React.useState("");

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    //Load state
    React.useEffect(() => {
        apiSendData('/api/clinic/appointments/', {centre_id: centre_id}, 'get')
            .then(response => {
                if (mountedRef.current) {
                    if (response && !response.error) {
                        setAppointments(response.data.appointments);
                    }
                }
            })
        apiSendData('/api/clinic/physicians/', {centre_id: centre_id}, 'get')
            .then(response => {
                if (mountedRef.current) {
                    if (response && !response.error) {
                        setPhysicians(response.data.physicians);
                    }
                }
            })
        // eslint-disable-next-line
    }, []);

    // Fetch calendar effect
    React.useEffect(() => {
        //Set loading overlay
        setCalendarLoading(true);

        apiSendData(
        '/api/clinic/calendar/',
            {
                date: calendarDate.format('YYYY-MM-DD'),
                centre_id: centre_id
            },
            'get',
        ).then(response => {
            if (mountedRef.current) {
                setCalendarLoading(false);
                if (response && !response.error) {
                    setCalendar(response.data.calendar);
                }
            }
        });
    }, [calendarDate, appointments]);

    // Filter Calendar events for specific physician
    const applyFilterSelectPhysician = (week) => {
        week.map(day => {
            if (!day.events || day.events.length === 0) {
                return day;
            }

            day.events = day.events.filter(event => {
                return (filterPhysician === '')
                || (filterPhysician === 'General' && event.physician_id == null)
                || (filterPhysician == event.physician_id);
            })

            return day;
        })

        return week;
    };

    return(
        <div className="pure-g">
            <div className="pure-u-1">
                <h3>Setup Clinic Appointments</h3>
                <p>You can create reserved appointments for your clinic. ER physicians will use these reserved appointments to book followup appointments when a patient from your clinic is discharged from the ER.</p>
                <p>Please keep these appointment times available until <strong>24 hours</strong> before the scheduled start time.</p>
                <div className="pure-u-1">
                    <ClinicSetupPhysicians physicians={physicians} setPhysicians={setPhysicians} setAppointments={setAppointments} />
                </div>
                <div className="pure-u-1">
                    <ClinicSetupReservedAppointments
                        physicians={physicians}
                        appointments={appointments}
                        setAppointments={setAppointments}
                        setFilterPhysician={setFilterPhysician}
                    />
                </div>
                <div className="pure-u-1 row-space-large">
                    <AppointmentCalendar
                        calendar={calendar.length > 0 ? JSON.parse(JSON.stringify(calendar)).map(applyFilterSelectPhysician) : calendar}
                        calendarDate={calendarDate}
                        setCalendarDate={setCalendarDate}
                        loading={calendarLoading}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSetupAppointments);