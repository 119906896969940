import React from 'react';
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";
import {formatDate} from "../../../libs/CommonFunctions";
import {apiSendData} from "../../../libs/Api";
import {Form, Formik} from "formik";
import {DatePickerField, TextField, CheckboxField, TextArea} from "../../Common/CustomInputs";
import * as Yup from 'yup';
import PropTypes from "prop-types";
import {showNotification} from "../../../redux/actions";
import * as Routes from '../../../libs/PCAOSSRoutes';
import history from "../../Common/history";
import CSRFTokenForm from "../../Common/CSRFTokenForm";
import HospitalClinicBookingForm from "./HospitalClinicBookingForm";


function HospitalClinicBooking(props) {
    const {appointment_id, clinic_id, physician_id, appointment_date} = useParams();
    const [loading, setLoading] = React.useState(false);
    const [clinicInfo, setClinicInfo] = React.useState({});
    const [isExternal, setIsExternal] = React.useState(false);

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    // Scroll to top of page on initial load
    React.useEffect(() => {
        window.scrollTo(0,0);
        if (clinic_id) 
            setIsExternal(true);
    },[, clinic_id]);

    // Fetch appointment info
    React.useEffect(() => {
        apiSendData(
        '/api/hospital/clinic/appointment/',
            {
                appointment_id: appointment_id,
                clinic_id: clinic_id,
            },
            'get',
        ).then(response => {
            if (mountedRef.current) {
                if (response && !response.error) {
                    setClinicInfo(response.data.clinic_info);
                }
            }
        });
        // eslint-disable-next-line
    }, [])


    return(
        <div className={`pure-g ${props.loading ? 'loading-overlay-element': ''}`}>
            <div className="pure-u-1">
                <h3>Appointment Booking</h3>
                {
                    isExternal ?
                    <p>Appointment for <strong>{clinicInfo.clinic_name ? clinicInfo.clinic_name : "" }</strong> - Please fill out all sections, then click submit</p>
                    : <p>Appointment for <strong>{formatDate(appointment_date)} {clinicInfo.appointment_time ? clinicInfo.appointment_time : ""} at {clinicInfo.clinic_name ? clinicInfo.clinic_name : "" }</strong> - Please fill out all sections, then click submit</p>
                }
                {isExternal ? <p style={{color:'red',fontWeight:'bold'}}>This clinic is using their own booking system. Once the following form is completed a new tab will open allowing you to create the <u>actual</u> appointment at the clinic.</p>: ''}
                <HospitalClinicBookingForm
                    loading={loading}
                    setLoading={setLoading}
                    appointment_id={appointment_id}
                    physician_id={physician_id}
                    appointment_date={appointment_date}
                    showNotification={props.showNotification}
                    clinic_id={clinic_id}
                    clinic_info={clinicInfo}
                    is_external={isExternal}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalClinicBooking);