import React from 'react';
import * as Routes from '../../../libs/PCAOSSRoutes';
import {connect} from "react-redux";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from "prop-types";


function AdminOverviewArrivalBlock(props) {
    return(
        <>
            <h4 className="big-space" >Patient Arrived at Appointment</h4>
            <div className="overview-arrival-block">
                <div className="arrival-block yes" style={{width: props.data.stats ? `${props.data.stats.patient_arrived_yes_percent}%`: "0%"}}>
                    {props.data.stats ? props.data.stats.patient_arrived_yes_percent : "-"}
                    % Arrived
                </div>
                <div className="arrival-block no" style={{width: props.data.stats ? `${props.data.stats.patient_arrived_no_percent}%`: "0%"}}>
                    {props.data.stats ? props.data.stats.patient_arrived_no_percent : "-"}
                    % Not Arrived
                </div>
                <div className="arrival-block unknown" style={{width: props.data.stats ? `${props.data.stats.patient_arrived_unknown_percent}%`: "0%"}}>
                    {props.data.stats ? props.data.stats.patient_arrived_unknown_percent : "-"}
                    % Unknown
                </div>
            </div>
            <div className="small-space pure-u-1">
                <div className="pure-u-1-4">
                    <div className="overview-arrival-legend yes">&nbsp;</div>
                    <p className="overview-arrival-legend-text"><strong>{props.data.stats ? props.data.stats.patient_arrived_yes : "-"}</strong> Patients Arrived</p>
                </div>
                <div className="pure-u-1-4">
                    <div className="overview-arrival-legend no" style={{marginLeft: "2em"}}>&nbsp;</div>
                    <p className="overview-arrival-legend-text"><strong>{props.data.stats ? props.data.stats.patient_arrived_no : "-"}</strong> Patients No Arrival</p>
                </div>
                <div className="pure-u-1-4">
                    <div className="overview-arrival-legend unknown" style={{marginLeft: "2em"}}>&nbsp;</div>
                    <p className="overview-arrival-legend-text"><strong>{props.data.stats ? props.data.stats.patient_arrived_unknown : "-"}</strong> Patients Unknown</p>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

AdminOverviewArrivalBlock.propTypes = {
    data: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOverviewArrivalBlock);