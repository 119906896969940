export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'
export const SHOW_BUSY = 'SHOW_BUSY'
export const HIDE_BUSY = 'HIDE_BUSY'

/*
    Show message notification.
    Variants: success, info, warning, error
    Next: true if message should be displayed on new url route
 */
export const showNotification = (message, variant, next=false) => {
    return {
        type: SHOW_NOTIFICATION,
        message,
        variant,
        next
    }
}

export const removeNotification = () => {
    return {
        type: REMOVE_NOTIFICATION
    }
}

export const resetNotification = () => {
    return {
        type: RESET_NOTIFICATION
    }
}

export const thunkShowNotification = () => {
    return (dispatch, getState) => {
        dispatch(showNotification("Thunk message pass along", "success"));
    }
};

export const showBusy = (text = "Processing") => {
    return {
        type: SHOW_BUSY,
        text
    }
};

export const hideBusy = () => {
    return {
        type: HIDE_BUSY,
    }
};

export const countdownHideBusy = () => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(hideBusy());
        }, 1000);
    }
}