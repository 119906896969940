import React from 'react';
import {connect} from "react-redux";
import AdminLogsTable from "./AdminLogsTable";
import {Link} from "react-router-dom";
import * as Routes from "../../../libs/PCAOSSRoutes";
import history from "../../Common/history";
import SearchAndFilterBar from "../../Common/SearchAndFilterBar";

function AdminLogs(props) {
    const [loading, setLoading] = React.useState(false);
    const [logs, setLogs] = React.useState([]);
    const [filter, setFilter] = React.useState({searchTerm: '', options: []});
    const statusOptions = ["USER", "PHI", "SYS"];

    // Load logs
    React.useEffect(() => {
        setLogs(
            [
                {'date': '06/10/2021', 'time': '13:36:59', 'type': 'USER', 'status': 'Info', 'event': 'hospital_user (2) logged in', 'user': 'hospital_user (2)'},
                {'date': '06/10/2021', 'time': '13:37:22', 'type': 'PHI', 'status': 'Info', 'event': 'Created booking (4)', 'user': 'hospital_user (2)'},
                {'date': '06/10/2021', 'time': '13:38:57', 'type': 'PHI', 'status': 'Info', 'event': 'Edit booking (4): changed status to cancelled', 'user': 'hospital_user (2)'},
                {'date': '06/10/2021', 'time': '13:39:11', 'type': 'USER', 'status': 'Info', 'event': 'hospital_user (2) logged out', 'user': 'hospital_user (2)'},
                {'date': '06/10/2021', 'time': '13:39:50', 'type': 'USER', 'status': 'Info', 'event': 'clinic_user (3) logged in', 'user': 'clinic_user (3)'},
                {'date': '06/10/2021', 'time': '13:41:01', 'type': 'PHI', 'status': 'Info', 'event': 'Viewed booking (4)', 'user': 'clinic_user (3)'},
                {'date': '06/10/2021', 'time': '13:42:13', 'type': 'USER', 'status': 'Warning', 'event': 'Attempted to access restricted URL: https://pcaoss.webarc.ca/api/admin/users/', 'user': 'clinic_user (3)'},
                {'date': '06/10/2021', 'time': '14:05:06', 'type': 'SYS', 'status': 'Error', 'event': 'Failed to send cancellation email to clinic for booking (4)', 'user': 'N/A'},
                ].reverse()
        )
    }, []);

    return(
        <>
            <h3>System Logs</h3>
            <p>All user and system activities are logged on this page.</p>
            <SearchAndFilterBar
                filterOptions={statusOptions}
                filter={filter}
                setFilter={setFilter}
                data={logs}
                searchPlaceholder="Search Username"
            />
            <AdminLogsTable
                logs={logs}
                loading={loading}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogs);