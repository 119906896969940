import React from 'react';
import {connect} from "react-redux";
import {useParams} from 'react-router-dom';
import AppointmentCalendar from "../../Common/Calendar/AppointmentCalendar";
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import dayjs from "dayjs";
import {apiSendData} from "../../../libs/Api";
import history from "../../Common/history";
import * as Routes from '../../../libs/PCAOSSRoutes';
import {formatDate} from "../../../libs/CommonFunctions";
import UrgentGIMNoticeModal from './ClinicSpecificModals/UrgentGIMNoticeModal';

const FirstThreeAppointments = (props) => {
    const [firstThreeAppointments, setFirstThreeAppointments] = React.useState([]);

    // Find the first 3 available appointments
    React.useEffect(() => {
        const newFirstThreeAppointments = [];

        props.calendar.forEach(
            week => week.forEach(
                day => day.events ?
                    day.events.forEach(
                        event => {
                            if (newFirstThreeAppointments.length < 3
                                && !event.past_event
                                && event.type === "appointment"
                                && (
                                    !props.checkPhysicianOnly
                                    || (props.checkPhysicianOnly && event.physician_id === props.physician_id)
                                )
                            ) {
                                newFirstThreeAppointments.push(event);
                            }
                        }
                    )
                    : undefined))
        setFirstThreeAppointments(newFirstThreeAppointments);
    }, [props.calendar, props.checkPhysicianOnly, props.physician_id]);


    const sequencesString = ["1st", "2nd", "3rd"];

    return (
        firstThreeAppointments.length > 0 ?
            <div className="pure-u-1">
                <p>Quick select appointments:</p>
                <ul>
                    {
                        firstThreeAppointments.map((appt, index) => (
                            <li key={index}>
                                {`${sequencesString[index]} Available Appointment: `}
                                <Link
                                    to={`${Routes.HOSPITAL_CLINIC_BOOKING}/${appt.id}/${props.physician_id}/${appt.event_date}`}
                                >
                                    {`${formatDate(appt.event_date)} at ${appt.start_time}`}
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
            : <></>
    );
}

FirstThreeAppointments.propTypes = {
    calendar: PropTypes.array,
    checkPhysicianOnly: PropTypes.bool,
    physician_id: PropTypes.number,
}

function HospitalClinicSchedule(props) {
    const {clinic_id, physician_id} = useParams();
    const [calendarLoading, setCalendarLoading] = React.useState(false);
    const [calendar, setCalendar] = React.useState([]);
    const [physicianName, setPhysicianName] = React.useState("")
    const [clinicName, setClinicName] = React.useState("")
    const [calendarDate, setCalendarDate] = React.useState(dayjs());
    const [checkPhysicianOnly, setCheckPhysicianOnly] = React.useState(false);
    
    // Clinic specific modals
    const [showUrgentGIMModal, setShowUrgentGIMModal] = React.useState(false);

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const eventsExist = calendar.length > 0
         && calendar.filter(week => week.some(day => day.events ? day.events.length > 0: false)).length > 0;

    const physicianAppointmentsExist = calendar.length > 0
        && calendar.filter(week => week.some(day => day.events ? day.events.some(event => event.physician_id ===  parseInt(physician_id)) : false)).length > 0;

    //Load calendar info
    React.useEffect(() => {
        //Set loading overlay
        setCalendarLoading(true);

        apiSendData(
        '/api/hospital/clinic/calendar/',
            {
                date: calendarDate.format('YYYY-MM-DD'),
                clinic_id: clinic_id,
                physician_id: physician_id,
            },
            'get',
        ).then(response => {
            if (mountedRef.current) {
                setCalendarLoading(false);
                if (response && !response.error) {
                    setPhysicianName(response.data.physician_name);
                    setClinicName(response.data.clinic_name);
                    setCalendar(response.data.calendar);
                }
            }
        });

        // eslint-disable-next-line
    }, [calendarDate]);

    const onClickEvent = (event) => {
        if (event.past_event === false && event.type === "appointment") {
            history.push(`${Routes.HOSPITAL_CLINIC_BOOKING}/${event.id}/${physician_id}/${event.event_date}`);
        }
    }
    
    React.useEffect(() => {
        if (clinic_id == 22 || clinic_id == 23) { // 2 is for testing
            setShowUrgentGIMModal(true);
        }
    }, [clinic_id]);

    return(
        <div className="pure-g">
            <div className="pure-u-1">
                <UrgentGIMNoticeModal onClickCancel={() => {history.replace('/hospital/clinic')}} onClickConfirm={() => {setShowUrgentGIMModal(false)}} setModalOpen={setShowUrgentGIMModal} modalOpen={showUrgentGIMModal} />
                <h3>Appointments Calendar</h3>
                <p><strong>{physicianName}, {clinicName}</strong></p>
                {/*<p>REMINDER: APPOINTMENTS FOR THIS SPECIFIC PHYSICIAN DO NOT EXIST. THE PATIENT WILL BE SEEN BY A PHYSICIAN WHO WORKS AT THIS CLINIC, WHO MAY OR MAY NOT BE THIS SPECIFIC PHYSICIAN</p>*/}
                {/* <p className={"pure-form-message"}><b>Reminder:</b> Appointments for this specific physician do not exist. The patient will be seen by a physician who works at this clinic, who may or may not be this specific physician.</p> */}
                <p>Select an available appointment time from the calendar.</p>
                {
                    (calendar.length > 0 && !eventsExist) ?
                        <p style={{color: 'red', fontWeight: 'bold'}}>NO APPOINTMENTS EXIST FOR THIS CLINIC</p> : ""
                }
                {/* <label className={!physicianAppointmentsExist ? "disabled-text" : undefined}>
                    <input type="checkbox" disabled={!physicianAppointmentsExist} checked={checkPhysicianOnly} onChange={() => setCheckPhysicianOnly(!checkPhysicianOnly)}/> Only Display Appointments for this Physician
                </label> */}
                <label>
                    <input type="checkbox" checked={checkPhysicianOnly} onChange={() => setCheckPhysicianOnly(!checkPhysicianOnly)}/> Only Display Appointments for this Physician
                </label>
                <small className={`pure-form-message`}>Note: Some clinics only have general appointments available. Selecting this option will hide those appointments.</small>
                <FirstThreeAppointments
                    calendar={calendar}
                    checkPhysicianOnly={checkPhysicianOnly}
                    physician_id={parseInt(physician_id)}
                />
                <div className="pure-u-1 row-space-large">
                    <AppointmentCalendar
                        loading={calendarLoading}
                        calendar={calendar}//{calendar.length > 0 ? JSON.parse(JSON.stringify(calendar)).map(applyFilterSelectPhysician) : calendar}
                        calendarDate={calendarDate}
                        setCalendarDate={setCalendarDate}
                        onClickEvent={onClickEvent}
                        filterPhysicianId={checkPhysicianOnly ?  parseInt(physician_id) : undefined}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalClinicSchedule);