import React from 'react';
import PropTypes from 'prop-types';
import {FaTimes, FaCheck, FaRegEdit, FaEye, FaCalendarTimes} from "react-icons/fa";
import ReactTooltip from 'react-tooltip';

function ModifyButton(props) {
    const typeDef = {
        'save': {
            symbol:
                <FaCheck
                    className={`check-icon ${props.additionalClass}`}
                    onClick={props.onClickHandler}
                    data-tip=''
                    data-for="saveButtonTooltip"
                />,
            tooltip: "Save",
            dataFor: "saveButtonTooltip",
        },
        'activate': {
            symbol:
                <FaCheck
                    className={`check-icon ${props.additionalClass}`}
                    onClick={props.onClickHandler}
                    data-tip=''
                    data-for="checkButtonTooltip"
                />,
            tooltip: "Activate",
            dataFor: "checkButtonTooltip",
        },
        'delete': {
            symbol:
                <FaTimes
                    className={`delete-icon ${props.additionalClass}`}
                    onClick={props.onClickHandler}
                    data-tip=''
                    data-for="deleteButtonTooltip"
                />,
            tooltip: "Delete",
            dataFor: "deleteButtonTooltip",
        },
        'cancel': {
            symbol:
                <FaTimes
                    className={`cancel-icon ${props.additionalClass}`}
                    onClick={props.onClickHandler}
                    data-tip=''
                    data-for="cancelButtonTooltip"
                />,
            tooltip: "Cancel",
            dataFor: "cancelButtonTooltip",
        },
        'edit': {
            symbol:
                <FaRegEdit
                    className={`edit-icon ${props.additionalClass}`}
                    onClick={props.onClickHandler}
                    data-tip=''
                    data-for="editButtonTooltip"
                />,
            tooltip: "Edit",
            dataFor: "editButtonTooltip",
        },
        'view': {
            symbol:
                <FaEye
                    className={`view-icon ${props.additionalClass}`}
                    onClick={props.onClickHandler}
                    data-tip=''
                    data-for="viewButtonTooltip"
                />,
            tooltip: "View",
            dataFor: "viewButtonTooltip",
        },
        'blackout-date': {
            symbol:
                <FaCalendarTimes
                    className={`blackout-icon ${props.additionalClass}`}
                    onClick={props.onClickHandler}
                    data-tip=''
                    data-for="blackoutDateButtonTooltip"
                />,
            tooltip: "Add Blackout Dates",
            dataFor: "blackoutDateButtonTooltip",
        }
    };

    return(
        <>
            {typeDef[props.type].symbol}
            <ReactTooltip id={typeDef[props.type].dataFor} place="top" effect="solid">{typeDef[props.type].tooltip}</ReactTooltip>
        </>
    );
}

ModifyButton.propTypes = {
    type: PropTypes.string,
    onClickHandler: PropTypes.func,
    additionalClass: PropTypes.string,
}

export default ModifyButton;