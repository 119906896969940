import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ModifyButton from "../../Common/ModifyButtons";
import history from "../../Common/history";
import * as Routes from '../../../libs/PCAOSSRoutes';

function Row(props) {

    return(
        <tr>
            <td>{props.user.id}</td>
            <td><div className={`status ${props.user.status}`}>{props.user.status}</div></td>
            <td>{props.user.username}</td>
            <td>{props.user.first_name}</td>
            <td>{props.user.last_name}</td>
            <td>{props.user.email}</td>
            <td>{props.user.healthcare_centre}</td>
            <td style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                {
                    props.user.status === "Pending" ?
                        <>
                            <ModifyButton type='activate' onClickHandler={() => {props.onClickHandleActivate(props.user.id)}} />
                            &nbsp;
                            <ModifyButton type='delete' onClickHandler={() => {props.onClickHandleDelete(props.user.id)}} />
                            &nbsp;
                        </>
                        :""
                }
                <ModifyButton type='edit' onClickHandler={() => {history.push(`${Routes.ADMIN_USER}/${props.user.id}`)}} />
            </td>
        </tr>
    );
}

Row.propTypes = {
    user: PropTypes.object.isRequired,
    statusOptions: PropTypes.array.isRequired,
    onClickHandleActivate: PropTypes.func.isRequired,
    onClickHandleDelete: PropTypes.func.isRequired
}

function AdminUsersTable(props) {

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <table className="pure-table pure-table-horizontal pure-table-striped table-hover row-space" style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th style={{whiteSpace: 'nowrap'}}>ID</th>
                        <th style={{whiteSpace: 'nowrap'}}>Status</th>
                        <th style={{whiteSpace: 'nowrap'}}>Username</th>
                        <th style={{whiteSpace: 'nowrap'}}>First Name</th>
                        <th style={{whiteSpace: 'nowrap'}}>Last Name</th>
                        <th style={{whiteSpace: 'nowrap'}}>Email</th>
                        <th style={{whiteSpace: 'nowrap'}}>Healthcare Centre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.users.length > 0 ?
                            props.users.map((user, index) => (
                                <Row
                                    key={index}
                                    user={user}
                                    statusOptions={props.statusOptions}
                                    onClickHandleActivate={props.onClickHandleActivate}
                                    onClickHandleDelete={props.onClickHandleDelete}
                                />
                            ))
                            : <tr><td colSpan={7}>No Results</td></tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

AdminUsersTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
    statusOptions: PropTypes.array.isRequired,
    onClickHandleActivate: PropTypes.func.isRequired,
    onClickHandleDelete: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersTable);