import React from 'react';
import BodyWrapper from "./components/Common/BodyWrapper";
import {Route, Routes} from 'react-router-dom';
import {resetNotification} from "./redux/actions";
import * as PCAOSSRoutes from './libs/PCAOSSRoutes';

import PageNotFound from "./components/PageNotFound";
import Lander from "./components/Common/Lander";
//Auth Pages
import LoginPage from "./components/Auth/LoginPage";
import UserCreateAccount from "./components/Common/CreateAccount/UserCreateAccount";
import ForgotPassword from "./components/Auth/ForgotPassword";
import TwoFactorAuth from "./components/Auth/TwoFactorAuth";
import AcceptAgreement from "./components/Auth/AcceptAgreement";

//Shared Pages
import UserAccount from "./components/Common/UserAccount/UserAccount";

//Clinic Pages
import ClinicAppointments from "./components/Clinic/ClinicAppointments";
import ClinicSetupAppointments from "./components/Clinic/SetupAppointments/ClinicSetupAppointments";

//Hospital Pages
import HospitalClinicSchedule from "./components/Hospital/ClinicSchedule/HospitalClinicSchedule";
import HospitalClinicBooking from "./components/Hospital/Booking/HospitalClinicBooking";
import HospitalClinicBookingConfirmed from "./components/Hospital/Booking/HospitalClinicBookingConfirmed";
import HospitalSearchClinics from "./components/Hospital/HospitalSearchClinics";
import HospitalRecentBookings from "./components/Hospital/RecentBookings/HospitalRecentBookings";
import HospitalRecentBookingsView from "./components/Hospital/RecentBookings/HospitalRecentBookingsView";
import HospitalRecentBookingsEdit from "./components/Hospital/RecentBookings/HospitalRecentBookingsEdit";

//Admin Pages
import AdminOverview from "./components/Admin/AdminOverview/AdminOverview";
import AdminBookings from "./components/Admin/AdminBookings/AdminBookings";
import AdminHealthcareCentre from "./components/Admin/AdminHealthcareCentre/AdminHealthcareCentre";
import AdminHealthcareCentreEdit from "./components/Admin/AdminHealthcareCentre/AdminHealthcareCentreEdit";
import AdminUsers from "./components/Admin/AdminUsers/AdminUsers";
import AdminUserEdit from "./components/Admin/AdminUsers/AdminUserEdit";
import AdminEmailTemplates from "./components/Admin/EmailTemplates/AdminEmailTemplates";
import AdminLogs from "./components/Admin/AdminLogs/AdminLogs";


import {connect} from 'react-redux';
import RequireNotAuth from "./components/Common/RequireNotAuth";
import RequireAuth from "./components/Common/RequireAuth";
import ClinicNotifications from './components/Clinic/ClinicNotifications/ClinicNotifications';
import SetForgotPassword from './components/Auth/SetForgotPassword';

function App(props) {
    const ANY_ROLE = 0;
    const CLINIC_ROLE = 1;
    const HOSPITAL_ROLE = 2;
    const ADMIN_ROLE = 3;

    return (
        <>
            <BodyWrapper>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={
                            <LoginPage />
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.LOGIN}
                        element={
                            <LoginPage />
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.NEW_ACCOUNT_ACTIVATION}/:token`}
                        element={
                            <RequireNotAuth>
                                <SetForgotPassword />
                            </RequireNotAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.FORGOT_PASSWORD}
                        element={
                            <RequireNotAuth>
                                <ForgotPassword />
                            </RequireNotAuth>
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.SET_FORGOT_PASSWORD}/:token`}
                        element={
                            <RequireNotAuth>
                                <SetForgotPassword />
                            </RequireNotAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.TWO_FACTOR}
                        element={
                            <TwoFactorAuth />
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.CREATE_ACCOUNT}
                        element={
                            <RequireNotAuth>
                                <UserCreateAccount />
                            </RequireNotAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.ACCEPT_AGREEMENT}
                        element={
                            <AcceptAgreement />
                        }
                    />
                    {/*Clinic Pages*/}
                    <Route
                        exact
                        path={PCAOSSRoutes.CLINIC_APPOINTMENTS}
                        element={
                            <RequireAuth role={CLINIC_ROLE}>
                                <ClinicAppointments />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.CLINIC_SETUP_APPOINTMENTS}
                        element={
                            <RequireAuth role={CLINIC_ROLE}>
                                <ClinicSetupAppointments />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.CLINIC_NOTIFICATIONS}
                        element={
                            <RequireAuth role={CLINIC_ROLE}>
                                <ClinicNotifications />
                            </RequireAuth>
                        }
                    />
                    {/*Hospital Pages*/}
                    <Route
                        exact
                        path={PCAOSSRoutes.HOSPITAL_CLINIC_SEARCH}
                        element={
                            <RequireAuth role={HOSPITAL_ROLE}>
                                <HospitalSearchClinics />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.HOSPITAL_CLINIC_SCHEDULE}/:clinic_id/:physician_id`}
                        element={
                            <RequireAuth role={HOSPITAL_ROLE}>
                                <HospitalClinicSchedule />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.HOSPITAL_CLINIC_BOOKING}/:appointment_id/:physician_id/:appointment_date`}
                        element={
                            <RequireAuth role={HOSPITAL_ROLE}>
                                <HospitalClinicBooking />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.HOSPITAL_CLINIC_BOOKING}/external/:clinic_id/:physician_id`}
                        element={
                            <RequireAuth role={HOSPITAL_ROLE}>
                                <HospitalClinicBooking />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.HOSPITAL_CLINIC_BOOKING_CONFIRMATION}/:booking_id`}
                        element={
                            <RequireAuth role={HOSPITAL_ROLE}>
                                <HospitalClinicBookingConfirmed />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.HOSPITAL_RECENT_BOOKINGS}
                        element={
                            <RequireAuth role={HOSPITAL_ROLE}>
                                <HospitalRecentBookings />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.HOSPITAL_RECENT_BOOKINGS}/:booking_id`}
                        element={
                            <RequireAuth role={HOSPITAL_ROLE}>
                                <HospitalRecentBookingsView />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.HOSPITAL_RECENT_BOOKINGS}/:booking_id/edit`}
                        element={
                            <RequireAuth role={HOSPITAL_ROLE}>
                                <HospitalRecentBookingsEdit />
                            </RequireAuth>
                        }
                    />
                    {/*Shared Pages*/}
                    <Route
                        exact
                        path={PCAOSSRoutes.ACCOUNT}
                        element={
                            <RequireAuth>
                                <UserAccount />
                            </RequireAuth>
                        }
                    />
                    Admin
                    <Route
                        exact
                        path={PCAOSSRoutes.ADMIN_OVERVIEW}
                        element={
                            <RequireAuth role={ADMIN_ROLE}>
                                <AdminOverview />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.ADMIN_BOOKING}
                        element={
                            <RequireAuth role={ADMIN_ROLE}>
                                <AdminBookings />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.ADMIN_HEALTHCARE_CENTRE}
                        element={
                            <RequireAuth role={ADMIN_ROLE}>
                                <AdminHealthcareCentre />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.ADMIN_HEALTHCARE_CENTRE}/:centre_id`}
                        element={
                            <RequireAuth role={ADMIN_ROLE}>
                                <AdminHealthcareCentreEdit />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.ADMIN_USER}
                        element={
                            <RequireAuth role={ADMIN_ROLE}>
                                <AdminUsers />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={`${PCAOSSRoutes.ADMIN_USER}/:user_id`}
                        element={
                            <RequireAuth role={ADMIN_ROLE}>
                                <AdminUserEdit />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.ADMIN_EMAIL_TEMPLATES}
                        element={
                            <RequireAuth role={ADMIN_ROLE}>
                                <AdminEmailTemplates />
                            </RequireAuth>
                        }
                    />
                    <Route
                        exact
                        path={PCAOSSRoutes.ADMIN_LOGS}
                        element={
                            <RequireAuth role={ADMIN_ROLE}>
                                <AdminLogs />
                            </RequireAuth>
                        }
                    />
                    <Route component={PageNotFound} />
                </Routes>
            </BodyWrapper>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    resetNotification,

};

export default connect(mapStateToProps, mapDispatchToProps)(App);
