import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ModifyButton from "../../Common/ModifyButtons";
import history from "../../Common/history";
import * as Routes from '../../../libs/PCAOSSRoutes';

function Row(props) {

    return(
        <tr>
            <td>{props.log.date}</td>
            <td>{props.log.time}</td>
            <td>{props.log.type}</td>
            <td>{props.log.status}</td>
            <td>{props.log.event}</td>
            <td>{props.log.user}</td>
            <td>
                <ModifyButton type='edit' onClickHandler={() => console.log("doing nothing.")} />
            </td>
        </tr>
    );
}

Row.propTypes = {
    log: PropTypes.object.isRequired,
}

function AdminLogsTable(props) {

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <table className="pure-table pure-table-horizontal pure-table-striped table-hover row-space" style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th style={{whiteSpace: 'nowrap'}}>Date</th>
                        <th style={{whiteSpace: 'nowrap'}}>Time</th>
                        <th style={{whiteSpace: 'nowrap'}}>Type</th>
                        <th style={{whiteSpace: 'nowrap'}}>Status</th>
                        <th style={{whiteSpace: 'nowrap'}}>Event</th>
                        <th style={{whiteSpace: 'nowrap'}}>User</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.logs.length > 0 ?
                            props.logs.map((log, index) => (
                                <Row
                                    key={index}
                                    log={log}
                                />
                            ))
                            : <tr><td colSpan={7}>No Results</td></tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

AdminLogsTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    logs: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogsTable);