import React from "react";
import {showNotification, setLogin} from "../../redux/actions";
import {connect} from "react-redux";
import {Formik, Form} from "formik";
import {TextField} from '../Common/CustomInputs'
import * as Routes from '../../libs/PCAOSSRoutes';
import {apiSendData} from "../../libs/Api";
import CSRFTokenForm from "../Common/CSRFTokenForm";
import history from "../Common/history";
import PasswordRequirements from "../Common/UserAccount/PasswordRequirements";
import { useParams } from "react-router-dom";

const SetForgotPasswordForm = (props) => {
    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    let {token} = useParams();

    return (
        <Formik
            initialValues={{password: '', confirmPassword: ''}}
            validateOnChange
            validate={values => {
                const errors = {}
                if (!values.password) {
                    errors.password = "Required"
                }

                if (!values.confirmPassword) {
                    errors.confirmPassword = "Required"
                }
            }}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                console.log('Token value: ', token);
                apiSendData(
                    '/api/users/login/forgot-password/',
                    {...values, token: token},
                    'put',
                    true,
                ).then(response => {
                    if (mountedRef.current) {
                        //setSubmitting(false);
                        if (response && !response.error) {
                            props.showNotification("Your password has been reset. You can now login.", 'success', true);
                            history.push(Routes.LOGIN);
                        } else {
                            setErrors(response.input_errors);
                        }
                    }
                });
            }}
        >
            <Form className="pure-form pure-form-stacked" id="form-forgot-password">
                <CSRFTokenForm/>
                <div className="pure-u-1 pure-u-md-1 row-space">
                    <TextField
                        label="New Password"
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="off"
                        required="required"
                        autoFocus={true}
                        placeholder="Enter New Password"
                    />
                </div>
                <div className="pure-u-1 pure-u-md-1 row-space">
                    <TextField
                        label="Confirm New Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        autoComplete="off"
                        type="password"
                        required="required"
                        placeholder="Enter Confirm New Password"
                    />
                </div>
                <div className="pure-u-1 pure-u-md-1 row-space">
                    <PasswordRequirements />
                </div>
                <div className="pure-u-1">
                    <button type="submit" className="pure-button button-std button-row-space">Save New Password</button>
                </div>
            </Form>
        </Formik>
    )
}

function SetForgotPassword(props) {
    return (
        <div className="pure-g">
            <div className="pure-u-1">
                <div className="pure-u-1">
                    <h3>Set Your New Password</h3>
                </div>
                <SetForgotPasswordForm {...props} />
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetForgotPassword);