import React from 'react';
import {connect} from "react-redux";
import {apiSendData} from "../../../libs/Api";
import HospitalRecentBookingsTable from "./HospitalRecentBookingsTable";
import {Link} from "react-router-dom";
import * as Routes from "../../../libs/PCAOSSRoutes";
import history from "../../Common/history";

function HospitalRecentBookings(props) {
    const [loading, setLoading] = React.useState(false);
    const [bookings, setBookings] = React.useState([]);
    const [filter, setFilter] = React.useState({searchTerm: '', options: []});
    const statusOptions = ["Pending", "Active", "Disabled"];

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setLoading(true);
        apiSendData(
        '/api/hospital/bookings/',
            {},
            'get',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    setBookings(response.data.bookings)
                }
            }
        });
    }, []);

    const filterBookings = (booking) => {
        return (!filter.options.length || filter.options.includes(booking.status))
            && (filter.searchTerm === '' || booking.email.toLowerCase().includes(filter.searchTerm.toLowerCase()));
    };

    return(
        <>
            <h3>Recent Bookings</h3>
            <p>Manage patient bookings that have been made in the last 14 days at your hospital and are taking place at a future date and time.</p>
            <div className="pure-u-1">
                <HospitalRecentBookingsTable
                    loading={loading}
                    bookings={bookings}
                />
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalRecentBookings);