import store from "../../redux/ConfigureStore";
import { Navigate, useLocation } from "react-router-dom";

const RequireAuth = ({children, role}) => {
    const authState = store.getState().authState;
    let location = useLocation();

    if (authState.isLoggedIn && (role === undefined || authState.roles.includes(role))) {
        return children
    }
    else {
        return <Navigate to="/login" state={{ from: location }} replace />
    }
}

export default RequireAuth;