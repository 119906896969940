import React from 'react';
import {connect} from "react-redux";
import ModifyButton from "../../Common/ModifyButtons";
import history from "../../Common/history";
import * as Routes from "../../../libs/PCAOSSRoutes";
import PropTypes from "prop-types";
import dayjs from 'dayjs';


function Row(props) {

    return(
        <tr>
            <td>{props.booking.id}</td>
            <td><div className={`status ${props.booking.cancelled ? "Cancelled": "Pending"}`}>{props.booking.cancelled ? "Cancelled" : "Booked"}</div></td>
            <td>{props.booking.patient_name}</td>
            <td>{props.booking.patient_phone}</td>
            <td>{props.booking.clinic}</td>
            <td>{props.booking.booking_start_date} - {props.booking.booking_start_time}</td>
            <td>{dayjs(props.booking.discharge_date).format('YYYY-MM-DD')}</td>
            <td>{props.booking.hospital}</td>
            <td>{props.booking.booking_user}</td>
            {/*<td style={{textAlign: 'right', whiteSpace: 'nowrap'}}>*/}
            {/*    <span className="icon-space-right"><ModifyButton type='view' onClickHandler={() => {history.push(`${Routes.HOSPITAL_RECENT_BOOKINGS}/${props.booking.id}`)}} /></span>*/}
            {/*    <ModifyButton type='edit' onClickHandler={() => {history.push(`${Routes.HOSPITAL_RECENT_BOOKINGS}/${props.booking.id}/edit`)}} />*/}
            {/*</td>*/}
        </tr>
    );
}

Row.propTypes = {
    booking: PropTypes.object.isRequired,
}

function AdminBookingsTable(props) {

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <table className="pure-table pure-table-horizontal pure-table-striped table-hover row-space" style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th style={{whiteSpace: 'nowrap'}}>ID</th>
                        <th style={{whiteSpace: 'nowrap'}}>Status</th>
                        <th style={{whiteSpace: 'nowrap'}}>Patient Name</th>
                        <th style={{whiteSpace: 'nowrap'}}>Patient Phone</th>
                        <th style={{whiteSpace: 'nowrap'}}>Clinic</th>
                        <th style={{whiteSpace: 'nowrap'}}>Appointment Date</th>
                        <th style={{whiteSpace: 'nowrap'}}>ER Visit Date</th>
                        <th style={{whiteSpace: 'nowrap'}}>Booking Hospital</th>
                        <th style={{whiteSpace: 'nowrap'}}>Booked By</th>
                        {/*<th></th>*/}
                    </tr>
                </thead>
                <tbody>
                    {
                        props.bookings.length > 0 ?
                            props.bookings.map((booking, index) => (
                                <Row
                                    key={index}
                                    booking={booking}
                                />
                            ))
                            : <tr><td colSpan={8}>No Bookings</td></tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

AdminBookingsTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    bookings: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBookingsTable);