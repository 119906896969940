import React from 'react';
import {connect} from "react-redux";
import {useParams} from 'react-router-dom';
import AdminHealthcareCentreEditForm from "./AdminHealthcareCentreEditForm";
import {apiSendData} from "../../../libs/Api";
import history from "../../Common/history";
import * as Routes from "../../../libs/PCAOSSRoutes";
import {showNotification} from "../../../redux/actions";
import ClinicSetupAppointments from "../../Clinic/SetupAppointments/ClinicSetupAppointments"

function AdminHealthcareCentreEdit(props) {
    let { centre_id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const buildFormState = (state) => {
        return {
            'active': state && state.active !== null ? state.active : true,
            'centre_type': state && state.centre_type !== null ? state.centre_type : '2',
            'name': state && state.name ? state.name : '',
            'email': state && state.email ? state.email : '',
            'phone_number': state && state.phone_number ? state.phone_number : '',
            'extension': state && state.extension ? state.extension : '',
            'address_line_1': state && state.address_line_1 ? state.address_line_1 : '',
            'address_line_2': state && state.address_line_2 ? state.address_line_2 : '',
            'city': state && state.city ? state.city : 'Toronto',
            'province': state && state.province ? state.province : 'Ontario',
            'postal_code': state && state.postal_code ? state.postal_code : '',
            'booking_url': state && state.booking_url ? state.booking_url : '',
            'appointment_expiry': state && state.appointment_expiry ? state.appointment_expiry : 24,
        }
    }

    const [initialValues, setInitialValues] = React.useState(buildFormState());

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const handleDeleteRequest = () => {
        setLoading(true);
            apiSendData(
                `/api/admin/healthcare-centre/${centre_id}/`,
                {},
                'delete',
            ).then(response => {
                if (mountedRef.current) {
                    setLoading(false);
                    if (response && !response.error) {
                        props.showNotification(response.detail, "success", true);
                        history.push(Routes.ADMIN_HEALTHCARE_CENTRE);
                    }
                }
            });
    }

    // Fetch Centre Details
    React.useEffect(() => {
        if (centre_id !== "new") {
            setLoading(true);
            apiSendData(
                `/api/admin/healthcare-centre/${centre_id}/`,
                {},
                'get',
            ).then(response => {
                if (mountedRef.current) {
                    setLoading(false);
                    if (response && !response.error) {
                        setInitialValues(buildFormState(response.data.initial_values));
                    }
                }
            });
        }
    }, []);

    return(
        <>
            <h3>{centre_id === "new" ? "Create New Healthcare Centre" : `Editing Healthcare Centre: ${initialValues.name}`}</h3>
            <AdminHealthcareCentreEditForm
                loading={loading}
                setLoading={setLoading}
                centre_id={centre_id}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                handleDeleteRequest={handleDeleteRequest}
            />
            {
                centre_id !== "new" &&
                <div>
                    <hr style={{marginTop:'30px', marginBottom:'20px'}}/>
                    <h3 style={{marginTop:'30px', marginBottom:'20px'}}>Edit Clinic Physicians/Appointments</h3>
                    <hr style={{marginTop:'30px', marginBottom:'20px'}}/>
                    <ClinicSetupAppointments />
                </div>
            }
            <h3></h3>
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHealthcareCentreEdit);