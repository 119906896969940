import React from 'react';
import {connect} from "react-redux";
import UserCreateAccountForm from "./UserCreateAccountForm";

function UserAccount(props) {
    const [createAccountLoading, setCreateAccountLoading] = React.useState(false);
    const [healthcareCentres, setHealthcareCentres] = React.useState([]);

    return(
        <>
            <div className="pure-u-1">
                <h3>Account Setup</h3>
                <p>New accounts will <strong>not be active</strong> until reviewed by a system administrator. You will be notified through email when your account has been activated.</p>
                <p>If your healthcare centre is not listed please contact <a href="mailto:clare.atzema@ices.on.ca">Clare Atzema (clare.atzema@ices.on.ca)</a></p>
            </div>
            <UserCreateAccountForm
                loading={createAccountLoading}
                setLoading={setCreateAccountLoading}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);