import React from 'react';
import {useField, useFormikContext} from "formik";
import DatePicker from 'react-datepicker';
import dayjs from "dayjs";
import Select from "react-select";

const validationStyle = (meta) => {
    if (meta.touched && meta.error) {
        return "is-invalid"
    }
    return "";
}

export const TextField = ({label, ...props}) => {
    const [field, meta] = useField(props);

    const textProps = {...props}
    delete textProps['helpText']

    return(
        <>
            <label htmlFor={props.id || props.name}>{label ? label+":" : ''}</label>
            <input className={`pure-u-1 ${validationStyle(meta)} same-row-input-spacing`} {...field} {...textProps} />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
            {props.helpText ?
                <small className="pure-form-message">{props.helpText}</small>
            : null}
        </>
    );
}

export const TextArea = ({label, ...props}) => {
    const [field, meta] = useField(props);

    const textProps = {...props}
    delete textProps['helpText']

    return(
        <>
            <label htmlFor={props.id || props.name}>{label ? label+":" : ''}</label>
            <textarea className={`pure-u-1 ${validationStyle(meta)}`} {...field} {...textProps} />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
            {props.helpText ?
                <small className="pure-form-message">{props.helpText}</small>
            : null}
        </>
    );
}

export const CheckboxField = ({children, ...props}) => {
    const [field, meta] = useField({...props, type: 'checkbox'});

    const checkboxProps = {...props}
    delete checkboxProps['helpText']

    return(
        <div>
            <label htmlFor={props.id || props.name}>
                <input type="checkbox" className={`${validationStyle(meta)}`} {...field} {...checkboxProps} style={{marginRight: '0.5em'}} />
                {children}
            </label>
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
            {props.helpText ?
                <small className="pure-form-message">{props.helpText}</small>
            : null}
        </div>
    );
}

export const SelectField = ({label, options, ...props}) => {
    const [field, meta] = useField(props);

    const selectProps = {...props}
    delete selectProps['helpText']

    return(
        <div>
            <label htmlFor={props.id || props.name}>{label ? label+":" : ''}</label>
            <select className={`pure-u-1 ${validationStyle(meta)}`} {...field} {...selectProps}>
                {
                    options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))
                }
            </select>
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
            {props.helpText ?
                <small className="pure-form-message">{props.helpText}</small>
            : null}
        </div>
    );
}

export const SearchSelectField = ({label, options, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const {setValue} = helpers;

    const selectProps = {...props}
    delete selectProps['helpText']

    return(
        <div>
            <label htmlFor={props.id || props.name}>{label ? label+":" : ''}</label>
            <Select
                className={`pure-u-1 ${validationStyle(meta)}`}
                options={options}
                name={field.name}
                value={options ? options.find(option => option.value === field.value) : ''}
                onChange={(option) => setValue(option.value)}
                //filterOption={(label, value, data) => console.log(label)} //Label = object {label: "", value: ""}, Value is current text
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
            {props.helpText ?
                <small className="pure-form-message">{props.helpText}</small>
            : null}
        </div>
    );
}

export const MultiSearchSelectField = ({label, options, ...props}) => {
    const [field, meta, helpers] = useField(props);
    const {setValue} = helpers;

    const selectProps = {...props}
    delete selectProps['helpText']

    return(
        <div>
            <label htmlFor={props.id || props.name}>{label ? label+":" : ''}</label>
            <Select
                className={`pure-u-1 ${validationStyle(meta)}`}
                options={options}
                name={field.name}
                onChange={(option) => setValue(option.value)}
                isMulti
                //filterOption={(label, value, data) => console.log(label)} //Label = object {label: "", value: ""}, Value is current text
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
            {props.helpText ?
                <small className="pure-form-message">{props.helpText}</small>
            : null}
        </div>
    );
}

export const DatePickerField = ({label, ...props}) => {
    const [field, meta] = useField(props);
    const {setFieldValue} = useFormikContext();

    return(
        <div>
            <label htmlFor={props.id || props.name}>{label ? label+":" : ''}</label>
            <DatePicker
                wrapperClassName={props.className}
                {...field}
                {...props}
                //startDate={props.startDate ? new dayjs(props.startDate).toDate() : null}
                className={`pure-u-1 ${validationStyle(meta)}`}
                selected={(field.value && new dayjs(field.value).toDate()) || null}
                onChange={(value) => {
                    setFieldValue(field.name, value);
                }}
                dateFormat="yyyy-MM-dd"
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
            {props.helpText ?
                <small className="pure-form-message">{props.helpText}</small>
            : null}
        </div>
    );

}