import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import * as Routes from '../../libs/PCAOSSRoutes';
import {formatPhoneNumber} from "../../libs/CommonFunctions";

function Row(props) {

    const formatPostalCode = (postalCode) => {
        postalCode = postalCode.replace(' ','');
        return postalCode;
    }

    const openExternalLink = (url) => {
        if (props.clinic.booking_url !== null && props.clinic.booking_url !== "") {
            let win = window.open(props.clinic.booking_url, '_blank');
            win.focus();
        }
    }

    return(
        <tr>
            <td>{props.clinic.physician_name}</td>
            <td>{props.clinic.clinic_name}</td>
            <td>
                {props.clinic.address_line_1},
                {props.clinic.address_line_2 ? `<br/>${props.clinic.address_line_1}<br/>` : ""}
                {` ${props.clinic.city}, ${props.clinic.province}, ${formatPostalCode(props.clinic.postal_code)}`}
            </td>
            <td>{formatPhoneNumber(props.clinic.phone_number)} {props.clinic.extension ? "ext. "+props.clinic.extension : ""}</td>
            <td>
                {
                (props.clinic.booking_url !== null && props.clinic.booking_url !== "") ? 
                    <Link to={`${Routes.HOSPITAL_CLINIC_BOOKING}/external/${props.clinic.clinic_id}/${props.clinic.physician_id}`}> Schedule </Link>
                :   <Link to={`${Routes.HOSPITAL_CLINIC_SCHEDULE}/${props.clinic.clinic_id}/${props.clinic.physician_id}`}> Schedule </Link>
                }
            </td>
        </tr>
    );
}

Row.propTypes = {
    clinic: PropTypes.object.isRequired,
}

function HospitalSearchClinicsTable(props) {

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <table className="pure-table pure-table-horizontal pure-table-striped table-hover row-space" style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th style={{whiteSpace: 'nowrap'}}>Physician Name</th>
                        <th style={{whiteSpace: 'nowrap'}}>Office Name</th>
                        <th style={{whiteSpace: 'nowrap'}}>Office Address</th>
                        <th style={{whiteSpace: 'nowrap'}}>Phone Number</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.clinics.length > 0 ?
                            props.clinics.map((clinic, index) => (
                                <Row key={index} clinic={clinic} />
                            ))
                            : <tr><td colSpan={5}>No Results</td></tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

HospitalSearchClinicsTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    clinics: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalSearchClinicsTable);