import React from 'react';
import {connect} from "react-redux";
import dayjs from "dayjs";
import {Form, Formik} from "formik";
import {apiSendData} from "../../../libs/Api";
import {showNotification} from "../../../redux/actions";
import history from "../history";
import * as Routes from "../../../libs/PCAOSSRoutes";
import * as Yup from "yup";
import {TextField} from "../CustomInputs";
import PropTypes from 'prop-types';
import CSRFTokenForm from "../CSRFTokenForm";

function UserAccountForm(props) {
    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <Formik
                initialValues={{
                    'first_name': props.accountDetails.first_name || '',
                    'last_name': props.accountDetails.last_name || '',
                    'email': props.accountDetails.email || '',
                }}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    props.setLoading(true);
                    apiSendData(
                    '/api/users/account/',
                        {values},
                        'patch',
                    ).then(response => {
                        if (mountedRef.current) {
                            props.setLoading(false);
                            if (response && response.error && response.code === "input_error" && response.input_errors) {
                                setErrors(response.input_errors);
                            }
                            if (response && !response.error) {
                                props.showNotification("Your account details have been updated.", "success");
                            }
                        }
                    });
                }}
                validationSchema={Yup.object().shape({
                    first_name: Yup.string()
                        .min(2, "First name is too short.")
                        .max(30, "First name is too long.")
                        .required("First name is required."),
                    last_name: Yup.string()
                        .min(2, "Last name is too short.")
                        .max(30, "Last name is too long.")
                        .required("Last name is required."),
                    email: Yup.string().email()
                        .required("Email is required."),
                })}
            >
                <Form autoComplete="off" className="pure-form pure-form-stacked" id="form-user-acct">
                    <CSRFTokenForm />
                    <div className="pure-u-1">
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                            <div className="grid-space">
                                <TextField
                                    label="First Name"
                                    id="first_name"
                                    name="first_name"
                                    required="required"
                                    placeholder="Enter First Name"
                                />
                            </div>
                        </div>
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                                <TextField
                                    label="Last Name"
                                    id="last_name"
                                    name="last_name"
                                    required="required"
                                    placeholder="Enter Last Name"
                                />
                            </div>
                    </div>
                    <div className="pure-u-1 row-space">
                        <TextField
                            label="Email Address"
                            id="email"
                            name="email"
                            required="required"
                            placeholder="Enter Email Address"
                            helpText="Please do not use your personal email."
                        />
                    </div>
                    <div className="pure-u-1">
                        <button type="submit" className="pure-button button-std button-row-space">Update Account Details</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccountForm);

UserAccountForm.propTypes = {
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    accountDetails: PropTypes.object,
}