import React from 'react';
import {connect} from "react-redux";
import dayjs from "dayjs";
import {Form, Formik} from "formik";
import {apiSendData} from "../../../libs/Api";
import {showNotification} from "../../../redux/actions";
import * as Yup from "yup";
import {TextField, SearchSelectField, CheckboxField} from "../../Common/CustomInputs";
import PropTypes from 'prop-types';
import PasswordRequirements from "../../Common/UserAccount/PasswordRequirements";
import history from "../../Common/history";
import * as Routes from "../../../libs/PCAOSSRoutes";
import CSRFTokenForm from "../../Common/CSRFTokenForm";


function AdminUserEditForm(props) {
    const [healthcareCentreOptions, setHealthcareCentreOptions] = React.useState([]);

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    // Fetch healthcare centre options
    React.useEffect(() => {
        props.setLoading(true);
        apiSendData(
        '/api/users/healthcare-centre/options/',
            {},
            'get',
        ).then(response => {
            if (mountedRef.current) {
                props.setLoading(false);
                if (response && !response.error) {
                    setHealthcareCentreOptions(response.data.options)
                }
            }
        });
    }, []);

    // Send Password Reset
    const SendPasswordResetEmail = () => {
        props.setLoading(true);
        apiSendData(
        '/api/users/login/forgot-password/',
            {'email': props.initialValues.email},
            'post',
        ).then(response => {
            if (mountedRef.current) {
                props.setLoading(false);
                if (response && !response.error) {
                    props.showNotification(`Password reset email was sent to ${props.initialValues.username}`, "success", false);
                }
            }
        });
    }

    // Disable or Delete Account
    const DisableOrDeleteAccount = () => {
        props.setLoading(true);
        apiSendData(
        `/api/admin/users/${props.user_id}/`,
            {},
            'delete',
        ).then(response => {
            if (mountedRef.current) {
                props.setLoading(false);
                if (response && !response.error) {
                    props.showNotification(response.detail, "success", true);
                    history.push(Routes.ADMIN_USER);
                }
            }
        });
    }

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <Formik
                initialValues={props.initialValues}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    props.setLoading(true);
                    apiSendData(
                    `/api/admin/users/${props.user_id}/`,
                        {...values},
                        props.user_id === 'new' ? 'post' : 'patch',
                    ).then(response => {
                        if (mountedRef.current) {
                            props.setLoading(false);
                            if (response && response.error && response.code === "input_error" && response.input_errors) {
                                setErrors(response.input_errors);
                            }
                            if (response && !response.error) {
                                props.showNotification(response.detail, "success", true);
                                history.push(Routes.ADMIN_USER)
                            }
                        }
                    });
                }}
                validationSchema={Yup.object().shape({
                    healthcare_centre: Yup.string()
                        .required("Healthcare centre is required."),
                    username: Yup.string()
                        .min(5, "Username is too short.")
                        .max(150, "Username is too long.")
                        .required("Username is required."),
                    first_name: Yup.string()
                        .min(2, "First name is too short.")
                        .max(30, "First name is too long.")
                        .required("First name is required."),
                    last_name: Yup.string()
                        .min(2, "Last name is too short.")
                        .max(30, "Last name is too long.")
                        .required("Last name is required."),
                    email: Yup.string().email()
                        .required("Email is required."),
                })}
            >
                <Form autoComplete="off" className="pure-form pure-form-stacked" id="form-admin-user-edit">
                    <CSRFTokenForm />
                    <div className="pure-u-1 pure-u-md-1-2">
                        <CheckboxField
                            id="is_active"
                            name="is_active"
                            helpText="Uncheck to disable this users account."
                        >
                            Active
                        </CheckboxField>
                    </div>
                    <div className="pure-u-1 pure-u-md-1-2 row-space">
                        <SearchSelectField
                            label="Healthcare Centre"
                            id="healthcare_centre"
                            name="healthcare_centre"
                            required="required"
                            options={healthcareCentreOptions}
                            helpText="Select a healthcare centre."
                        />
                    </div>
                    <div className="pure-u-1 row-space">
                        <TextField
                            label="Username"
                            id="username"
                            name="username"
                            required="required"
                            placeholder="Enter Username"
                            helpText="Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only."
                        />
                    </div>
                    <div className="pure-u-1 row-space">
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                            <div className="grid-space">
                                <TextField
                                    label="First Name"
                                    id="first_name"
                                    name="first_name"
                                    required="required"
                                    placeholder="First Name"
                                />
                            </div>
                        </div>
                        <div className="pure-u-1 pure-u-md-1-2 row-space">
                                <TextField
                                    label="Last Name"
                                    id="last_name"
                                    name="last_name"
                                    required="required"
                                    placeholder="Last Name"
                                />
                            </div>
                    </div>
                    <div className="pure-u-1 row-space">
                        <TextField
                            label="Email Address"
                            id="email"
                            name="email"
                            required="required"
                            placeholder="Email Address"
                            helpText="Please do not use your personal email."
                        />
                    </div>
                    {
                        props.user_id === 'new' ?
                            <>
                            <div className="pure-u-1 row-space">
                                <p className="notes-separator">
                                    <strong>Note:</strong> Leaving the password fields blank will send the user an activation
                                    email where they can set their own password.</p>
                            </div>
                            <div className="pure-u-1">
                                <div className="pure-u-1 pure-u-md-1-2 row-space">
                                    <div className="grid-space">
                                        <TextField
                                            label="Password"
                                            id="password"
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </div>
                                    <PasswordRequirements />
                                </div>
                                <div className="pure-u-1 pure-u-md-1-2 row-space">
                                    <TextField
                                        label="Confirm Password"
                                        id="password_confirm"
                                        name="password_confirm"
                                        type="password"
                                        placeholder="Confirm Password"
                                        helpText="Enter the same password as before, for verification."
                                    />
                                </div>
                            </div>
                            </>
                    :
                            <>
                                <div className="pure-u-1 row-space">
                                    <p className="notes-separator">
                                        <strong>Note:</strong> Leave the password field blank unless you wish to change it for this user.</p>
                                </div>
                                <div className="pure-u-1-2">
                                    <div className="grid-space">
                                        <TextField
                                            label="Set New Password (optional)"
                                            id="password"
                                            name="password"
                                            type="password"
                                            placeholder="Password (leave blank unless changing)"
                                        />
                                    </div>
                                    <PasswordRequirements />
                                </div>
                                <div className="pure-u-1-2">
                                    <button
                                        type="button"
                                        className="pure-button button-std button-row-space"
                                        onClick={SendPasswordResetEmail}
                                    >
                                        Send User Password Reset Email
                                    </button>
                                </div>
                            </>
                    }
                    <div className="pure-u-1">
                        <button type="submit" className="pure-button button-std button-row-space">{props.user_id === 'new' ? "Create Account" : "Update Account"}</button>
                        {
                            props.user_id !== "new" ?
                                props.initialValues.is_active || props.initialValues.requires_activation ?
                                    <button
                                        type="button"
                                        className="pure-button button-error button-row-space"
                                        style={{marginLeft:'3rem'}}
                                        onClick={DisableOrDeleteAccount}
                                    >
                                        {props.initialValues.requires_activation ? "Delete User" : "Disable User"}
                                    </button>
                                    : ""
                                : ""
                        }
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserEditForm);

AdminUserEditForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
    user_id: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
}