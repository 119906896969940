import React from 'react';
import {connect} from "react-redux";
import {apiSendData} from "../../../libs/Api";
import * as Routes from "../../../libs/PCAOSSRoutes";
import history from "../../Common/history";
import SearchAndFilterBar from "../../Common/SearchAndFilterBar";
import AdminHealthcareCentreTable from "./AdminHealthcareCentreTable";

function AdminHealthcareCentre(props) {
    const [loading, setLoading] = React.useState(false);
    const [healthcareCentres, setHealthcareCentres] = React.useState([]);
    const [filter, setFilter] = React.useState({searchTerm: '', options: []});
    const statusOptions = ["Hospital", "Clinic"]

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setLoading(true);
        apiSendData(
        '/api/admin/healthcare-centre/',
            {},
            'get',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    setHealthcareCentres(response.data.healthcare_centres);
                }
            }
        });
    }, []);

    const filterHealthcareCentres = (centre) => {
        return (!filter.options.length || filter.options.includes(centre.type.charAt(0).toUpperCase() + centre.type.slice(1)))
            && (filter.searchTerm === '' || centre.name.toLowerCase().includes(filter.searchTerm.toLowerCase()));
    };

    return(
        <>
            <h3>Manage Healthcare Centres</h3>
            <p>This page can be used to manage existing healthcare centres or create new ones. Multiple user accounts can be linked to a single healthcare centre.</p>
            <div className="pure-u-1">
                <button className="pure-button button-std row-space" onClick={() => {history.push(`${Routes.ADMIN_HEALTHCARE_CENTRE}/new`)}}>Create New Healthcare Centre</button>
            </div>
            <SearchAndFilterBar
                filterOptions={statusOptions}
                filter={filter}
                setFilter={setFilter}
                data={healthcareCentres}
                searchPlaceholder="Healthcare Centre Name"
            />
            <AdminHealthcareCentreTable
                loading={loading}
                healthcareCentres={healthcareCentres.filter(filterHealthcareCentres)}
                statusOptions={statusOptions}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHealthcareCentre);