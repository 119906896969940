import React from "react";
import {showNotification, setLogin} from "../../redux/actions";
import {connect} from "react-redux";
import {Formik, Form} from "formik";
import {TextField} from '../Common/CustomInputs'
import {Link, useLocation} from "react-router-dom";
import * as Routes from '../../libs/PCAOSSRoutes';
import {apiSendData} from "../../libs/Api";
import history from "../Common/history";
import authRouteFinder from "../../libs/AuthRouteFinder";
import CSRFTokenForm from "../Common/CSRFTokenForm";

const LoginForm = props => {
    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    return (
        <Formik
            initialValues={{username: '', password: ''}}
            onSubmit={(values, {setSubmitting}) => {
                apiSendData(
                    '/api/users/login/',
                    values,
                    'post',
                    true,
                ).then(response => {
                    if (mountedRef.current) {
                        //setSubmitting(false);
                        if (response && !response.error) {
                            props.setLogin(response.data);
                            //Check if two factor is required
                            if (response.data.two_factor_required) {
                                history.push(Routes.TWO_FACTOR);
                            } else {
                                console.log("From: "+props.from);
                                authRouteFinder(props.from);
                            }
                        }
                    }
                });
            }}
        >
            <Form className="pure-form pure-form-stacked" id="form-login">
                <CSRFTokenForm/>
                <div className="pure-u-1 row-space">
                    <TextField
                        label="Username"
                        id="username"
                        name="username"
                        type="text"
                        required="required"
                        autoFocus={true}
                        placeholder="Username"
                    />
                </div>
                <div className="pure-u-1 row-space">
                    <TextField
                        label="Password"
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="off"
                        required="required"
                        placeholder="Password"
                    />
                </div>
                <div className="pure-u-1-2">
                    <button type="submit" className="pure-button button-std button-row-space">Sign In</button>
                </div>
                <div className="pure-u-1-2 button-row-space" style={{textAlign: 'right'}}>
                    <Link to={Routes.FORGOT_PASSWORD}>Forgot your password?</Link>
                </div>
            </Form>
        </Formik>
    )
}

function LoginPage(props) {
    // Location
    let location = useLocation();

    // Mounted Ref
    const mountedRef = React.useRef(true);

    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const randomString = (length, chars) => {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    //Check if user has an existing session and load state if they do
    React.useEffect(() => {
        // Get next route if specified
        let from = location.state?.from?.pathname;
        const token = randomString(64, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZ');
        // Check if user is current logged in
        if (props.authState.isLoggedIn) {
            authRouteFinder(from);
        } else {
            // Check with backend for existing session
            apiSendData(
                '/api/users/login/',
                {'csrftoken': token},
                    'get',
                    true,
                ).then(response => {
                    if (mountedRef.current) {
                        //setSubmitting(false);
                        if (response && !response.error) {
                            if (response.data !== "") {
                                props.setLogin(response.data);
                                authRouteFinder(from);
                            }
                        }
                    }
                });
        }
    }, []);

    return (
        <div className="pure-g">
            <div className="pure-u-1" style={{margin: 'auto', maxWidth: '1280px'}}>
                <div className="pure-u-1">
                    <h2
                        className="header_color_override"
                        style={{textAlign: 'center'}}>Primary Care Appointment
                        <br/>
                        Online Scheduling System for EDs
                    </h2>
                </div>
                <div className="pure-u-1">
                    <h4 className="header_color_override" style={{textAlign:'center'}}>A pilot study funded by CIHR</h4>
                </div>
                <div className="pure-u-1">
                    <p style={{textAlign: 'center'}}>
                        These appointments are <strong>for ERs only</strong>; please contact your family physician's office if your are looking for an appointment.
                    </p>
                </div>

                <LoginForm {...props} from={location.state?.from?.pathname} />

                <div className="pure-u-1">
                    <p>Don't have an account? <Link to="/user/account-setup">Create a new account</Link></p>
                </div>

                <div className="pure-u-1">
                    <table className="table-login-sub">
                        <tbody>
                            <tr>
                                <td>Research Coordinator:</td>
                                <td>Anahita Carbonneau</td>
                            </tr>
                            <tr>
                                <td>PI:</td>
                                <td>Clare Atzema MD MSc FRCPC, <a
                                    href="mailto:clare.atzema@sunnybrook.ca">clare.atzema@sunnybrook.ca</a>, text (416) 319-0118
                                </td>
                            </tr>
                            <tr>
                                <td>Technical Issues:</td>
                                <td>David Burke, <a href="mailto:david.burke@sunnybrook.ca">david.burke@sunnybrook.ca</a>, text (905) 483-7863</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        authState: state.authState,
    };
};

const mapDispatchToProps = {
    setLogin,
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);