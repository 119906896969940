import React from 'react';
import { useParams } from 'react-router-dom';
import {connect} from "react-redux";
import {apiSendData} from "../../../libs/Api";
import {TextField, SelectField, CheckboxField} from "../../Common/CustomInputs";
import {FieldArray, Form, Formik, Field} from "formik";
import CSRFTokenForm from "../../Common/CSRFTokenForm";
import { showNotification } from '../../../redux/actions';

function ClinicNotifications(props) {
    let { centre_id } = useParams();

    const [notifyList, setNotifyList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    // Fetch users to recieve notifications from the api
    React.useEffect(() =>{
        setLoading(true);
        apiSendData('/api/clinic/notifications/', {centre_id: centre_id}, 'get')
            .then(response => {
                if (mountedRef.current) {
                    setLoading(false);
                    if (response && !response.error) {
                        setNotifyList(response.data.users);
                    }
                }
            })
    }, [])

    return (
        <div className={loading ? 'loading-overlay-element': undefined}>
        <div className="pure-g">
            <div className="pure-u-1">
                <h3>Manage Clinic Notifications</h3>
                <p>You can manage who at your clinic will recieve email notifications from our system.</p>
                <p>Email notifications will be sent for the following reasons: </p>
                <ul>
                    <li>An appointment has been booked.</li>
                    <li>An appointment has expired without being taken.</li>
                </ul>
                <Formik
                    initialValues={{ users: notifyList }}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
                        setLoading(true);
                        apiSendData(
                        '/api/clinic/notifications/',
                            {
                                ...values,
                            },
                            'patch'
                        ).then(response => {
                            if (mountedRef.current) {
                                setLoading(false);
                                if (response && !response.error) {
                                    // Push not working for some reason 
                                    //props.showNotification("Notification list updated successfully.", "success", true);
                                }
                            }
                        });
                    }}
                    >
                    {(formikProps) => (
                        <Form autoComplete="off" className="pure-form pure-form-stacked" id="form-clinic-setup">
                        <CSRFTokenForm />
                        <FieldArray name="users">
                            {(arrayHelpers) => (
                            <div className="div-table">
                                {formikProps.values.users && formikProps.values.users.length > 0 ? (
                                formikProps.values.users.map((user, index) => (
                                    <div key={user.id}>
                                    <div>
                                        <CheckboxField
                                            name={`users.${index}.receieve_email_notifications`}
                                            id={`users.${index}.receieve_email_notifications`}
                                            checked={formikProps.values.users[index].receieve_email_notifications}
                                            onChange={(e) => {
                                                const newValue = !formikProps.values.users[index].receieve_email_notifications;
                                                formikProps.setFieldValue(`users.${index}.receieve_email_notifications`, newValue);
                                            }}
                                        >
                                        {user.first_name} {user.last_name} - {user.email}
                                        </CheckboxField>
                                    </div>
                                    </div>
                                ))
                                ) : (
                                <div>No users found.</div>
                                )}
                            </div>
                            )}
                        </FieldArray>
                        <div className="pure-u-1">
                            <button type="submit" className="pure-button button-std button-row-space">
                            Save Changes
                            </button>
                        </div>
                        </Form>
                    )}
                    </Formik>
            </div>
        </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicNotifications);