import React from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import {connect} from "react-redux";
import {Formik, Form} from "formik";
import {SelectField, DatePickerField} from "../../Common/CustomInputs";
import Modal from "../../Common/Modal";
import dayjs from "dayjs";
import CSRFTokenForm from "../../Common/CSRFTokenForm";
import * as Yup from "yup";
import {FaExclamationTriangle} from 'react-icons/fa';


function ClinicSetupReservedAppointmentsModal(props) {
    const [repeatDays, setRepeatDays] = React.useState([]);
    const [showOddTimeWarning, setShowOddTimeWarning] = React.useState(false);
    let acceptWarning = false;

    const initialValues = props.initialValues ?

        {
            start_date: dayjs(props.initialValues.start_date).toDate(),
            start_time_hour: props.initialValues.start_time.substring(0, 2),
            start_time_min: props.initialValues.start_time.substring(3,5),
            start_time_am_pm: props.initialValues.start_time.substring(props.initialValues.start_time.length-2,props.initialValues.start_time.length),
            repeat: props.initialValues.repeat,
            type: props.initialValues.type,
            clinic_physician: props.initialValues.clinic_physician === null ? '' : props.initialValues.clinic_physician,
            end_date: (props.initialValues.end_date) ? dayjs(props.initialValues.end_date).toDate(): '',
            appointment_repeat_days: props.initialValues.appointment_repeat_days === null ? [] : props.initialValues.appointment_repeat_days,
        }
        :
        {start_date: '', start_time_hour: '1', start_time_min: '00', start_time_am_pm: 'AM', repeat: 1, clinic_physician: '', end_date: '', appointment_repeat_days: []};

    React.useEffect(() => {
        if (props.appointmentModalOpen === true && initialValues) {
            setRepeatDays(initialValues.appointment_repeat_days);
        }

        // Reset warning
        acceptWarning = false;
        setShowOddTimeWarning(false);
        // eslint-disable-next-line
    }, [props.appointmentModalOpen]);

    const physicianOptions = [
        {value: '', label: 'Clinic Appointment'},
        ...props.physicians.map(physician => ({value: physician.id, label: physician.physician_name}))
    ];

    const appointmentTypeOptions = [
        {value: '1', label: 'In Person'},
        {value: '2', label: 'Virtual'},
    ]

    const hourOptions = [
        {value: '01', label: '01'},
        {value: '02', label: '02'},
        {value: '03', label: '03'},
        {value: '04', label: '04'},
        {value: '05', label: '05'},
        {value: '06', label: '06'},
        {value: '07', label: '07'},
        {value: '08', label: '08'},
        {value: '09', label: '09'},
        {value: '10', label: '10'},
        {value: '11', label: '11'},
        {value: '12', label: '12'},
    ];

    const minuteOptions = [
        {value: '00', label: '00'},
        {value: '05', label: '05'},
        {value: '10', label: '10'},
        {value: '15', label: '15'},
        {value: '20', label: '20'},
        {value: '25', label: '25'},
        {value: '30', label: '30'},
        {value: '35', label: '35'},
        {value: '40', label: '40'},
        {value: '45', label: '45'},
        {value: '50', label: '50'},
        {value: '55', label: '55'},
    ];

    const amPMOptions = [
        {value: 'AM', label: 'AM'},
        {value: 'PM', label: 'PM'},
    ];

    const repeatOptions = [
        {value: '1', label: "Never"},
        {value: '2', label: "Weekly"},
        {value: '3', label: "Every 2 Weeks"},
        {value: '4', label: "Every 3 Weeks"},
        {value: '5', label: "Every 4 Weeks"},
    ];

     const onClickRepeatDay = (event, value) => {
        event.preventDefault();
        //Remove filter if it already exists
        if (repeatDays.filter(option => option.id === value).length > 0) {
            setRepeatDays(repeatDays.filter((option) => option.id !== value));
        } else {
            setRepeatDays([...repeatDays, {id: value}]);
        }
        event.target.blur();
     }

     // Handle warning for time
    const cancelConfirmTime = () => {
         setShowOddTimeWarning(false);
         acceptWarning = false;
    }

    const confirmTime = () => {
         acceptWarning = true;
    }

    return(
        <Modal
            modalOpen={props.appointmentModalOpen}
            setModalOpen={props.setAppointmentModalOpen}
            header={props.initialValues ? "Update Reserved Appointment" : "Create Reserved Appointment"}
            onModalClose={props.onModalClose}
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    start_date: Yup.date()
                        .required("Appointment date is required."),
                })}
                onSubmit={(values, {resetForm, setSubmitting, setErrors}) => {
                    // Check if appointment occurs at a usual time
                    if(!acceptWarning
                        && ((values.start_time_hour >= 10 && values.start_time_am_pm === "PM" && values.start_time_hour != 12) ||  ((values.start_time_hour < 7 || values.start_time_hour == 12) && values.start_time_am_pm === "AM"))
                    ) {
                        // Display confirm to continue
                        setShowOddTimeWarning(true);
                        return;
                    }

                    if (props.initialValues) {
                        props.updateAppointment({...values, appointment_repeat_days: repeatDays, edit_id: props.initialValues.id});
                    } else {
                        props.addAppointment({...values, appointment_repeat_days: repeatDays});
                    }
                    props.setAppointmentModalOpen(false);
                    resetForm();
                    setRepeatDays([]);
                }}
            >
                {({values}) => <Form autoComplete="off" className="pure-form pure-form-stacked" id="form-setup-resv-appt">
                    <CSRFTokenForm />
                    <div className="pure-u-1 row-space">
                        <SelectField
                            label="Appointment is For"
                            id="clinic_physician"
                            name="clinic_physician"
                            options={physicianOptions}
                            helpText="Appointments can be created for specific physicians or clinic wide."
                        />
                    </div>
                    <div className="pure-u-1-2 row-space same-row-input-spacing">
                        <DatePickerField
                            label="Appointment Start Date"
                            id="start_date"
                            name="start_date"
                            required="required"
                        />
                    </div>
                    <div className="pure-u-1-2 row-space">
                        <SelectField
                            label="Appointment Type"
                            id="type"
                            name="type"
                            required="required"
                            style={{marginTop:'8px'}}
                            options={appointmentTypeOptions}
                            helpText="Appointments can be either in person or virtual."
                        />
                    </div>
                    
                    <div className="pure-u-1 row-space">
                        <label>Appointment Start Time:</label>
                    </div>
                    <div className="pure-u-1-3">
                        <SelectField
                            id="start_time_hour"
                            name="start_time_hour"
                            required="required"
                            options={hourOptions}
                        />
                    </div>
                    <div className="pure-u-1-3">
                        <SelectField
                            label=""
                            id="start_time_min"
                            name="start_time_min"
                            required="required"
                            options={minuteOptions}
                        />
                    </div>
                    <div className="pure-u-1-3">
                        <SelectField
                            label=""
                            id="start_time_am_pm"
                            name="start_time_am_pm"
                            required="required"
                            options={amPMOptions}
                        />
                    </div>
                    <div className="pure-u-1 row-space">
                        <DatePickerField
                            label="End Date (optional)"
                            minDate={values.start_date}
                            id="end_date"
                            name="end_date"
                        />
                    </div>
                    <div className="pure-u-1 row-space">
                        <div className="pure-u-1-3">
                            <SelectField
                                label="Repeat Appointment"
                                id="repeat"
                                name="repeat"
                                required="required"
                                options={repeatOptions}
                                helpText="Repeat this appointment until it's cancelled."
                            />
                        </div>
                    </div>
                    {
                        values.repeat != "1" &&
                            <div className="pure-u-1 row-space">
                                <label>Also repeat appointment on (optional):</label>
                                <div className="pure-u-1" style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <div className="pure-form icon-input-container">
                                        <div className="pure-button-group" role="group">
                                            <button
                                                disabled={ values.start_date && values.start_date.getDay() === 0 }
                                                className={`pure-button button-group-empty ${repeatDays.filter(option => option.id === 0).length > 0 ? 'selected' : ''}`}
                                                style={{borderLeft: '1px solid #CCCCCC'}}
                                                onClick={(event) => onClickRepeatDay(event, 0)}
                                            >
                                                Sunday
                                            </button>
                                            <button
                                                disabled={ values.start_date && values.start_date.getDay() === 1 }
                                                className={`pure-button button-group-empty ${repeatDays.filter(option => option.id === 1).length > 0 ? 'selected' : ''}`}
                                                onClick={(event) => onClickRepeatDay(event, 1)}
                                            >
                                                Monday
                                            </button>
                                            <button
                                                disabled={ values.start_date && values.start_date.getDay() === 2 }
                                                className={`pure-button button-group-empty ${repeatDays.filter(option => option.id === 2).length > 0 ? 'selected' : ''}`}
                                                onClick={(event) => onClickRepeatDay(event, 2)}
                                            >
                                                Tuesday
                                            </button>
                                            <button
                                                disabled={ values.start_date && values.start_date.getDay() === 3 }
                                                className={`pure-button button-group-empty ${repeatDays.filter(option => option.id === 3).length > 0 ? 'selected' : ''}`}
                                                onClick={(event) => onClickRepeatDay(event, 3)}
                                            >
                                                Wednesday
                                            </button>
                                            <button
                                                disabled={ values.start_date && values.start_date.getDay() === 4 }
                                                className={`pure-button button-group-empty ${repeatDays.filter(option => option.id === 4).length > 0 ? 'selected' : ''}`}
                                                onClick={(event) => onClickRepeatDay(event, 4)}
                                            >
                                                Thursday
                                            </button>
                                            <button
                                                disabled={ values.start_date && values.start_date.getDay() === 5 }
                                                className={`pure-button button-group-empty ${repeatDays.filter(option => option.id === 5).length > 0 ? 'selected' : ''}`}
                                                onClick={(event) => onClickRepeatDay(event, 5)}
                                            >
                                                Friday
                                            </button>
                                            <button
                                                disabled={ values.start_date && values.start_date.getDay() === 6 }
                                                className={`pure-button button-group-empty ${repeatDays.filter(option => option.id === 6).length > 0 ? 'selected' : ''}`}
                                                onClick={(event) => onClickRepeatDay(event, 6)}
                                            >
                                                Saturday
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <small className="pure-form-message" style={{marginTop:'0.25em'}}>(Optional) Also repeat this appointment on the selected days of the week.</small>
                            </div>
                        }
                    <div className="pure-u-1">
                        {
                            showOddTimeWarning ?
                                <>
                                <p className="alert-warning inline-warning"><FaExclamationTriangle /> Warning the appointment time you've selected falls outside of the normal range of between <b>7am and 10pm</b>.<br/><br/><b>Are you sure you want to create this appointment?</b></p>
                                        <button type="button" onClick={cancelConfirmTime} className="pure-button button-cancel button-row-space">Cancel</button>
                                        <button type="submit" onClick={confirmTime} className="pure-button button-std button-row-space left-space">Confirm</button>
                                </>
                                :
                                props.initialValues ?
                                    <button type="submit" className="pure-button button-std button-row-space">Update Appointment</button>
                                    :
                                    <button type="submit" className="pure-button button-std button-row-space">Create Appointment</button>
                        }
                    </div>
                </Form>}
            </Formik>
        </Modal>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

ClinicSetupReservedAppointmentsModal.propTypes = {
    appointmentModalOpen: PropTypes.bool,
    setAppointmentModalOpen: PropTypes.func,
    physicians: PropTypes.array,
    addAppointment: PropTypes.func,
    appointments: PropTypes.object,
    setAppointments: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSetupReservedAppointmentsModal);