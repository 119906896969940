import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ModifyButton from "../../Common/ModifyButtons";
import history from "../../Common/history";
import * as Routes from '../../../libs/PCAOSSRoutes';

function Row(props) {

    return(
        <tr>
            <td>
                <div className={`status ${props.centre.active ? props.centre.type : "Disabled"}`}>
                    {
                        props.centre.active ?
                            props.centre.type.charAt(0).toUpperCase() + props.centre.type.slice(1)
                            : "Disabled"
                    }
                </div>
            </td>
            <td>{props.centre.name}</td>
            <td>{props.centre.email}</td>
            <td>{props.centre.address}</td>
            <td style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                <ModifyButton type='edit' onClickHandler={() => {history.push(`${Routes.ADMIN_HEALTHCARE_CENTRE}/${props.centre.id}`)}} />
            </td>
        </tr>
    );
}

Row.propTypes = {
    centre: PropTypes.object.isRequired,
    statusOptions: PropTypes.array.isRequired,
}

function AdminHealthcareCentreTable(props) {

    return(
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <table className="pure-table pure-table-horizontal pure-table-striped table-hover row-space" style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th style={{whiteSpace: 'nowrap'}}>Type</th>
                        <th style={{whiteSpace: 'nowrap'}}>Name</th>
                        <th style={{whiteSpace: 'nowrap'}}>Email</th>
                        <th style={{whiteSpace: 'nowrap'}}>Address</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.healthcareCentres.length > 0 ?
                            props.healthcareCentres.map((centre, index) => (
                                <Row key={index} centre={centre} statusOptions={props.statusOptions} />
                            ))
                            : <tr><td colSpan={5}>No Results</td></tr>
                    }
                </tbody>
            </table>
        </div>
    );
}

AdminHealthcareCentreTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    healthcareCentres: PropTypes.array.isRequired,
    statusOptions: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHealthcareCentreTable);