import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import * as Routes from '../../../libs/PCAOSSRoutes';
import AdminUsersTable from "./AdminUsersTable";
import {apiSendData} from "../../../libs/Api";
import SearchAndFilterBar from "../../Common/SearchAndFilterBar";
import history from "../../Common/history";
import {showNotification} from "../../../redux/actions";

function AdminUsers(props) {
    const [loading, setLoading] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const [filter, setFilter] = React.useState({searchTerm: '', options: []});
    const statusOptions = ["Pending", "Active", "Disabled"];
    const [toggleReloadUsers, setToggleReloadUsers] = React.useState(false);

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    React.useEffect(() => {
        setLoading(true);
        apiSendData(
        '/api/admin/users/',
            {},
            'get',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    setUsers(response.data.users)
                }
            }
        });
    }, [toggleReloadUsers]);

    const filterUsers = (user) => {
        return (!filter.options.length || filter.options.includes(user.status))
            && (filter.searchTerm === '' || user.email.toLowerCase().includes(filter.searchTerm.toLowerCase()));
    };

    // Handle activate for pending users
    const onClickHandleActivate = (user_id) => {
        setLoading(true);
        apiSendData(
        `/api/admin/users/${user_id}/activate/`,
            {},
            'post',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    props.showNotification(response.detail, "success", false);
                    setToggleReloadUsers(!toggleReloadUsers);
                }
            }
        });
    }

    // Handle delete for pending users
    const onClickHandleDelete = (user_id) => {
        setLoading(true);
        apiSendData(
        `/api/admin/users/${user_id}/`,
            {},
            'delete',
        ).then(response => {
            if (mountedRef.current) {
                setLoading(false);
                if (response && !response.error) {
                    props.showNotification(response.detail, "success", false);
                    setToggleReloadUsers(!toggleReloadUsers);
                }
            }
        });
    };

    return(
        <>
            <h3>Manage User Accounts</h3>
            <p>This page can be used to manage existing user accounts or create new accounts. Before creating a new user you need to create a <Link to={Routes.ADMIN_HEALTHCARE_CENTRE}>healthcare centre</Link> first. Multiple accounts can be linked to a single healthcare centre.</p>
            <div className="pure-u-1">
                <button className="pure-button button-std row-space" onClick={() => {history.push(`${Routes.ADMIN_USER}/new`)}}>Create New User</button>
            </div>
            <SearchAndFilterBar
                filterOptions={statusOptions}
                filter={filter}
                setFilter={setFilter}
                data={users}
                searchPlaceholder="Search User Email"
            />
            <AdminUsersTable
                loading={loading}
                users={users.filter(filterUsers)}
                statusOptions={statusOptions}
                onClickHandleActivate={onClickHandleActivate}
                onClickHandleDelete={onClickHandleDelete}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);