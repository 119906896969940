import React from 'react';
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";
import {formatDate} from "../../../libs/CommonFunctions";
import {apiSendData} from "../../../libs/Api";
import {Form, Formik} from "formik";
import {DatePickerField, TextField, CheckboxField, TextArea, SelectField} from "../../Common/CustomInputs";
import * as Yup from 'yup';
import PropTypes from "prop-types";
import {showNotification} from "../../../redux/actions";
import * as Routes from '../../../libs/PCAOSSRoutes';
import history from "../../Common/history";
import CSRFTokenForm from "../../Common/CSRFTokenForm";
import ConfirmOptionModal from "../../Common/ConfirmOptionModal";

const HospitalClinicBookingForm = (props) => {
    const [cancel, setCancel] = React.useState(false);
    const [showCancellationModal, setShowCancellationModal] = React.useState(false);
    const formRef = React.useRef();

    // Mounted Ref
    const mountedRef = React.useRef(true);
    React.useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const initialValues =  {
        'virtual_appt_ok': (props.bookingData && props.bookingData.virtual_appt_ok) ? props.bookingData.virtual_appt_ok : (props.clinic_info && props.clinic_info.type == 2),
        'patient_name': props.bookingData && props.bookingData.patient_name ? props.bookingData.patient_name : '',
        'patient_age': props.bookingData && props.bookingData.patient_age ? props.bookingData.patient_age : '',
        'patient_phone': props.bookingData && props.bookingData.patient_phone ? props.bookingData.patient_phone : '',
        'health_card_number': props.bookingData && props.bookingData.health_card_number ? props.bookingData.health_card_number : '',
        'discharge_date': props.bookingData && props.bookingData.discharge_date ? dayjs(props.bookingData.discharge_date).toDate() : dayjs().toDate(),
        'reason_for_appt': props.bookingData && props.bookingData.reason_for_appt ? props.bookingData.reason_for_appt : '',
        'consent_obtained': props.bookingData && props.bookingData.consent_obtained ? props.bookingData.consent_obtained : false,
        'patient_covid_symptoms': props.bookingData && props.bookingData.patient_covid_symptoms ? props.bookingData.patient_covid_symptoms : undefined,
        'patient_covid_likely': props.bookingData && props.bookingData.patient_covid_likely ? props.bookingData.patient_covid_likely : undefined,
    }

    const onClickCancellationConfirmButton = () => {
        setCancel(true);
        setShowCancellationModal(false);
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    }

    return(
        <>
        <ConfirmOptionModal
            confirmationModalOpen={showCancellationModal}
            setConfirmationModalOpen={setShowCancellationModal}
            onClickConfirm={onClickCancellationConfirmButton}
            headerText={"Confirm Cancel Appointment?"}
            bodyText={"Are you sure you wish to cancel this appointment? This action cannot be undone."}
        />
        <div className={props.loading ? 'loading-overlay-element': undefined}>
            <Formik
                initialValues={initialValues}
                innerRef={formRef}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting, setErrors}) => {
                    props.setLoading(true);
                    apiSendData(
                    '/api/hospital/clinic/booking/',
                        {
                            ...values,
                            discharge_date: dayjs(values.discharge_date).format("YYYY-MM-DD"),
                            appointment_id: props.appointment_id,
                            physician_id: props.physician_id,
                            appointment_date: props.appointment_date,
                            cancel: cancel,
                            booking_id: props.bookingData ? props.bookingData.id : "",
                            clinic_id: props.clinic_id,
                            is_external: props.is_external,
                        },
                        props.bookingData ? 'patch' : 'post',
                    ).then(response => {
                        if (mountedRef.current) {
                            props.setLoading(false);
                            if (response && response.error && response.code === "input_error" && response.input_errors) {
                                setErrors(response.input_errors);
                            }
                            if (response && !response.error) {
                                // Open external link to clinic booking system
                                console.log('Got the following response: ', response.data);
                                console.log('Booking url: ', response.data.clinic_booking_url);
                                if (response.data.clinic_booking_url !== null && response.data.clinic_booking_url !== "" && response.data.clinic_booking_url !== undefined) {
                                    let win = window.open(response.data.clinic_booking_url, '_blank');
                                    win.focus();
                                }
                                if (props.bookingData) {
                                    props.showNotification(response.detail, "success", true);
                                    history.push(`${Routes.HOSPITAL_RECENT_BOOKINGS}`);
                                } else {
                                    props.showNotification("Patient appointment was successfully created.", "success", true);
                                    history.push(`${Routes.HOSPITAL_CLINIC_BOOKING_CONFIRMATION}/${response.data.booking_id}`);
                                }
                            }
                        }
                    });
                }}
                validationSchema={Yup.object().shape({
                    patient_name: Yup.string()
                        .min(2, "Patient name is too short.")
                        .max(200, "Patient name is too long.")
                        .required("Patient name is required."),
                    patient_phone: Yup.string()
                        .min(10, "Phone number is invalid. It must be at least 10 digits long.")
                        .required("Phone number is required."),
                    patient_age: Yup.number()
                        .min(1, "Patient age is too small. Min: 1.")
                        .max(130, "Patient age is too large. Max: 130.")
                        .required("Patient age is required."),
                    health_card_number: Yup.string()
                        .min(10, "Health card number is invalid. It must be 10 digits long.")
                        .max(10, "Health card number is invalid. It must be 10 digits long.")
                        .required("Health card number is required."),
                    discharge_date: Yup.string()
                        .required("Discharge date is required.").nullable(),
                    reason_for_appt: Yup.string()
                        .required("Reason for the appointment is required."),
                    consent_obtained: Yup.bool()
                        .when('props.is_external', {
                            is: false,
                            then: Yup.bool()
                                .oneOf([true], 'Verbal consent is required before an appointment can be booked for this patient.')
                                .required("Verbal consent is required before an appointment can be booked for this patient."),
                            otherwise: Yup.bool()
                        })
                    })}
            >
                <Form autoComplete="off" className="pure-form pure-form-stacked" id="form-clinic-booking">
                    <CSRFTokenForm />
                    {!props.is_external && <div className={`${props.clinic_info.type == 2 ? 'disabled-text' : ''} pure-u-1 row-space`}>
                        <CheckboxField
                            id="virtual_appt_ok"
                            name="virtual_appt_ok"
                            helpText="Select this option if a virtual followup appointment is acceptable."
                            disabled={props.clinic_info.type == 2 ? 'disabled': false}
                        >
                            Is followup appointment okay to be virtual, given the medical issue(s)?
                        </CheckboxField>
                    </div>}
                    {!props.is_external && <div className="pure-u-1 row-space">
                        <div className="pure-u-1 pure-u-lg-1-3 pure-u-xl-1-3 same-row-input-spacing">
                            <SelectField
                                label="Does the patient have any COVID symptoms?"
                                id="patient_covid_symptoms"
                                name="patient_covid_symptoms"
                                options={[{"value": "", "label": "Unknown"},{"value": true, "label": "Yes"},{"value": false, "label": "No"}]}
                            />
                        </div>
                        <div className="pure-u-1 pure-u-lg-1-3 pure-u-xl-1-3 same-row-input-spacing">
                            <SelectField
                                label="Is COVID infection likely?"
                                id="patient_covid_likely"
                                name="patient_covid_likely"
                                options={[{"value": "", "label": "Unknown"},{"value": true, "label": "Yes"},{"value": false, "label": "No"}]}
                            />
                        </div>
                    </div>}
                    <div className="pure-u-1 pure-u-md-2-3 row-space same-row-input-spacing">
                        <TextField
                            label="Patient Name"
                            id="patient_name"
                            name="patient_name"
                            required="required"
                            //autoFocus={true}
                            placeholder="Enter Patient Name"
                        />
                    </div>
                    <div className="pure-u-1 pure-u-md-1-3 row-space same-row-input-spacing">
                        <TextField
                            label="Patient Phone Number"
                            id="patient_phone"
                            name="patient_phone"
                            required="required"
                            //autoFocus={true}
                            placeholder="Phone number"
                        />
                    </div>
                    <div className="pure-u-1 row-space">
                        <div className="pure-u-1 pure-u-md-1-3 same-row-input-spacing">
                            <TextField
                                label="Patient Age"
                                id="patient_age"
                                name="patient_age"
                                required="required"
                                type="number"
                                min="1"
                                max="130"
                                placeholder="Enter Patient Age"
                            />
                        </div>
                        <div className="pure-u-1 pure-u-md-1-3 same-row-input-spacing">
                            <TextField
                                label="OHN"
                                id="health_card_number"
                                name="health_card_number"
                                required="required"
                                type="text"
                                maxLength="10"
                                placeholder="Enter Health Card Number"
                                pattern="\d*"
                                helpText="Enter without dashes or version code."
                            />
                        </div>
                        <div className="pure-u-1 pure-u-md-1-3">
                            <DatePickerField
                                className="pure-u-1 date-picker"
                                label="Date of ED Discharge"
                                id="discharge_date"
                                name="discharge_date"
                                required="required"
                            />
                        </div>
                    </div>
                    <div className="pure-u-1 row-space">
                        <TextArea
                            label="Reason for the Appointment"
                            id="reason_for_appt"
                            name="reason_for_appt"
                            rows={10}
                            required="required"
                            placeholder="Enter Reason for the Appointment"
                        />
                    </div>
                    {!props.is_external && <div className="pure-u-1 row-space">
                        <CheckboxField
                            id="consent_obtained"
                            name="consent_obtained"
                            helpText="Verbal consent is required before an appointment can be booked."
                        >
                            Consent Obtained from Patient?
                        </CheckboxField>
                    </div>}
                    <div className="pure-u-1">
                        {
                            props.bookingData ?
                                <>
                                <button type="submit" className="pure-button button-std button-row-space">Update Appointment</button>
                                <button
                                    type="button"
                                    className="pure-button  button-row-space button-error left-space"
                                    onClick={() => setShowCancellationModal(true)}
                                >
                                    Cancel Appointment
                                </button>
                                </>
                                :
                                <button type="submit" disabled={false} className="pure-button button-std button-row-space">Create Appointment</button>
                        }
                    </div>
                </Form>
            </Formik>
        </div>
        </>
    );
}

HospitalClinicBookingForm.propTypes = {
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
    appointment_id: PropTypes.string,
    physician_id: PropTypes.string,
    appointment_date: PropTypes.string,
    showNotification: PropTypes.func,
    bookingData: PropTypes.object,
    clinic_id: PropTypes.string,
    clinicInfo: PropTypes.object,
    is_external: PropTypes.bool,
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps =  {
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalClinicBookingForm);