const LOGIN = "/login";
const TWO_FACTOR = "/login/two-factor";
const FORGOT_PASSWORD = "/login/forgot-password";
const SET_FORGOT_PASSWORD = "/login/set-forgot-password";
const ACCEPT_AGREEMENT = "/user/accept-agreement";

//Common
const CREATE_ACCOUNT = "/user/account-setup";
const ACCOUNT = "/user/account";
const NEW_ACCOUNT_ACTIVATION = "/user/account/new/activate";

//Clinic
const CLINIC_APPOINTMENTS = "/clinic/appointments";
const CLINIC_SETUP_APPOINTMENTS = "/clinic/setup-appointments";
const CLINIC_NOTIFICATIONS = "/clinic/notifications"

//Hospital
const HOSPITAL_CLINIC_SEARCH = "/hospital/clinic";
const HOSPITAL_CLINIC_SCHEDULE = "/hospital/clinic/schedule";
const HOSPITAL_CLINIC_BOOKING = "/hospital/clinic/booking";
const HOSPITAL_CLINIC_BOOKING_CONFIRMATION = "/hospital/clinic/booking/confirmation";
const HOSPITAL_RECENT_BOOKINGS = "/hospital/bookings"

//Management
const ADMIN_OVERVIEW = "/admin/overview";
const ADMIN_ACCOUNT = "/admin/account";
const ADMIN_BOOKING = "/admin/booking";
const ADMIN_USER = "/admin/user";
const ADMIN_HEALTHCARE_CENTRE = "/admin/healthcare-centre";
const ADMIN_EMAIL_TEMPLATES = "/admin/email-templates";
const ADMIN_LOGS = "/admin/logs";

export {
    LOGIN,
    TWO_FACTOR,
    FORGOT_PASSWORD,
    SET_FORGOT_PASSWORD,
    ACCEPT_AGREEMENT,

    //Common Pages
    CREATE_ACCOUNT,
    ACCOUNT,
    NEW_ACCOUNT_ACTIVATION,

    //Clinic Pages
    CLINIC_APPOINTMENTS,
    CLINIC_SETUP_APPOINTMENTS,
    CLINIC_NOTIFICATIONS,

    //Hospital Pages
    HOSPITAL_CLINIC_SEARCH,
    HOSPITAL_CLINIC_SCHEDULE,
    HOSPITAL_CLINIC_BOOKING,
    HOSPITAL_CLINIC_BOOKING_CONFIRMATION,
    HOSPITAL_RECENT_BOOKINGS,

    //Admin pages
    ADMIN_OVERVIEW,
    ADMIN_ACCOUNT,
    ADMIN_BOOKING,
    ADMIN_USER,
    ADMIN_HEALTHCARE_CENTRE,
    ADMIN_EMAIL_TEMPLATES,
    ADMIN_LOGS,
};