import React from 'react';


function PageNotFound() {
    return(
        <>
            <h2>ERROR 404</h2>
            <h3>The page you are looking for does not exist.</h3>
        </>
    );
}

export default PageNotFound;